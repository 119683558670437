.jumbotron-content__title__left {
    width: 100%;
}

.equip__analog__img-null {
    margin-top: auto;
    color: black;
}

.equip__analog__img-banner {
    max-height: none;
    margin-top: auto;
}

.equip-color_name {
    display: block;
    color: #0b273f;
    font-size: 16px;
    height: 24px;
}

.jumbotron-content__model {
    display: flex;
    height: auto;
    color: black;
    background: url(/../samovar/front/img/slide-decor.svg) no-repeat left center;
    background-size: inherit;
    width: inherit;
    flex-direction: column;
}

.link__favorite {
    color: black;
    text-decoration: underline;
    display: flex;
    width: 100%;
    height: 20px;
    justify-content: center;
    margin-bottom: 20px;
    visibility: hidden;
}

.link__favorite p {
    color: black;
    text-decoration: underline;
}

.jumbotron-content__model__title a p {
    margin: 0 0 0 5px;
}

.favorites-link__icon {
    width: 35px;
    fill: transparent;
    stroke: rgba(11, 39, 63, 0.4);
}

a:hover .favorites-link__icon {
    width: 35px;
    fill: #E4AE55;
    stroke: none;
}

.jumbotron.jumbotron__equipment:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: #fafafa;
}

.jumbotron-content__model__title {
    width: 100%;
    /*text-align: center;*/
}

.title__content {
    margin-top: 25px;
    width: 100%;
    text-align: center;
}

.jumbotron-content__model__img {
    align-items: center;
    background-size: auto;
    justify-content: center;
    display: flex;
    margin-top: auto;
    flex-direction: column;
}

.jumbotron-content__model__btn {
    justify-content: space-evenly;
    display: flex;
    align-items: center;
}

.jumbotron-content__model__btn button {
    width: 240px;
}

.btn-credit {
    height: auto;
    background: #0b273f;
    border-radius: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
}

.btn-credit b {
    line-height: 30px;
}

.btn-text-price {
    color: white;
}

.btn-count-price {
    color: #E4AE55;
}

.jumbotron.jumbotron__equipment .jumbotron-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.jumbotron-content__form.__white__equip {
    display: flex;
    max-width: 405px;
    flex-direction: column;
    box-shadow: 0 0 15px -10px;
    padding: 20px;
    margin: 0 auto;
}

.jumbotron-content__form .form-inline .btn-equip,
.jumbotron-content__form .form-inline .form-control__field-equip {
    width: 100%;
    margin: 0 0 15px;
}

.form-align {
    text-align: center;
}

.special__price {
    color: black;
    margin-bottom: 10px;
}

.special__price p {
    font-weight: 800;
    font-size: 25px;
    color: black;
    padding: 0 20px;
    margin-bottom: 0;
}

.block__price {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.left-price {
    font-weight: 800;
    font-size: 30px;
}

.right-price {
    font-size: 21px;
}

.right-price__up {
    color: #E4AE55;
    text-decoration: line-through;
}

.right-price__down {
    color: #0b273f;
}

.equp__favorite {
    margin: 20px auto 0;
    font-size: 20px;
    cursor: pointer;
}

.brand-cars__new-item-pull {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    background: #f7f7f7;
}

.down-bloc {
    border: rgba(11, 39, 63, 0.64) 2px dotted;
    border-radius: 25px;
    padding: 30px 45px;
    margin-top: 70px;
}

.conditions__mobi {
    display: none;
}

.equip__img__mobi {
    display: none;
}

.model__colors {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.model__colors__value {
    width: 32px;
    height: 32px;
    margin: 5px;
    border-radius: 50%;
    border: $base-color 3px solid;
}

.link__all-equipment {
    text-align: center;
    text-decoration: underline;
    margin-bottom: 15px;
    display: block;
}

.model__colors__checked {
    border: orange 3px solid;
}

.conditions__max {
    margin-bottom: auto;
}

.equip__title__h1 {
    font-size: 50px;
    margin-bottom: 0;
}

.brand-cars__item-info .link__equipment {
    color: $base-color;
    &:link {
        color: $base-color;
    }
    &:visited {
        color: $base-color;
    }
    &:hover {
        color: $base-color;
    }
    &:focus {
        color: $base-color;
    }
    &:active {
        color: $base-color;
    }
}

.equipment__brand-cars {
    margin-top: -15px;
    margin-bottom: 48px;
}

.equipment__img__galery-colors__null {
    width: 560px;
    height: 330px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1409px) {
    .jumbotron-content__form.__white__equip {
        margin: 0 auto;
    }

    .jumbotron.jumbotron__equipment .jumbotron-content {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 1279px) {
    .equipment__brand-cars {
        display: none;
    }
}

@media screen and (max-width: 1023px) {
    h1 {
        margin-top: 0;
        font-size: 30px;
    }
    .jumbotron.jumbotron__equipment:before {
        content: none;
    }
    .jumbotron.jumbotron__equipment {
        padding-top: 0;
    }
    .jumbotron-content__model__btn {
        flex-wrap: wrap;
        margin-top: 20px;
    }
    .special__price p {
        margin: 0 0 30px 0;
        font-size: 40px;
    }
    .jumbotron.jumbotron__equipment .jumbotron-content {
        background: none;
    }
}

@media (max-width: 972px) {
    .jumbotron-content__model__btn__last-btn {
        display: flex;
        flex-direction: column-reverse;
        margin-top: inherit;
    }
}

@media screen and (max-width: 490px) {
    .jumbotron-content__model__title {
        display: none;
    }
    .equip__img__max {
        display: none;
    }
    .equip__img__mobi {
        display: flex;
        margin-bottom: 15px;
    }
    .brand-cars__new-item-pull {
        flex-direction: column;
        background: none;
    }
    .brand-cars__item-pull-title,
    .brand-cars__item-pull-discount {
        font-size: 20px;
    }
    .brand-cars__item-pull-discount:before {
        content: "";
        display: block;
        position: absolute;
        background: url(../img/icons/discount.svg) no-repeat center;
        background-size: contain;
        width: 24px;
        height: 24px;
        left: -40px;
        top: 50%;
        margin-top: -12px;
    }
    .brand-cars__item-pull-old {
        font-size: 16px;
    }
    .brand-cars-title__param {
        opacity: 0.4;
    }
    .brand-cars__new-item-pull div {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .brand-cars__item-pull__param {
        padding: 8px 16px;
    }
    .brand-cars__item-pull__param:first-child,
    .brand-cars__item-pull__param:last-child {
        line-height: 0.5;
    }
    .conditions__max {
        display: none;
    }
    .conditions__mobi {
        display: block;
    }
    .jumbotron-content__model {
        height: auto;
        background: none;
    }
    .jumbotron-content__model__btn button {
        margin-bottom: 10px;
        max-width: 365px;
        width: 100%;
    }
    .jumbotron-content__model__btn__last-btn {
        width: 100%;
        align-items: center;
    }
    .jumbotron-content__model__btn__last-btn {
        margin-top: 0;
    }
    .special__price p {
        line-height: 1;
        margin: 15px 0 15px 0;
    }
}
