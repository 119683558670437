.footer {
  background: #F8F8F8;
  position: relative;

  &-main {
    padding: 80px 0 72px;

    .text-sm {
      display: block;
      max-width: 830px;
      margin: 72px 0 0;
      color: rgba($base-color, .32);
    }

    &__row {
      @include flex;
    }

    &__col {
      width: 24%;

      &:nth-child(even) {
        width: 76%;
      }
    }

    &__title {
      margin-bottom: 16px;
    }

    &__list {
      @include listReset;

      li {
        margin-bottom: 8px;
      }

      a {
        display: inline-block;
        vertical-align: top;
        color: rgba($base-color, .64);

        &:hover {
          color: $primary;
        }
      }

      &.footer-main__list-columned {
        column-count: 8;
      }
    }
  }

  &-info {
    background: #F0F0F0;
    padding: 40px 0;

    .text-sm {
      display: block;
      margin: 15px 0 0;
      color: rgba($base-color, .32);
    }

    .container {
      @include flexbox(space-between, center);
    }

    &__main {
      @include flexbox(flex-start, center);
    }

    &__txt {
      width: calc(100% - 163px);
      padding-left: 37px;
    }

    &__contacts {
      color: rgba($base-color, .5);

      & * {
        font-family: $base-font-cn;
      }

      &-phone {
        @include flexbox(flex-end, center);
        margin-bottom: 4px;

        .icon {
          @include size(18px);
          vertical-align: top;

          &+.icon {
            margin-left: 12px;
          }

          &+a {
            margin-left: 20px;
          }
        }

        a {
          letter-spacing: 0.02em;
          font-weight: 700;
          display: inline-block;
          vertical-align: top;
          font-size: 22px;
          margin-left: 16px;
          color: inherit;

          &:hover {
            color: $primary;
          }
        }
      }

      &-address {

        @include respond-below(sm) {
          font-size: 16px;
          justify-content: flex-start;
        }

        @include flexbox(flex-end, center);

        .icon {
          @include size(16px);
          opacity: .6;

          &+* {
            margin-left: 8px;
            opacity: .6;
          }
        }

        a {
          font-weight: 700;
          color: inherit;
          margin-left: 16px;
          opacity: .4;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &-logo {
    display: block;
    width: 163px;

    img {
      display: block;
      width: auto;
      //height: 100%;
    }
  }

  &-copyright {
    display: block;
    margin-bottom: 5px;
  }

  &-links {
    list-style: none;
    padding: 0;
    margin: 0 -8px;
    @include flex;

    li {
      margin: 0 8px;
    }

    a {
      color: rgba($base-color, .56);
      display: inline-block;
      vertical-align: top;

      &:hover {
        color: $primary;
      }
    }
  }

  @include respond-below(xl) {
    &-main {
      &__row {
        @include flex;
      }

      &__col {
        width: 30%;

        &:nth-child(even) {
          width: 70%;
        }
      }

      &__list {
        &.footer-main__list-columned {
          column-count: 5;
        }
      }
    }
  }

  @include respond-below(lg) {
    &-info {
      &__contacts {
        margin-bottom: 0;

        &-phone {
          a {
            font-size: 18px;
          }
        }
      }
    }
  }

  @include respond-below(md) {
    border-radius: 24px 24px 0px 0px;

    &-main {
      padding: 24px 0 32px;

      &__row {
        display: block;
        margin: -8px;
      }

      &__col {
        width: 100%;
        padding: 8px;

        &:nth-child(even) {
          width: 100%;
        }
      }

      &__title {
        font-weight: 800;
      }

      &__list {
        &.footer-main__list-columned {
          column-count: 2;
        }
      }
    }

    &-info {
      padding: 32px 0 91px;

      .container {
        flex-direction: column-reverse;
      }

      &__main {
        display: block;
        margin-top: 26px;
        text-align: center;
      }

      &__txt {
        width: 100%;
        padding-left: 0;
      }

      &__contacts {
        & * {
          font-family: $base-font;
        }

        &-phone {
          flex-direction: column;
          text-align: center;
          margin-bottom: 12px;

          .icon {
            display: none;

            &+a {
              margin-left: 0;
            }
          }

          a {
            color: $base-color;
            letter-spacing: 0;
            font-weight: 800;
            font-size: 24px;
            margin-left: 0;
            margin-bottom: 4px;

            &:last-child {
              font-weight: 600;
              font-size: 18px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &-logo {
      display: none;
    }

    &-copyright {
      font-size: 15px;
      margin-bottom: 9px;
    }

    &-links {
      justify-content: center;
      align-items: center;
      font-size: 14px;
      margin: 10px;

      li {
        margin: 2px 4px;
      }
    }
  }
}

.mobile-phone {
  display: none;

  @include respond-below(md) {
    position: fixed;
    background: #36BA5F;
    box-shadow: 5px 5px 16px rgba(68, 176, 73, 0.24);
    @include size(48px);
    border-radius: 50%;
    bottom: 85px;
    right: 16px;
    z-index: 992;
    color: #fff;
    transition: $transition-decor;
    transform: translateY(300%);
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    display: none;

    .icon {
      @include size(20px);
    }

    &.sticky {
      pointer-events: auto;
      transform: translateY(0);
    }
  }
}

.vidget__btn {
  z-index: 4;
  cursor: pointer;
  width: 200px;
  position: fixed;
  left: 1%;
  bottom: 3%;

  img {
    width: 100%;
  }
}

.vidget {
  transition: opacity 0.5s;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: -100%;
  background: rgba(0, 0, 0, 0.5);

  &.active {
    z-index: 20;
    transition: opacity 0.5s;
    opacity: 1;
    top: 0;
  }
}

.vidget__body {
  position: relative;
  padding: 98px 43px 146px;
  background: url('../img/amix_desc.png') center (center / contain) no-repeat;
  margin: 10% auto;
  max-width: 495px;
}

.vidget__close {
  cursor: pointer;
  position: absolute;
  width: 21px;
  height: 21px;
  right: 40px;
  top: 40px;

  span {
    pointer-events: none;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      left: 0;
      top: 50%;
      height: 2px;
      background: #000;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.vidget__header {
  padding: 0 30px;
}

.vidget__ttl {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 35.3903px;
  line-height: 45px;
  color: #E28839;
}

.vidget__subttl {
  font-weight: 700;
  font-size: 15px;
}

.vidget__content {
  margin-top: 18px;
  margin-left: -17px;
  display: flex;
  align-items: center;
  column-gap: 17px;
}

.vidget__percent {
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16.8602px;
  line-height: 22px;
  color: #042641;
  background: url('../img/percent_circle.svg') center (center / cover) no-repeat;
}

.vidget__descr {
  font-weight: 500;
  font-size: 16.8602px;
  line-height: 22px;
  color: #000000;
}

.viget__form {
  width: max-content;

  &_input {
    height: unset;
    margin-top: 17px;
    border: 1.41321px solid #E28E3C;
    border-radius: 66.4209px;
    padding: 6px 11px;
    font-weight: 300;
    font-size: 15px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
  }

  &_btn {
    height: unset;
    margin-top: 23px;
    width: 100%;
    font-weight: 700;
    font-size: 15.6869px;
    line-height: 20px;
    color: #FFFFFF;
    border: none;
    padding: 16px;
    background: #E28839;
    border-radius: 25.7049px;
  }

  &_inline {
    display: unset;

    .form-control__field {
      width: unset;
      margin-right: unset;
    }
  }
}

@media (max-width: 425px) {
  .vidget__body {
    margin: 50% auto;
    padding: 60px 46px 99px;
  }

  .vidget__close {
    top: 0;
  }

  .vidget__ttl {
    font-size: 23.5161px;
    line-height: 30px;
  }

  .vidget__subttl {
    font-size: 14px;
  }

  .vidget__content {
    column-gap: 10px;
    margin-left: -15px;
  }

  .vidget__percent {
    width: 33px;
    height: 33px;
    font-weight: 700;
    font-size: 10.4891px;
    line-height: 13px;
  }

  .vidget__descr {
    font-weight: 500;
    font-size: 10.4891px;
    line-height: 13px;
  }

  .viget__form {
    input {
      margin-top: 7px;
      font-size: 10px;
    }

    button {
      font-size: 10px;
      margin-top: 10px;
      padding: 5px;
    }
  }
}

.mobile-menu {
  display: none;

  &__nav {
    display: none;

    .h5 {
      font-weight: 700;
    }
  }

  @include respond-below(md) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 994;
    background: #fff;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px 11px 40px rgba(0, 0, 0, 0.16);
    transition: $transition-decor;
    transform: translateY(100%);
    pointer-events: none;

    &.sticky {
      transform: translateY(0);
      pointer-events: auto;
    }

    &__nav {
      display: block;
      position: fixed;
      bottom: 0;
      background: #FFFFFF;
      border-radius: 24px;
      height: 90vh;
      overflow: auto;
      left: 0;
      width: 100%;
      box-shadow: 0px 11px 40px rgba(0, 0, 0, 0.16);
      @include hide;
      transition: $transition;
      overflow: auto;
      padding: 16px 16px 100px;
      z-index: 993;

      &.active {
        @include show;
      }
    }

    &__list {
      @include listReset;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__item {
      width: 25%;
      flex-grow: 1;
      text-align: center;
    }

    &__link {
      color: rgba($base-color, .5);
      display: inline-block;
      vertical-align: top;
      font-weight: 600;
      font-size: 11px;
      line-height: 1;
      padding: 10px 5px 7px;

      &:hover {
        color: $base-color;
      }
    }

    &__icon {
      @include size(32px);
      display: block;
      margin: 0 auto 4px;

      .icon {
        @include size(100%);
      }
    }
  }

  @include respond-below(sx) {
    &.sticky {
      display: none;
    }

    &__nav {
      padding: 60px 16px 100px;
    }

    &__close {
      position: absolute;
      z-index: 3;
      top: 60px;
      right: 15px;
      width: 25px;
      height: 25px;
      background: url('../svg/close-yellow.svg') no-repeat 0 0;
      background-size: contain;
      border: 0;
      padding: 0;
      outline: none;
      cursor: pointer;
    }
  }

  @include respond-below(xs) {
    &__icon {

      &,
      .favorites-link {
        width: 22px;
        height: 22px;
      }
    }

    &__link {
      padding: 7px 5px 7px;
      font-size: 10px;
    }
  }
}

.brands-menu {
  @include flex;
  @include listReset;

  &__icon {
    @include size(40px);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      max-height: 100%;
    }
  }

  &__title {
    width: calc(100% - 50px);
    padding-left: 15px;
  }

  li {
    width: calc(100% / 8);
    padding: 3px;

    a {
      display: flex;
      border-radius: 16px;
      padding: 14px;
      align-items: center;
      justify-content: flex-start;
      color: $base-color;
      font-weight: 700;
      font-size: 15px;

      small {
        display: block;
        font-size: 12px;
        opacity: .5;
      }

      &:hover {
        background: #F3F3F3;
      }
    }
  }

  @include respond-below(xl) {
    li {
      width: calc(100% / 7);
    }
  }

  @include respond-below(lg) {
    li {
      width: calc(100% / 6);
    }
  }

  @include respond-below(md) {
    li {
      width: 20%;
    }
  }

  @include respond-below(sm) {
    li {
      width: 50%;
    }
  }
}

#modalRoute1.modal,
#modalRoute2.modal,
#modalRoute3.modal {
  max-width: 60%;
  padding: 20px;
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  @include respond-below(sm) {
    max-width: 95%;
  }
}

#modalRoute1.modal .modal-content,
#modalRoute2.modal .modal-content,
#modalRoute3.modal .modal-content {
  max-height: 100%;
  max-width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modalRoute1.modal .modal-content img,
#modalRoute2.modal .modal-content img,
#modalRoute3.modal .modal-content img {
  max-height: 450px;
}

.cars-menu {
  @include flex;
  @include listReset;
  padding-top: 40px;

  li {
    width: calc(100% / 6);
    padding: 10px;

    img {
      display: block;
      height: 46px;
      mix-blend-mode: darken;
      margin: 0 auto 16px;
    }

    a {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: rgba($base-color, .5);
      text-align: center;

      &:hover {
        color: $primary;
      }
    }
  }

  @include respond-below(md) {
    li {
      width: calc(100% / 3);
    }
  }

  @include respond-below(sm) {
    li {
      width: 50%;
      padding: 3px;

      a {
        text-align: left;
      }

      img {
        margin-left: 0;
      }
    }
  }
}

.programs-menu {
  list-style: none;
  padding: 0;
  margin: -8px;
  @include flex;

  li {
    width: calc(100% / 3);
    padding: 8px;
  }

  &__link {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 24px;
    box-shadow: 0 15px 16px rgba(0, 0, 0, 0.03);
    padding: 24px;
    overflow: hidden;
    min-height: 200px;
    background-repeat: no-repeat !important;
    // background-position: 50% 50% !important;
    background-size: cover !important;

    .h5 {
      margin-bottom: 4px;
      font-weight: 700;
      position: relative;
      z-index: 1;
    }

    p {
      margin-top: 0;
      margin-bottom: 6px;
      position: relative;
      z-index: 1;
    }

    &:hover {
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.03);

      .btn {
        background: $primary !important;
        color: #fff;
      }
    }

    .btn {
      margin-top: auto;
      color: inherit;
      pointer-events: none;
      position: relative;
      z-index: 1;
    }

    &.programs-menu__link--credit {
      background: #EDF7FD;
      color: $base-color;

      .btn {
        background: rgba(34, 34, 34, 0.12);
      }
    }

    &.programs-menu__link--family {
      background: radial-gradient(23.07% 68.17% at 71.61% 51.67%, #424C80 0%, #3D446B 100%);
      color: #fff;

      .btn {
        background: rgba(255, 255, 255, 0.12);
      }
    }

    &.programs-menu__link--first {
      background: radial-gradient(27.86% 82.32% at 70.61% 57.56%, #2C4B79 0%, #1E3251 100%);
      color: #fff;

      .btn {
        background: rgba(255, 255, 255, 0.12);
      }
    }

    &.programs-menu__link--trade {
      background: radial-gradient(32.11% 94.89% at 73.94% 22.59%, #2C4B79 0%, #1E3251 100%);
      color: #fff;

      .btn {
        background: rgba(255, 255, 255, 0.12);
      }
    }

    &.programs-menu__link--medicine {
      background: #EDF7FD;
      color: $base-color;

      .btn {
        background: rgba(34, 34, 34, 0.12);
      }
    }

    &.programs-menu__link--dark-blue {
      background: #203555;
      color: #fff;

      .btn {
        background: rgba(255, 255, 255, 0.12);
      }
    }

    &.programs-menu__link--gray {
      background: #eaeaec;
      color: $base-color;

      .btn {
        background: rgba(34, 34, 34, 0.12);
      }
    }
  }

  .gos-sign {
    position: absolute;
    background-size: contain !important;
    z-index: 9;

    &.__lg {
      background: url('../svg/gos-sign-sm.svg') no-repeat 0 0;
      bottom: -85px;
      right: -70px;
      width: 231px;
      height: 250px;
    }

    &.__md {
      background: url(../svg/gos-sign.svg) no-repeat 0 0;
      top: -40px;
      right: -50px;
      width: 160px;
      height: 140px;
    }
  }

  @include respond-below(md) {
    margin: -4px;

    li {
      width: 50%;
      padding: 4px;
    }
  }

  @include respond-below(sm) {
    li {
      width: 100%;
    }
  }
}

a[href='credit'] {
  background-image: url(../img/new_credit_bg.jpg) !important;
}

a[href='family-car'] {
  background-image: url(../img/new_family-car_bg.jpg) !important;
}

a[href='first-car'] {
  background-image: url(../img/new_first-car_bg.jpg) !important;
}

a[href='gosprogramma-trade-in'] {
  background-image: url(../img/new_tradein_bg.jpg) !important;
}

a[href='medicine'] {
  background-image: url(../img/new_medicine_bg.jpg) !important;
}

a[href='rassrocka'] {
  background-image: url(../img/new_rassrocka_bg.jpg) !important;
}

.programs {
  @include flex();
  margin: 0 -16px;

  &-col {
    width: calc(100% / 2);
    padding: 16px;

    .gos-sign {
      display: block;
      position: absolute;
      z-index: 1;
      top: 15px;
      right: 15px;
      bottom: auto;
      background: url('../svg/gos-sign-circle.svg') no-repeat 0 0;
      background-size: contain;
      width: 42px;
      height: 42px;
    }

    @include respond-below(xl) {
      width: calc(100% / 3);
    }

    @include respond-below(lg) {
      width: calc(100% / 2);
    }

    @include respond-below(sm) {
      width: 100%;
    }
  }
}

.header {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // transition: $transition-decor;
  z-index: 991;

  &-light {
    background: #FAFAFA;
  }

  &.sticky {
    background: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    transform: translateY(-78px);

    .header-main {
      opacity: 0;
      pointer-events: none;
    }

    .header-menu {
      &.active {
        margin-top: 78px;
      }

      &__close {
        display: block;
      }
    }
  }

  &-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 22px;
    transition: $transition-decor;

    .btn {
      position: relative;
      z-index: 991;

      .icon {
        vertical-align: middle;
        @include size(30px);
      }
    }

    &__menu {
      flex-grow: 1;

      ul {
        @include listReset;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          margin: 0 10px;
        }

        a {
          display: inline-block;
          vertical-align: top;
          color: rgba($base-color, .7);

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  &-pull {
    @include size(28px);
    position: relative;
    padding: 0;
    z-index: 991;

    span {
      background: $base-color;
      height: 4px;
      border-radius: 4px;
      position: absolute;
      left: 50%;
      width: 100%;
      margin-left: -14px;
      top: 50%;
      margin-top: -2px;
      transition: $transition;

      &:nth-child(1) {
        margin-top: -11px;
      }

      &:nth-child(2) {}

      &:nth-child(3) {
        margin-top: 7px;
      }
    }

    &.active {
      span {
        background: $primary;

        &:nth-child(1) {
          margin-top: -2px;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          margin-top: -2px;
          transform: rotate(-45deg);
          opacity: 0;
        }

        &:nth-child(3) {
          margin-top: -2px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &-logo {
    color: $primary;
    display: block;
    width: 250px;
    height: 45px;
    margin-left: 24px;
    margin-right: 24px;
    position: relative;
    z-index: 991;

    .icon {
      @include size(auto, 100%);
    }

    &.active {
      color: $base-color;
    }
  }

  &-address {
    padding-left: 24px;
    border-left: 2px solid rgba($base-color, .08);
    position: relative;
    z-index: 991;

    &__time {
      display: block;
      color: rgba($base-color, .6);
    }

    &__location {
      display: block;
      color: $base-color;
      font-weight: 700;
      line-height: 1.3;
    }

    &__mobile {
      display: none;

      @include respond-below(md) {
        display: block;
        margin: 10px 0;
        font-size: 14px;
        color: rgba(34, 34, 34, 0.5);
      }
    }
  }

  &-phone {
    display: block;
    position: relative;
    z-index: 991;
    font-weight: 800;
    font-size: 22px;
    color: $base-color;
    letter-spacing: 0.02em;
    margin: 0 28px;

    &:hover {
      color: $primary;
    }

    &__mobile {
      display: none;

      @include respond-below(md) {
        display: block;
        margin: 5px 0;
        font-size: 16px;
        color: #222222;
        font-weight: 600;
      }
    }
  }

  &-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__menu {
      flex-grow: 1;

      .header-navigation__menu-has-children:hover {
        .header-navigation__menu-lvl-2 {
          @include show;
        }
      }

      &-lvl-1 {
        @include listReset;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: static;

        &>li {
          padding: 22px 0;
          position: static;

          &>a {
            display: inline-block;
            vertical-align: top;
            color: rgba($base-color, .8);
            text-transform: uppercase;
            line-height: 2;
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.04em;
            position: relative;

            &:hover {
              color: rgba($base-color, .3);
            }
          }

          &.header-navigation__menu-has-children {
            &>a {
              &:after {
                @include pseudo();
                @include size(10px);
                top: 50%;
                margin-top: -5px;
                right: -22px;
                background: url(../img/menu-arrow.svg) no-repeat center;
                -webkit-background-size: contain;
                background-size: contain;
              }
            }
          }
        }
      }

      &-lvl-2 {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 100%;
        padding: 36px 60px;
        z-index: 99;
        transform: translateX(-50%);
        @include hide;
        transition: $transition;
        transition-delay: 0.1s;

        @include respond-below(xxl) {
          padding-left: 50px;
          padding-right: 50px;
        }

        @include respond-below(xl) {
          padding-left: 0;
          padding-right: 0;
        }


        &:before {
          @include pseudo();
          top: 0;
          width: 100vw;
          background: #FFFFFF;
          border-radius: 0px 0px 24px 24px;
          box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
          border-top: 1px solid #E9E9E9;
        }

        ul {
          position: relative;
          z-index: 1;
        }
      }
    }

    &__controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 70px;

      .favorites-link {
        @include size(24px);

        .icon {
          stroke: rgba($base-color, .4);
        }

        &.active {
          .icon {
            fill: $base-color;
            stroke: $base-color;
          }
        }

        &:hover {
          .icon {
            fill: $primary;
            stroke: $primary;
          }
        }
      }

      .search-link {
        margin-left: 32px;
        color: rgba($base-color, .4);
        @include size(24px);

        &:hover {
          color: $base-color;
        }
      }
    }
  }

  &-menu {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: $transition;
    @include hide;
    transform: translateY(-100px);

    &.active {
      transform: translateY(0);
      @include show;
    }

    &__mobile {
      display: none;
    }

    &__search {
      position: relative;
      margin-bottom: 50px;

      .search-link {
        position: absolute;
        top: 50%;
        left: 32px;
        @include size(22px);
        margin-top: -11px;
      }

      .btn {
        position: absolute;
        top: 50%;
        margin-top: -24px;
        right: 14px;
      }

      .form-control {
        border: 1px solid #E9E9E9;
        border-radius: 16px;
        padding: 0 120px 0 73px;
        background: #fff;
      }
    }

    &__desktop {
      display: flex;

      &-col {
        width: calc(100% / 3);

        &:nth-child(2n) {
          width: calc((100% / 3) * 2);
        }

        .h4 {
          margin-bottom: 24px;
          display: block;
        }
      }

      &-lvl-1 {
        @include listReset;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 30px;

        li+li {
          margin-top: 9px;
        }

        a {
          color: $base-color;
          display: inline-block;
          vertical-align: top;

          &:hover {
            color: $primary;
          }
        }
      }

      &-lvl-2 {
        @include listReset;

        li {
          margin-bottom: 8px;
        }

        a {
          display: inline-block;
          vertical-align: top;
          color: rgba($base-color, .64);

          &:hover {
            color: $primary;
          }
        }
      }

      &-lvl-3 {
        display: flex;
        list-style: none;
        margin: -24px;
        padding: 0;
        justify-content: flex-start;

        li {
          padding: 24px;
        }

        a {
          color: $base-color;
          display: inline-block;
          vertical-align: top;

          &:hover {
            color: $primary;
          }
        }
      }

      &-row {
        margin-top: 42px;
        @include flex;

        .header-menu__desktop-lvl-2 {
          column-count: 4;
        }

        &-col {
          width: 50%;
        }
      }
    }

    &__close {
      display: none;
      position: fixed;
      top: 30px;
      right: 30px;
      width: 30px;
      height: 30px;
      padding: 0;
      background: url('../svg/close-yellow.svg') no-repeat 0 0;
      background-size: contain;
      border: 0;
      outline: none;
      cursor: pointer;
    }
  }

  @include respond-below(xl) {
    &.sticky {
      transform: translateY(-71px);
    }

    &-main {
      padding-top: 15px;
      font-size: 14px;

      .btn:not(.btn-sm) {
        padding-left: 20px;
        padding-right: 20px;

        .icon {
          @include size(24px);
        }
      }

      &__menu {
        ul {
          li {
            margin: 0 7px;
          }
        }
      }
    }

    &-pull {
      @include size(24px);

      span {
        height: 2px;
        margin-left: -12px;
        margin-top: -1px;

        &:nth-child(1) {
          margin-top: -10px;
        }

        &:nth-child(2) {}

        &:nth-child(3) {
          margin-top: 8px;
        }
      }

      &.active {
        span {
          &:nth-child(1) {
            margin-top: -1px;
          }

          &:nth-child(2) {
            margin-top: -1px;
          }

          &:nth-child(3) {
            margin-top: -1px;
          }
        }
      }
    }

    &-logo {
      height: 50px;
      margin-left: 14px;
      margin-right: 20px;
    }

    &-address {
      padding-left: 20px;
    }

    &-phone {
      font-size: 18px;
      margin: 0 20px;
    }

    &-navigation {
      position: relative;
      z-index: 992;

      &__menu {
        &-lvl-1 {
          &>li {
            padding: 18px 0;

            &>a {
              font-size: 14px;
            }

            &.header-navigation__menu-has-children {
              &>a {
                &:after {
                  right: -18px;
                }
              }
            }
          }
        }
      }

      &__controls {
        padding-left: 60px;

        .search-link {
          margin-left: 24px;
        }
      }
    }

    &-menu {
      &__close {
        top: 60px;
      }
    }
  }

  @include respond-below(lg) {
    &.sticky {
      transform: none;

      .header-main {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &-main {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 100px;

      &__menu {
        display: none;
      }

      .btn.btn-primary:not(.btn-sm) {
        height: auto;
        line-height: normal;
        padding: 0;
        background: none;
        box-shadow: none;
        color: $primary;

        &:hover {
          color: darken($primary, 10%);
        }
      }
    }

    &-logo {
      margin-right: auto;
    }

    &-address {
      display: none;
    }

    &-phone {
      margin-left: auto;
    }

    &-navigation {
      padding: 0;
      position: relative;

      &__menu {
        display: none;
      }

      &__controls {
        padding-left: 0;
        position: absolute;
        bottom: 100%;
        height: 94px;
        right: 0;
      }
    }

    &-menu {
      &__close {
        top: 10px;
      }
    }
  }

  @include respond-below(md) {
    background: #fff;
    position: absolute;

    &-light {
      background: #fff;
      border-bottom: 1px solid rgba(#000, .15);
    }

    &.sticky {
      background: #fff;
      box-shadow: none;
      transform: none;

      .header-main {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &-main {
      height: 86px;
      padding: 53px 24px 19px 0;
      justify-content: center;
      position: relative;

      .btn.btn-primary:not(.btn-sm) {
        font-size: 15px;
        font-weight: 700;
        position: absolute;
        top: 9px;
        right: 0;
        line-height: 20px;
        text-transform: lowercase;
      }
    }

    &-logo {
      width: 220px;
      height: 40px;
      margin: 0 auto;
    }

    &-phone-block {
      position: absolute;
      top: 9px;
      left: 0;
    }

    &-phone {
      display: inline-block;
      vertical-align: middle;
      letter-spacing: 0;
      margin: 0;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: rgba($base-color, .5);

      &:hover {
        color: $primary;
      }

      &:last-child {
        margin-left: 10px;
      }

      @include respond-below(sx) {
        &:last-child {
          display: none;
        }
      }
    }

    &-navigation {
      &__controls {
        .favorites-link {
          display: none;
        }

        height: 43px;
        padding-bottom: 9px;
      }
    }

    &-menu {
      height: auto;
      padding-top: 86px;
      padding-bottom: 16px;
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);

      &__mobile {
        display: block;

        ul {
          @include listReset;

          li+li {
            border-top: 1px solid #E9E9E9;
          }

          a {
            display: block;
            font-weight: 600;
            font-size: 16px;
            height: 36px;
            line-height: 36px;
            color: $base-color;
          }
        }

        &.__no-borders {
          ul li+li {
            border: 0;
          }

          ul {
            a {
              height: 28px;
              line-height: 28px;
            }
          }
        }

        &.__border-bottom {
          border-bottom: 1px solid #e9e9e9;
        }

        &.__margin-bottom {
          margin-bottom: 15px;
        }

        &.__padding-bottom {
          padding-bottom: 15px;
        }

        &-icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
          width: 28px;
          height: 20px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;

          &.__auto {
            background-image: url('../svg/m-auto.svg');
          }

          &.__credit {
            background-image: url('../svg/m-credit.svg');
          }

          &.__insurance {
            background-image: url('../svg/m-insurance.svg');
          }

          &.__recycling {
            background-image: url('../svg/m-recycling.svg');
          }

          &.__taxi-credit {
            background-image: url('../svg/m-taxi-credit.svg');
          }

          &.__favorite {
            background-image: url('../svg/m-favorite.svg');
          }
        }
      }

      &__search {
        display: none;
      }

      &__desktop {
        display: none;
      }

      &__close {
        display: none;
      }
    }
  }

  @include respond-below(sx) {
    &-menu {
      &.active {
        max-height: 100vh;
        overflow-y: auto;
      }
    }

    .header {
      &-logo {
        width: 190px;
        height: 40px;
      }
    }
  }
}

.catalog {
  @include flex;
  margin: -16px;

  &-col {
    width: 25%;
    padding: 16px;
  }

  &-item {
    height: 100%;
    border: 1px solid #E9E9E9;
    border-radius: 24px;
    position: relative;
    padding: 30px 18px 18px;

    .tag {
      position: absolute;
      top: 19px;
      left: 19px;
    }

    .favorites-link {
      position: absolute;
      right: 19px;
      top: 17px;
      z-index: 2;
    }

    &__img {
      display: flex;
      position: static;
      align-items: flex-end;
      justify-content: center;
      height: 136px;

      img {
        max-height: 100%;
        display: block;
      }

      &-title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        font-weight: 800;
        font-size: 81px;
        line-height: 1;
        color: rgba($base-color, .03);
        text-align: center;
        max-width: 100%;
        overflow: hidden;
        text-transform: uppercase;
        font-family: $base-font-cn;
      }

      &.link-stretched {
        position: static;

        .catalog-item__img-title {
          top: 24px;
        }
      }
    }

    &__txt {
      text-align: center;
      margin-top: 28px;

      &-list {
        @include listReset;
        margin-bottom: 24px;
        column-count: 2;
        font-size: 13px;
        line-height: 1.54;
      }
    }

    &__model {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      margin-bottom: 5px;

      img {
        width: 24px;
        margin-right: 11px;
        display: inline-block;
      }
    }

    &__price {
      &-title {
        font-weight: 600;
        color: #E4AE55;
        display: block;
      }

      &-current {
        display: block;
        font-weight: 600;
        color: #E4AE55;
        font-size: 30px;
        line-height: 1.06;
      }

      &-old {
        font-weight: 600;
        display: block;
        color: rgba($base-color, .4);
        text-decoration: line-through;
      }

      &-credit {
        display: block;
        margin-top: 8px;
      }
    }

    &__buttons {
      margin-top: 23px;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 1;

      .btn {
        height: 48px;
        line-height: 50px;
        width: calc(50% - 4px);
        padding: 0;
        flex-grow: 1;
        margin: 0 4px;
        font-size: 15px;
      }
    }

    &__slider {
      margin: -30px -18px 0;
      position: relative;
      z-index: 2;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      overflow: hidden;
      width: calc(100% + 36px);

      .swiper-container {
        width: 100%;
        margin: 0;
        padding: 0;
      }

      &-img {
        display: block;
        position: relative;
        overflow: hidden;

        &:before {
          @include pseudoHeight(66%);
        }

        img {
          @include img-cover;
        }
      }

      .swiper-pagination {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 2px;

        .swiper-pagination-bullet {
          flex-grow: 1;
          width: auto;
          height: 100%;
          border-radius: 0;
          margin: 0 2px;
          background: transparent;
          border-bottom: 4px solid rgba(#fff, .5);
          opacity: 1;

          &-active {
            flex-grow: 1;
            width: auto;
            opacity: 1;
            border-bottom: 4px solid $primary;
          }
        }
      }
    }

    &.catalog-item--used {
      .favorites-link {
        z-index: 3;
        background: #ffffff;
        @include size(40px);
        box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
        border-radius: 8px;
        padding: 9px;
      }

      .catalog-item__txt {
        text-align: left;
        margin-top: 20px;
      }

      .catalog-item__model {
        line-height: 1.3;
        font-size: 24px;
        padding-bottom: 14px;
        border-bottom: 1px solid #E9E9E9;
        display: block;
        text-align: left;
        font-weight: 400;
        color: $base-color;
        margin-bottom: 14px;
      }

      .catalog-item__price-current {
        color: $base-color;
      }

      .catalog-item__price-credit {
        color: rgba($base-color, .6);
      }
    }
  }

  @include respond-above(md) {
    &-slider {
      .swiper-wrapper {
        transform: none !important;
        flex-wrap: wrap;
        margin: -10px;
      }

      .swiper-slide {
        padding: 10px;
      }
    }
  }

  @include respond-above(xl) {
    &-slider {
      .swiper-wrapper {
        margin: -16px;
      }

      .swiper-slide {
        padding: 16px;
      }
    }
  }

  @include respond-below(xl) {
    &-col {
      width: calc(100% / 3);
    }
  }

  @include respond-below(lg) {
    margin: -10px;

    &-col {
      padding: 10px;
    }

    &-item {
      padding: 30px 12px 18px;

      &__slider {
        margin: -30px -12px 0;

        width: calc(100% + 24px);
      }
    }
  }

  @include respond-below(md) {
    margin: -4px -21px;

    &-col {
      padding: 4px;
      width: 50%;
    }

    &-item {
      .btn {
        font-size: 14px;
      }

      &__img {
        &-title {
          font-size: 69px;
        }
      }
    }
  }

  @include respond-below(sm) {
    &-col {
      width: 100%;
    }
  }
}

.proposals {
  @include flex;
  margin: -16px;

  &-col {
    width: 50%;
    padding: 16px;
  }

  &-item {
    height: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    position: relative;
    padding: 32px 40px 50px;
    overflow: hidden;
    background-repeat: no-repeat !important;
    background-position: 50% 50%;
    background-size: cover !important;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, .3));
    }

    .gos-sign {
      position: absolute;
      z-index: 1;
      bottom: -80px;
      right: -50px;
      background: url('../svg/gos-sign-sm.svg') no-repeat 0 0;
      background-size: contain;
      width: 231px;
      height: 250px;
    }

    &.proposals-item--blue {
      background: #E3F1FC;

      .btn {
        &:hover {
          background: $primary;
          color: $primary-second
        }
      }
    }

    &.proposals-item--dark-blue {
      background: #203555;

      .btn-light,
      .proposals-item__title,
      .proposals-item__txt p {
        color: #ffffff !important;
      }

      .btn-light {
        background: rgba(255, 255, 255, 0.2);

        &:hover {
          background: $primary;
          color: $primary-second;
        }
      }
    }

    &.proposals-item--grey {
      background: #EAEAEC;

      .btn {
        &:hover {
          background: $primary-second;
        }
      }
    }

    &.proposals-item--light {
      background: #F1F3F8;
    }

    &.proposals-item--yellow {
      background: #FEF7EA;
    }

    &__txt {
      width: 55%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      z-index: 1;
      position: relative;
    }

    &__title {
      display: block;
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 8px;
      line-height: 1.14;

      .__vertical-middle {
        vertical-align: middle;
      }
    }

    p {
      margin: 0;
      color: rgba($base-color, .6);
      flex-grow: 1;
    }

    .btn {
      margin-top: 40px;
      z-index: 2;
    }
  }

  @include respond-above(md) {
    &-slider-desktop {
      .swiper-wrapper {
        transform: none !important;
        flex-wrap: wrap;
        margin: -10px;
        width: calc(100% + 20px);
      }

      .swiper-slide {
        padding: 10px;
        margin: 0 !important;
        width: 50% !important;
      }
    }
  }

  @include respond-above(lg) {
    &-slider-desktop {
      .swiper-wrapper {
        margin: -16px;
        width: calc(100% + 32px);
      }

      .swiper-slide {
        padding: 16px;
      }
    }
  }

  @include respond-below(xl) {
    &-item {
      &__txt {
        width: 70%;
      }

      p {
        max-width: 80%;
      }

      &__title {
        font-size: 24px;
      }
    }
  }

  @include respond-below(lg) {
    margin: -10px;

    &-col {
      padding: 10px;
    }

    &-item {
      &__txt {
        width: 90%;
      }

      &__title {
        font-size: 22px;
      }
    }
  }

  @include respond-below(md) {
    &-item {
      padding: 225px 0 0;

      &__txt {
        width: 100%;
        padding: 23px 16px 16px;
        background: rgba(#fff, .32);
      }

      &__title {
        font-size: 19px;
      }

      .btn {
        margin-top: 24px;
        width: 100%;
      }
    }
  }

  @include respond-below(sm) {
    &-item {
      &__txt {
        p {
          display: none;
        }
      }
    }
  }

  @include respond-below(sx) {
    &-item {
      .gos-sign {
        top: 15px;
        right: 15px;
        bottom: auto;
        background: url('../svg/gos-sign-circle.svg') no-repeat 0 0;
        background-size: contain;
        width: 42px;
        height: 42px;
      }
    }
  }
}

.partners {
  @include flex;
  margin: -16px;

  &-col {
    width: calc(100% / 7);
    padding: 16px;
  }

  &-item {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      max-height: 100%;
    }
  }

  @include respond-below(sm) {
    &-slider {
      .swiper-slide {
        width: 86px;
      }
    }
  }
}

.guarantees {
  @include flex;
  margin: -8px;

  &-col {
    width: 25%;
    padding: 8px;
  }

  &-item {
    height: 100%;
    background: #EAEAEC;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    position: relative;
    padding: 24px 32px;
    min-height: 176px;
    cursor: pointer;

    &__img {
      display: flex;
      position: absolute;
      width: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      justify-content: flex-end;
      align-items: flex-end;

      img {
        max-height: 100%;
        display: block;
      }
    }

    &__txt {
      width: 60%;
      position: relative;
    }

    .text-xl {
      display: block;
      margin-bottom: 8px;
      line-height: 1.2;

      b {
        display: block;
      }
    }

    p {
      margin: 0;
      color: rgba($base-color, .6);
    }
  }

}

.brand-benefits {
  @include flex;
  margin: -8px;

  &__col {
    width: 50%;
    padding: 8px;

    &.brand-benefits__col-narrow {
      width: calc(100% / 3);

      .brand-benefits__item-img {
        &:before {
          @include pseudoHeight(65%);
        }
      }
    }

    &.brand-benefits__col-wide {
      width: calc((100% / 3) * 2);

      .brand-benefits__item-img {
        &:before {
          @include pseudoHeight(38%);
        }
      }
    }
  }

  &__item {
    height: 100%;
    background: #F3F3F3;
    border-radius: 16px;
    position: relative;
    padding: 40px 48px;

    &.brand-benefits__item-img {
      padding: 0;
      overflow: hidden;

      img {
        @include img-cover;
      }
    }
  }

  &__title {
    display: block;
    font-size: 28px;
    line-height: 1.14;
    margin-bottom: 8px;
    font-weight: 700;
  }

  p {
    margin: 0;
  }

  @include respond-below(xs) {
    &__title {
      font-size: 24px;
    }
  }

  @include respond-below(lg) {
    &__title {
      font-size: 22px;
    }
  }

  @include respond-below(md) {
    margin: -4px -21px;

    &__col {
      padding: 4px;
    }

    &__item {
      padding: 16px;
      font-size: 14px;
    }

    &__title {
      font-size: 19px;
    }
  }

  @include respond-below(md) {
    &__col {
      width: 100%;

      &.brand-benefits__col-wide,
      &.brand-benefits__col-narrow {
        width: 50%;

        .brand-benefits__item-img {
          &:before {
            @include pseudoHeight(65%);
          }
        }
      }
    }
  }
}

div.archive-cars {
  display: flex;
  flex-direction: column;

  &__item {
    display: none;

    &+.archive-cars__item {
      margin-top: 10px;
    }

    &.active {
      display: flex;
    }
  }
}

.news {
  @include flex;
  margin: -16px;

  &-col {
    width: 25%;
    padding: 16px;

    @include respond-below(xl) {
      width: calc(100% / 3);
    }

    @include respond-below(md) {
      width: calc(100% / 2);
    }

    @include respond-below(xs) {
      width: 100%;
    }
  }

  &-item {
    height: 100%;
    position: relative;

    &__img {
      position: relative;
      overflow: hidden;
      display: block;
      border-radius: 16px;

      .tag {
        position: absolute;
        bottom: 16px;
        left: 16px;
        z-index: 2;
      }

      &:before {
        @include pseudoHeight(63%);
      }

      img {
        @include img-cover;
      }
    }

    &__txt {
      padding: 16px 16px 0;

      p {
        margin: 0;
        color: rgba($base-color, .6);
      }
    }

    &__brand {
      display: inline-block;
      vertical-align: middle;
      width: 59px;
      margin-right: 11px;

      img {
        width: 100%;
      }
    }

    &__date {
      font-weight: 600;
      display: inline-block;
      vertical-align: middle;
      color: rgba($base-color, .4);
    }

    &__title {
      display: block;
      margin: 8px 0 12px;
      font-weight: 700;
      color: $base-color;
      transition: $transition;

      &:hover {
        color: $primary;
      }
    }
  }
}

.list-block {
  background: #3E4757;
  box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  padding: 82px;
  color: #fff;

  *+& {
    margin-top: 32px;
  }

  .btn {
    margin-top: 40px;
  }

  .h5 {
    margin-bottom: 32px;
    margin-top: 0;
    max-width: 640px;
    font-weight: 400;

    &+h4,
    &+.h4 {
      margin-top: 48px;
    }
  }

  .text-xl {
    margin-bottom: 32px;
    margin-top: 0;
    max-width: 640px;

    &+h4,
    &+.h4 {
      margin-top: 48px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4,
  .h4 {
    margin-bottom: 28px;
  }

  .text-xxl {
    color: #fff;
    margin-bottom: .85em;
    display: block;
    font-weight: 600;
  }

  &__decor {
    position: absolute;
    top: -10px;
    left: 0;
    width: 45%;
    bottom: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    img {
      display: block;
      max-height: 100%;
    }
  }

  &__content {
    margin-left: auto;
    width: 55%;
  }

  .list {
    max-width: 600px;
    color: rgba(#fff, .8);

    &+h4,
    &+.h4 {
      margin-top: 48px;
    }
  }

  @include respond-below(md) {
    margin-left: -17px;
    margin-right: -17px;
    padding: 246px 24px 30px;

    *+& {
      margin-top: 16px;
    }

    &+.btn {
      margin-bottom: 40px;
    }

    &__decor {
      top: -10px;
      height: 246px;
      width: 100%;
      bottom: auto;

      img {
        display: block;
        max-height: 100%;
      }
    }

    &__content {
      margin-left: 0;
      width: 100%;
    }
  }

  @include respond-below(sm) {
    .btn {
      width: 100%;
    }
  }
}

.banks {
  @include flex;
  margin: -16px;

  &-col {
    width: 20%;
    padding: 16px;
  }

  &-slider {
    .banks-item {
      height: calc(50% - 8px);
    }
  }

  &-item {
    height: 100%;
    position: relative;
    border-radius: 24px;
    font-size: 15px;
    padding: 22px;

    &+.banks-item {
      margin-top: 16px;
    }

    &.banks-item--navy {
      background: #DFE5EB;
      color: rgba(#295379, .72);

      b {
        color: #295379;
      }
    }

    &.banks-item--green {
      background: rgba(23, 113, 58, 0.16);
      color: rgba(#17713A, .72);

      b {
        color: #17713A;
      }
    }

    &.banks-item--yellow {
      background: #FEFCD6;
      color: rgba(#000, .72);

      b {
        color: #000;
      }
    }

    &.banks-item--blue {
      background: #DFF4FB;
      color: rgba(#58585A, .72);

      b {
        color: #58585A;
      }
    }

    &.banks-item--red {
      background: #F6DFDD;
      color: rgba(#E3381F, .72);

      b {
        color: #E3381F;
      }
    }

    &__img {
      display: flex;
      height: 40px;
      align-items: center;
      justify-content: center;

      img {
        max-height: 100%;
      }
    }

    ul {
      @include listReset;
    }
  }

  @include respond-below(xl) {
    &-col {
      width: 25%;
    }
  }

  @include respond-below(md) {
    &-item {
      &+.banks-item {
        margin-top: 8px;
      }
    }
  }

  @include respond-below(sm) {
    &-slider {
      .swiper-wrapper {
        transform: none !important;
        height: 500px;
        flex-wrap: wrap;
        margin-right: -24px;
        width: calc(100% + 24px);
        overflow: hidden;
        border: 1px solid #EEEDED;
        border-radius: 24px;
      }

      .swiper-slide {
        margin: 0 !important;
        width: 50% !important;
      }

      .banks-item {
        height: 100px;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none !important;
        border-radius: 0 !important;
        box-shadow: inset 0 0 0 .5px #EEEDED;
      }
    }

    &-item {
      &+.banks-item {
        margin-top: 0;
      }

      &__img {
        margin: 0;
      }

      ul {
        display: none;
      }
    }
  }
}

.video {
  @include flex;
  margin: -16px;

  &-col {
    width: calc(100% / 3);
    padding: 16px;

    // @include respond-below(md) {
    //   width: calc(100% / 2);
    // }

    @include respond-below(md) {
      width: 100%;
    }
  }

  &-item {
    height: 100%;

    &__tags {
      position: absolute;
      left: 16px;
      bottom: 16px;
    }

    &__media {
      position: relative;
      overflow: hidden;
      display: block;
      border-radius: 24px;

      &:before {
        @include pseudoHeight(47%);
      }

      img,
      iframe {
        @include img-cover;
      }

      iframe {
        display: block;
        border: 0;
      }
    }

    &__txt {
      padding: 16px 16px 0;

      .link {
        margin-left: 43px;
        margin-top: 4px;
        text-transform: lowercase;
      }
    }

    &__quote {
      display: block;
      position: relative;
      padding-left: 43px;
      color: rgba($base-color, .8);
      margin-top: 8px;

      &:before {
        @include pseudo();
        width: 27px;
        height: 23px;
        background: url(../img/quote.svg) no-repeat center;
        -webkit-background-size: contain;
        background-size: contain;
        top: 3px;
        left: 0;
        opacity: .12;
      }
    }
  }
}

.gifts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .special-item {
    width: 32%;
    height: unset;
    margin-bottom: 16px;
    overflow: hidden;

    @include respond-below(md) {
      width: 48%;
    }

    @include respond-below(sm) {
      width: 100%;
      padding: 0;
    }

    &+.special-item {
      margin-top: 0;
    }

    &__title {
      @include respond-below(sm) {
        margin-bottom: 0;
        padding: 15px 15px 15px 10px;
        background-color: #DCDCDC;
      }
    }

    &__body {
      @include respond-below(sm) {
        width: auto;
        margin: 0 auto;
        padding: 5px 5px 15px 21%;
        display: flex;
        flex-direction: column;
      }
    }

    &__discount {
      @include respond-below(sm) {
        color: #000;
      }

      &.active {
        @include respond-below(sm) {
          color: #000;
        }
      }
    }
  }
}

.competitor-proposal {
  background: #FFFFFF;
  box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  position: relative;

  &__decor {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 24.6%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    width: 75%;
    margin-left: auto;
    padding: 63px 63px 63px 0;
  }

  &__mobile-img {
    display: none;
  }

  &__mobile-bg {
    display: none;
  }

  @include respond-above(lg) {
    &.competitor-proposal--home {
      color: #fff;
      background: $primary-second;
      margin-bottom: 70px;

      a {
        color: #fff;
      }

      .competitor-proposal__content {
        position: relative;
        width: 68%;
      }

      .competitor-proposal__decor {
        width: 45%;
        left: -200px;
      }

      .competitor-proposal__mobile-bg {
        display: block;
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 24px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        overflow: hidden;

        &:before {
          @include pseudo();
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(0deg, rgba(29, 30, 47, 0.9), rgba(29, 30, 47, 0.9));
        }
      }
    }
  }

  @include respond-below(xl) {
    &.competitor-proposal--home {
      .competitor-proposal__content {
        width: 70%;
      }

      .competitor-proposal__decor {
        width: 40%;
        left: -150px;
        bottom: 40px;
      }
    }
  }

  @include respond-below(lg) {
    color: #fff;
    background: none;
    border-radius: 0;
    padding-top: 60px;

    a {
      color: #fff;
    }

    &__decor {
      display: none;
    }

    &__content {
      width: 100%;
      padding: 63px;
      position: relative;
      z-index: 1;
    }

    &__mobile-img {
      display: block;
      position: absolute;
      width: 308px;
      left: 0;
      z-index: 1;
      pointer-events: none;
      top: -58px;
    }

    &__mobile-bg {
      display: block;
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 24px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      overflow: hidden;

      &:before {
        @include pseudo();
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(29, 30, 47, 0.9), rgba(29, 30, 47, 0.9));
      }
    }

    &.competitor-proposal--home {
      background: $primary-second;

      .competitor-proposal__content {
        width: 100%;
      }
    }
  }

  @include respond-below(md) {
    margin-left: -17px;
    margin-right: -17px;

    &__mobile-img {
      left: 50%;
      transform: translateX(-50%);
    }

    &__content {
      padding: 19px;
      text-align: center;
    }

    .section__title {
      display: block;
      text-align: center;
    }

    .section__title-line {
      display: block;
      text-align: center;
    }

    .social-links {
      width: 100%;
      margin: 5px 0 0;
      justify-content: center;
    }

    .form-inline {
      display: block;
    }

    .form-control__field {
      width: 100%;
      margin: 0 0 8px;
    }

    .btn {
      width: 100%;
      padding: 0;
    }

    &.competitor-proposal--home {
      .competitor-proposal__content {
        padding-top: 50px;
      }
    }
  }
}

.jumbotron {
  padding: 64px 80px;
  box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  position: relative;
  margin-top: -80px;
  margin-bottom: 16px;
  background: #1E3351;
  background-size: cover !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;

  &,
  a {
    color: #fff;
  }

  .jumbotron-img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;

    &:after {
      @include pseudo();
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(to right, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0));
    }

    img {
      display: block;
      //   width: 40%;
      height: 100%;
      object-fit: cover;
    }
  }

  .gos-sign {
    position: absolute;
    z-index: 9;
    top: -110px;
    right: -90px;
    background: url('../svg/gos-sign.svg') no-repeat 0 0;
    background-size: contain;
    width: 372px;
    height: 365px;
  }

  &.jumbotron--credit {
    overflow: hidden;
  }

  &.jumbotron--installment {
    .jumbotron-img__bold {
      position: absolute;
      z-index: -1;
      top: 50px;
      left: -100px;
      color: #fff;
      font-size: 180px;
      line-height: 1;
      font-weight: 700;

      @include respond-below(md) {
        top: 10px;
        left: 50%;
        transform: translate(-50%, 0);
      }

      @include respond-below(sm) {
        top: 10px;
        font-size: 80px;
      }
    }
  }

  &.jumbotron--insurance {
    background: #F9DF4E;

    .h1:before {
      display: none;
    }

    .jumbotron-img {
      justify-content: flex-end;
      border-radius: 24px;
      overflow: hidden;

      img {
        height: 100%;
        max-height: 100%;
      }
    }
  }

  &.jumbotron--medicine {
    overflow: hidden;

    .text-primary {
      color: #fff;
    }
  }

  &.jumbotron--family {
    overflow: hidden;

    .jumbotron-content__form-title-line {
      background: rgba(#fff, .24);
    }

    .text-xl {
      max-width: 610px;
      max-width: 455px;

    }
  }

  &.jumbotron--auto {
    background: $primary-second;
    margin-top: 0;

    .jumbotron-content__form-title-line {
      background: rgba(#fff, .24);
    }

    .text-xl {
      max-width: 610px;
    }

    .jumbotron-img {
      width: 60%;
      left: auto;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      &:after {
        display: none;
      }

      img {
        max-width: 550px;
        max-height: 420px;
        width: auto;
        height: auto;
        object-fit: contain;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: optimizeQuality;
      }

      @include respond-below(xl) {
        img {
          max-height: 400px;
        }
      }

      @include respond-below(lg) {
        img {
          max-height: 300px;
          max-width: 400px;
        }
      }

      @include respond-below(md) {
        width: 100%;

        img {
          max-height: 250px;
        }
      }

      @include respond-below(sx) {
        img {
          max-height: 140px;
        }
      }
    }

    .jumbotron-model-title {
      position: absolute;
      z-index: -1;
      top: 10px;
      font-size: 104px;
      line-height: 1;
      text-transform: uppercase;
      font-weight: 900;
      color: rgba(255, 255, 255, 0.13);
      word-break: break-word;
      text-align: center;

      @include respond-below(md) {
        font-size: 72px;
      }

      @include respond-below(sx) {
        font-size: 40px;
      }
    }
  }

  &.jumbotron--first {
    overflow: hidden;

    .jumbotron-content__form-title-line {
      background: rgba(#fff, .24);
    }

    .text-xl {
      max-width: 610px;
    }

    .jumbotron-content__percent-txt {
      text-transform: none;
      font-weight: 400;
    }
  }

  &.jumbotron--trade {
    overflow: hidden;

    .jumbotron-content__form-title-line {
      background: rgba(#fff, .24);
    }

    .jumbotron-content__percent-txt {
      color: #FFFFFF;
      text-transform: none;
      font-weight: 400;
    }
  }

  &.jumbotron--recycling {
    overflow: hidden;

    .jumbotron-content__form-title-line {
      background: rgba(#fff, .24);
    }

    .text-xl {
      max-width: 610px;
    }
  }

  &--credit {
    &__number {
      position: absolute;
      width: 22%;
      top: 20px;
      right: 38%;
      z-index: 1;

      img {
        width: 100%;
        display: block;
        margin-bottom: 18px;
      }

      &-desktop {
        font-weight: 600;
        font-size: 45.485px;
        line-height: 34px;
        display: block;
        color: #fff;
      }
    }
  }

  &--insurance {
    &__list {
      @include listReset;
      column-count: 2;
      white-space: nowrap;

      li {
        margin-bottom: 8px;
        position: relative;
        padding-left: 40px;

        &:before {
          @include size(24px);
          border-radius: 3px;
          background: url(../img/icon-check-dark-blue.svg) no-repeat center $primary;
          background-size: 50%;
          @include pseudo();
          left: 0;
          top: 0;
        }
      }
    }

    @include respond-above(md) {
      .text-xl {
        font-weight: 600;
      }
    }
  }

  &--installment,
  &--medicine,
  &--trade,
  &--first,
  &--family {
    &__number {
      position: absolute;
      z-index: 1;

      &-value {
        display: block;
        font-size: 170px;
        font-weight: 800;
        line-height: .8;
      }

      &-desktop {
        font-size: 32px;
        line-height: .8;
      }
    }
  }

  &--installment {
    &__number {
      top: 30px;
      right: 37%;
    }
  }

  &--medicine {
    &__number {
      top: 60%;
      right: 10%;
    }
  }

  &--trade {
    &__number {
      top: 20px;
      right: 26%;
    }
  }

  &--family {
    &__number {
      top: 64px;
      right: 26%;

      top: auto;
      bottom: 22px;
      right: 333px;
    }
  }

  &--first {
    &__number {
      top: 60%;
      right: 10%;

      top: 49px;
      right: 8px;
    }
  }

  &-content {
    max-width: 100%;
    width: 600px;
    position: relative;
    z-index: 2;

    width: 700px;

    &__list {
      display: flex;
      margin: -10px -10px 55px;
      list-style: none;
      padding: 0;
      max-width: 440px;
      font-weight: 600;

      &-item {
        @include flexbox(flex-start, center);
        padding: 10px;
      }

      &-icon {
        background: #fff;
        box-shadow: 0px 11.5789px 12.3509px rgba(0, 0, 0, 0.03);
        @include size(68px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 13px;
        border-radius: 50%;

        img {
          display: block;
          max-height: 100%;
        }
      }

      &-txt {
        width: calc(100% - 68px);
        padding-left: 18px;
      }
    }

    &__form {
      &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;

        &-line {
          flex-grow: 1;
          height: 1px;
          background: rgba($base-color, .24);

          &.__dark {
            background: #d0d0d0 !important;
          }
        }

        &-txt {
          padding: 0 10px;
          white-space: nowrap;

          &.__dark {
            color: $base-color;
          }

          &.__blue {
            b {
              background: $primary-second;
              color: #fff;
              font-weight: 600;
            }
          }

          b {
            background: $primary;
            font-weight: 400;
            color: $primary-second;
            padding: 3px 3px 2px;
            border-radius: 3px;
          }
        }
      }

      .form-inline {

        .form-control__field,
        .btn {
          // width: calc((100% / 3) - 7px);
        }

        .form-control__field {
          margin-right: 10px;
          margin-right: 0;
        }

        .btn {
          white-space: normal;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 30px;

          padding: 0;
          height: 45px;

          width: 213px;
          font-size: 16px;
          line-height: 19px;

          border-radius: 16px;


          .icon {
            display: none;
          }
        }
      }

      .text-sm {
        display: block;
        opacity: .7;
        margin-top: 11px;
        width: 100%;
      }

      &.__white {
        background: #fff;
        padding: 10px 20px 10px;
        border-radius: 25px;

        &,
        a {
          color: $base-color;
        }

        @include respond-below(sx) {
          padding: 25px 16px 10px;
        }
      }
    }

    &__percent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #fff;
      margin: 24px 0;

      margin: 0 0 44px;

      &.__yellow {
        color: $primary;
      }

      &-item {
        font-weight: 800;
        font-size: 56px;
        line-height: 1;

        b {
          font-size: 80px;
          font-weight: 800;
        }
      }

      &-txt {
        line-height: 1.19;
        font-size: 32px;
        font-weight: 800;
        padding-left: 26px;
      }
    }

    &__tag {
      display: inline-block;
      vertical-align: top;
      padding: 0 19px;
      margin-bottom: 11px;
      font-weight: 600;
      font-size: 18px;
      height: 32px;
      line-height: 34px;
      background: rgba(#fff, .12);
      border-radius: 16px;


      background: rgba(255, 255, 255, 0.12);
      border: 1px solid #FFFFFF;
      border-radius: 16px;
    }

    @include respond-below(lg) {
      width: 500px;
    }
  }

  &-img {
    width: calc(100% - 680px);
    bottom: 0;
    right: 0;
    top: 0;
    max-width: 100%;
    position: absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1;
    pointer-events: none;

    img {
      display: block;
    }
  }

  .h1 {
    font-weight: 800;
    margin-bottom: 8px;
    position: relative;


  }

  .text-xl {
    max-width: 440px;
    margin-bottom: 20px;
    margin-top: 0;
    display: block;
  }

  .text-md {
    display: block;
    margin-top: 4px;
    margin-bottom: 32px;
  }

  .text-lg {
    display: block;
    margin-top: 16px;
  }

  .h4 {
    font-weight: 400;
  }

  &.jumbotron--slider {
    padding: 0;
    background: #ffffff;

    .swiper-pagination {
      right: 55px;
      bottom: 50px;
      left: auto;
      width: auto;
      color: rgba(#fff, .4);
      font-size: 18px;
      font-weight: 700;

      span {
        color: #fff;
        margin: 0 5px;

        &:before {
          // content: '0';
        }
      }

      @include respond-below(md) {
        bottom: 15px;
        right: 20px;
        left: 20px;
        font-size: 16px;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      @include size(96px);
      margin-top: -48px;
      background: $primary;
      color: $primary-second;
      font-size: 26px;

      &:hover {
        background: #fff;
        color: $primary-second;
      }

      @include respond-below(md) {
        display: none;
      }
    }

    .swiper-button-prev {
      left: 54px;
    }

    .swiper-button-next {
      right: 54px;
    }
  }

  &__slider {
    border-radius: inherit;
    overflow: hidden;
    background: #002C5F;


    @include respond-below(sm) {
      border-radius: 0;
    }

    .swiper-slide {
      height: auto;
      background: #002C5F;
      position: relative;
    }

    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &-mobile,
      &-desk {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
      }

      &-desk {
        display: block;
      }

      &-mobile {
        display: none;
      }
    }

    &-content {
      position: relative;
      padding: 83px 170px 83px 250px;
      display: flex;
      height: 588px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      color: #fff;
      z-index: 1;

      img {
        display: none;
      }

      .h3 {
        font-weight: 600;
        font-size: 30px;
      }

      .h1 {
        &:before {
          display: none;
        }

        margin-bottom: 16px;
        font-weight: 800;
      }

      .h5 {
        font-weight: normal;
        padding-left: 32px;
        border-left: 2px solid rgba(#fff, .32);
      }

      .link-more {
        margin-top: 44px;
      }

      &:before {
        @include pseudo();
        top: 83px;
        left: 200px;
        bottom: 99px;
        right: 0;
        background: url(../img/home-slider/slide-decor.svg) no-repeat left center;
        background-size: auto 100%;
        z-index: -1;
      }
    }
  }

  &.jumbotron--model {
    padding: 0;
    position: relative;
    border-radius: 0;
    box-shadow: none;
    background: $base-bg;
    margin-top: 0;
    margin-bottom: 0;

    &:before {
      display: none;
    }

    .jumbotron-content__form-title-line {
      background: rgba(#fff, .24);
    }

    .h1,
    .text-xl {
      max-width: 500px;
      max-width: 652px;
      max-width: 679px;

      &:before {
        display: none;
      }
    }

    .jumbotron-img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      &__logo {
        position: absolute;
        top: auto;
        bottom: 40px;
        right: 40px;
        width: 150px;
        background: none;
        height: 75px;
        // background: url(../img/jumbotron/logo-bg.svg) no-repeat top center;
        //-webkit-background-size: contain;
        // background-size: contain;
        // padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        // transform: translateX(-50%);

        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: contain;

        }
      }


    }

    .jumbotron-content {
      width: 700px;
      width: 900px;
      max-width: 100%;
      padding: 192px 0 43px;
      padding: 80px 0 24px;
      color: #fff;

      @media (max-width: 1120px) {
        width: 700px;
      }
    }
  }

  &--model {
    &__price {
      padding: 42px 20px 35px;
      background: url(../svg/desc-bg.svg) no-repeat left center;
      background-size: auto 100%;
      margin: 25px 0;

      &-title {
        display: block;
        font-weight: 600;
        font-size: 20px;
      }

      &-item {
        font-size: 46px;
        font-weight: 600;
        display: block;
        line-height: 1.14;
      }
    }

    .list-styled {
      font-size: 22px;
      margin-bottom: 10px;
      text-align: left;
      margin-top: 40px;
      margin-bottom: 51px;

      li {
        color: inherit;
        font-weight: 800;
        font-size: 28px;
        line-height: 36px;
      }

      @include respond-below(sm) {
        font-size: 16px;
      }
    }
  }

  @include respond-below(xl) {
    &.jumbotron--model {
      .jumbotron-img {
        &:before {
          right: 38%;
        }
      }
    }

    &--family {
      &__number {}
    }

    &--trade {
      &__number {
        top: 60px;
      }
    }
  }

  @include respond-below(lg) {
    padding: 50px;

    &-img {
      width: 50%;
    }

    &--credit {
      &__number {
        right: 20%;
        width: 20%;

        &-desktop {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }

    .h1 {
      &:before {
        left: -50px;
      }
    }

    &.jumbotron--model {
      .jumbotron-img {
        &:before {
          right: 20%;
        }
      }
    }

    &--medicine,
    &--family,
    &--first {
      &__number {
        text-align: center;

        &-value {
          font-size: 130px;
        }

        &-desktop {
          font-size: 24px;
        }
      }
    }

    &--trade {
      &__number {
        &-value {
          font-size: 70px;
        }

        &-desktop {
          font-size: 14px;
        }
      }
    }

    &--medicine {
      &__number {
        right: 5%;
      }
    }

    &--installment {
      &__number {
        &-value {
          font-size: 100px;
        }

        &-desktop {
          font-size: 22px;
        }
      }
    }
  }

  @include respond-below(md) {
    margin-top: -40px;
    padding: 240px 0 0;
    margin-left: -17px;
    margin-right: -17px;

    &-img {
      width: 100%;
      bottom: auto;
      height: 240px;
      overflow: hidden;
      border-top-right-radius: 24px;
      border-top-left-radius: 24px;
    }

    &-content {
      max-width: 100%;
      width: 100%;
      padding: 8px 16px 16px;
      text-align: center;

      .h1 {
        font-size: 20px;
        margin-bottom: 0;

        br {
          display: none;
        }

        &:before {
          display: none;
        }

        span {
          display: block;
          font-weight: 600;
          font-size: 12px;
        }
      }

      .text-xl,
      .text-lg,
      .h4 {
        font-size: 12px;
        line-height: 14px;
        opacity: .7;
        margin-bottom: 8px;
        margin-top: 0;
      }

      &__list {
        margin-bottom: 0;
        justify-content: center;
        max-width: 100%;
        font-size: 10px;
        text-align: left;

        &-item {
          @include flexbox(flex-start, center);
          padding: 10px;
        }

        &-icon {
          @include size(48px);
          padding: 10px;
        }

        &-txt {
          width: calc(100% - 48px);
          padding-left: 8px;
        }
      }

      &__form {
        &-title {
          font-size: 12px;
          margin-bottom: 12px;
          line-height: 24px;

          &-txt {
            b {
              font-weight: 600;
              font-size: 16px;
              padding: 0 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 24px;
              line-height: 24px;
            }
          }
        }

        .text-sm {
          margin-top: 8px;
        }
      }

      &__percent {
        align-items: flex-start;
        flex-direction: column;
        margin: 0 0 -50px;
        text-align: left;

        &-item {
          font-size: 14px;
          line-height: 1;

          b {
            font-size: 32px;
          }
        }

        &-txt {
          line-height: 1;
          font-size: 14px;
          padding-left: 0;

          br {
            display: none;
          }
        }
      }

      &__tag {
        display: block;
        padding: 0;
        margin-bottom: 0;
        height: auto;
        line-height: 1;
        background: none;
        font-weight: 600;
        font-size: 12px;
        border-radius: 0;
      }
    }

    &.jumbotron--credit {
      .jumbotron-img {
        background: rgba(0, 0, 0, 0.03);
      }
    }

    &.jumbotron--medicine {
      .jumbotron-img {
        background: rgba(0, 0, 0, 0.03);
      }

      .text-xl {
        max-width: 100%;
      }

      .jumbotron-content__percent-txt {
        font-size: 10px;
      }

      .jumbotron-content__form-title {
        justify-content: flex-end;

        &-line {
          display: none;
        }

        &-txt {
          padding: 0;
          display: flex;
          flex-direction: column;
          text-align: center;
          width: auto;
        }
      }
    }

    &.jumbotron--family {
      .jumbotron-content__form-title {
        justify-content: flex-end;

        &-line {
          display: none;
        }

        &-txt {
          padding: 0;
          display: flex;
          flex-direction: column;
          text-align: center;
          width: auto;
        }
      }

      .jumbotron-img {
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(0, 0, 0, 0.14) 100%);
      }

      .text-xl {
        max-width: 100%;
      }
    }

    &.jumbotron--first {
      .jumbotron-content__form-title {
        justify-content: flex-end;

        &-line {
          display: none;
        }

        &-txt {
          padding: 0;
          display: flex;
          flex-direction: column;
          text-align: center;
          width: auto;
        }
      }

      .jumbotron-img {
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(0, 0, 0, 0.14) 100%);
      }

      .text-xl {
        max-width: 100%;
      }
    }

    &.jumbotron--trade {
      .jumbotron-img {
        background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(0, 0, 0, 0.14) 100%);
      }

      .text-xl {
        max-width: 100%;
      }

      .jumbotron-content__percent-txt {
        font-size: 10px;
      }

      .jumbotron-content__form-title {
        justify-content: flex-end;

        &-line {
          display: none;
        }

        &-txt {
          padding: 0;
          display: flex;
          flex-direction: column;
          text-align: center;
          width: auto;
        }
      }
    }

    &.jumbotron--recycling {
      .jumbotron-img {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        img {
          max-height: 100%;
        }

        &:after {
          display: none;
        }
      }

      .text-xl {
        max-width: 100%;
      }

      .jumbotron-content__percent-txt {
        font-size: 10px;
      }

      .jumbotron-content__form-title {
        justify-content: flex-end;

        &-line {
          display: none;
        }

        &-txt {
          padding: 0;
          display: flex;
          flex-direction: column;
          text-align: center;
          width: auto;
        }
      }
    }

    &.jumbotron--insurance {
      .text-xl {
        max-width: 100%;
      }

      .jumbotron-img {
        justify-content: center;
      }
    }

    &.jumbotron--model {
      padding-top: 240px;
      border-radius: 24px;
      margin: 0 8px;
      width: calc(100% - 16px);
      overflow: hidden;

      @media (max-width: 1024px) {
        width: 100%;
        margin: 0;
      }

      .container {
        padding: 0;

        @media (max-width: 1024px) {
          margin: 0 8px;
          width: calc(100% - 16px);
        }
      }

      &:before {
        display: none;
      }

      .h1,
      .text-xl {
        max-width: 100%;
      }

      .jumbotron-img {
        height: 240px;

        &__logo {
          width: 80px;
          height: 46px;
          padding: 3px;
        }

        &:before {
          display: none;
        }
      }

      .jumbotron-content {
        width: 100%;

      }
    }

    &__slider {
      &-bg {
        &-desk {
          display: none;
        }

        &-mobile {
          display: block;
        }
      }

      &-content {
        padding: 42px 15px;
        height: 370px;
        align-items: center;
        justify-content: flex-start;
        text-align: center;

        .h3 {
          font-size: 20px;
        }

        .h1 {
          font-size: 30px;
          margin-bottom: 8px;
        }

        .h5 {
          padding-left: 0;
          border-left: 0;
          font-size: 14px;
        }

        .link-more {
          margin-top: 11px;
        }

        img {
          display: block;
          margin: 9px auto 0;
          max-width: 280px;
        }

        &:before {
          top: 20px;
          left: 24px;
          bottom: 90px;
          right: 24px;
          background: url(../img/home-slider/slide-decor.svg) no-repeat center;
          background-size: 100% 100%;
        }
      }
    }

    &--model {
      &__price {
        padding: 29px 21px 23px;
        margin: 0;
        position: absolute;
        bottom: 100%;
        left: 16px;
        background-size: 100%;

        &-title {
          font-size: 14px;
        }

        &-item {
          font-size: 24px;
        }
      }
    }

    &--credit {
      &__number {
        right: 0;
        width: 100%;
        top: 30px;

        &-desktop {
          text-align: center;
        }

        img {
          height: 80px !important;
          object-fit: contain !important;
        }
      }
    }

    &--installment,
    &--medicine,
    &--family,
    &--first {
      &__number {
        width: 100%;
        text-align: center;
        top: 10px;
        right: auto;

        &-value {
          font-size: 100px;
        }

        &-desktop {
          font-size: 20px;
        }
      }
    }

    &--trade {
      &__number {
        width: 100%;
        text-align: left;
        top: 10px;
        left: 10px;
        right: auto;
      }

      &-value {
        font-size: 70px;
      }

      &-desktop {
        font-size: 14px;
      }
    }
  }

  @include respond-below(sm) {
    padding-top: 185px;

    &-img {
      height: 185px;
    }

    &-content {
      &__form {
        &-title {
          &-line {
            display: none;
          }

          &-txt {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

        }

        .text-sm {
          font-size: 10px;
        }

        .form-inline {
          display: block;

          .form-control__field,
          .btn {
            width: 100%;
          }

          .form-control__field {
            margin-right: 0;
            margin-bottom: 17px;
          }

          .form-control {
            height: 48px;

            &.form-control--dark {
              background: #FFFFFF;
              color: $base-color;

              @include input-placeholder {
                color: rgba($base-color, .5);
              }

              &:focus {
                background: rgba(#fff, .85);
              }

              &:hover,
              &:focus,
              &:active {
                background: rgba(#fff, .85);
              }

              &:hover {
                @include input-placeholder {
                  color: $base-color;
                }
              }
            }
          }

          .btn {
            padding: 0 15px;
            height: 48px;
            font-size: 16px;
            line-height: 1;

            &:-moz-placeholder {
              line-height: 48px;
            }

            &::-moz-placeholder {
              line-height: 48px;
            }

            .icon {
              display: block;
              margin-top: -5px;
            }
          }
        }
      }
    }

    &.jumbotron--model {
      padding-top: 140px;

      .jumbotron-img {
        height: 140px;

        &:before {
          content: '';
          position: absolute;
          z-index: 3;
          display: block;
          width: 180px;
          height: 100%;
          background: url('../svg/blue-rect.svg') no-repeat 0 0;
          background-size: contain;
        }

        &__logo {
          right: 10px;
          bottom: 10px;
        }
      }
    }

    &.jumbotron--slider {
      box-shadow: none;
      margin-bottom: 0;

      .swiper {
        &-pagination {
          bottom: 0;
          left: auto;
          background-color: rgb(255, 204, 71);
          border-radius: 10px;
          padding: 2px 5px;
          left: 50%;
          transform: translate3d(-50%, 0px, 0px);
          right: auto;
        }

        &-button {

          &-prev,
          &-next {
            display: block;
            position: absolute;
            top: auto;
            bottom: 0;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 1;

            &:after {
              font-size: 15px;
            }

            &:hover {
              background: $primary;
              color: $primary-second;
            }
          }

          &-prev {
            right: 70px;
            left: 70px;
          }

          &-next {
            // right: 20px;
            right: 70px;
          }
        }
      }

      .jumbotron__slider {
        height: 418px;
        padding-bottom: 50px;

        &,
        .swiper-slide {
          background: transparent;
        }

        &-bg-mobile {
          // border-radius: 24px;
          border-radius: 0;
          background-position: 0 50%;
        }

        &-content {
          width: 160px;
          height: 160px;
          position: absolute;
          bottom: -50px;
          left: 0;
          padding: 40px 20px 20px;
          text-align: left;
          color: $base-color;
          justify-content: center;
          align-items: flex-start;

          &:before {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-size: contain;
          }

          .h1 {
            order: 1;
            font-size: 18px;
            line-height: 1;
          }

          .h2,
          .h3,
          .h4,
          .h5 {
            font-size: 12px;
            line-height: 1;
          }

          .h2 {
            order: 2
          }

          .h3 {
            order: 3;
          }

          .h4 {
            order: 4;
          }

          .h5 {
            order: 5;
          }
        }
      }
    }

    &--model {
      &__price {
        left: 10px;
        padding: 15px 10px;
        margin-bottom: 35px;

        &-title {
          font-size: 13px;
        }

        &-item {
          font-size: 20px;
        }
      }
    }

    .gos-sign {
      z-index: 2;
      width: 200px;
      height: 200px;
      top: -60px;
      right: -60px;
    }

    &--credit {
      &__number {
        top: 20px;
        left: 10px;

        &-desktop {
          font-size: 18px;
          line-height: 18px;
          width: max-content;
        }

        img {
          height: 50px !important;
        }
      }
    }
  }
}

.info-slider {
  overflow: visible;

  .swiper-slide {
    width: calc(100% / 3);

    &.info-slider__wide {
      width: calc((100% / 3) * 2);
    }

    &.info-slider__half {
      width: 50%;
    }

    &.info-slider__xs {
      width: 25%;
    }
  }

  &__item {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    height: 100%;

    &.info-slider__item-light {
      background: #f3f3f3;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);

      .info-slider__txt {
        padding: 25px 32px;
        min-height: 176px;
        justify-content: flex-start;
      }

      .info-slider__img {
        width: 100%;
      }
    }

    &.info-slider__item-xs {
      .text-xl {
        max-width: 382px;
      }

      .text-lg {
        max-width: 230px;
        font-weight: 400;
        opacity: 0.9;
      }

      .info-slider__txt {
        padding: 32px 40px;
        min-height: 160px;
        justify-content: flex-start;
      }

      &.info-slider__item-xs-light {
        background: #F0F0F0;

        .info-slider__txt {
          padding: 28px 32px;
          min-height: 164px;
        }
      }
    }
  }

  &__txt {
    position: relative;
    z-index: 1;
    padding: 33px 56px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .h5 {
      font-weight: 400;
      margin: 0;
    }

    .h3 {
      font-weight: 700;
      margin-top: 8px;
    }

    .btn {
      margin-top: 20px;
    }

    .text-lg {
      display: block;
      max-width: 170px;
      opacity: .8;
      font-weight: 600;
      line-height: 1.33;
    }

    .text-xl {
      display: block;
      opacity: .9;
    }
  }

  &__img {
    position: absolute;
    width: 50%;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &.info-slider__img-fluid {
      width: 100%;
      justify-content: flex-end;

      img {
        height: 100%;
      }
    }

    &.info-slider__img-end {
      justify-content: flex-end;
    }

    img {
      max-height: 100%;
    }
  }

  @include respond-above(md) {
    .swiper-wrapper {
      transform: none !important;
      flex-wrap: wrap;
      margin: -8px;
      width: calc(100% + 16px);
    }

    .swiper-slide {
      padding: 8px;
    }
  }

  @include respond-below(lg) {
    &__txt {
      padding: 24px;

      .h5 {
        z-index: 1;
      }

      .h3 {
        z-index: 1;
      }

      .text-lg {
        z-index: 1;
      }

      .text-xl {
        z-index: 1;
      }
    }
  }

  @include respond-below(md) {
    .swiper-slide {
      width: 50%;

      &.info-slider__wide {
        width: 100%;
      }

      &.info-slider__half {
        width: 50%;
      }

      &.info-slider__xs {
        width: 50%;
      }
    }
  }

  @include respond-below(sm) {
    .swiper-slide {
      width: 100%;

      &.info-slider__wide {
        width: 100%;
      }

      &.info-slider__half {
        width: 100%;
      }

      &.info-slider__xs {
        width: 100%;
      }
    }

    &__item {
      &.info-slider__item-light {
        .info-slider__txt {
          padding: 24px;
          min-height: 215px;
          justify-content: flex-start;
        }

        .info-slider__img {
          top: 0;
          height: auto;
        }
      }

      &.info-slider__item-xs {
        .info-slider__txt {
          padding: 24px;
          min-height: 215px;
          justify-content: flex-start;
        }

        .info-slider__img {
          top: 0;
          height: auto;
        }

        &.info-slider__item-xs-light {
          .info-slider__txt {
            padding: 24px;
            min-height: 215px;
            justify-content: flex-start;
          }

          .info-slider__img {
            top: 0;
            height: auto;
          }
        }
      }
    }

    &__txt {
      padding-bottom: 160px;
      min-height: 275px;
      justify-content: flex-start;
    }

    &__img {
      width: 100%;
      height: 160px;
      top: auto;

      &.info-slider__img-fluid img {
        height: 100%;
        width: auto;
        object-fit: cover;
        object-position: left;
      }
    }
  }
}

.car-block {
  @include flexbox(space-between, flex-start);

  &__sidebar {
    padding: 0 96px;
    width: 42.5%;
  }

  &__content {
    padding-top: 15px;
    padding-left: 33px;
    width: 57.5%;
  }

  &__img {
    display: block;
    position: relative;
    max-width: 59%;
    margin-bottom: 38px;

    &.car-block__img-md {
      margin-bottom: 10px;
      max-width: 45%;
    }

    img {
      display: block;
      width: 100%;
      max-height: 330px;
    }

    &-ttl {
      display: block;
      font-size: 24px;
      font-weight: 600;
    }

    &-eq {
      display: block;
      font-size: 16px;
      margin-bottom: 20px;
    }

    &-sale {
      position: absolute;
      z-index: 3;
      top: 0;
      right: -150px;
      width: 140px;
      height: 140px;
      font-size: 18px;
      background: url('../img/home-slider/slide-decor.svg') no-repeat 0 0;
      background-size: contain;
      padding: 45px 0 0 18px;
      line-height: 1.1;

      b {
        font-size: 24px;
        font-weight: 600;
      }
    }

    &-prices {
      display: none;

      &.__active {
        @include flex();
        margin-top: 20px;
      }

      &__col {
        width: 50%;
        margin-bottom: 20px;
      }

      &__ttl {
        display: block;
        font-size: 16px;
      }

      &__val {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.1;

        &.__old {
          opacity: .5;
        }
      }
    }
  }

  &__info {
    border: 3px dashed #E9E9E9;
    border-radius: 24px;
    position: relative;
    padding: 40px 48px;

    &:before {
      @include pseudo();
      background: url(../img/info-icon.svg) no-repeat center;
      -webkit-background-size: contain;
      background-size: contain;
      @include size(138px);
      bottom: 49px;
      right: 0;
      pointer-events: none;
      opacity: .06;
    }

    *+& {
      margin-top: 32px;
    }

    .text-xl {
      display: block;
      font-weight: 700;
      margin-bottom: 8px;
    }

    & *+.text-xl {
      margin-top: 24px;
    }
  }

  &__form {
    &-item {
      position: relative;
      padding-bottom: 32px;

      &:before {
        @include pseudo();
        top: 0;
        left: -65px;
        bottom: 0;
        width: 3px;
        background: #ECECEC;
        pointer-events: none;
      }

      &.car-block__form-item-result {
        padding-bottom: 0;

        &:before {
          display: none;
        }
      }

      &.completed {
        &:before {
          background: $primary;
        }
      }

      &.active {
        .car-block__form-step {
          border-color: $primary;
          background: $primary;
          color: #fff;
        }
      }

      &.car-block__form-item-disabled {
        .car-block__form-step {
          background: #C4C4C4;
          border-color: #C4C4C4;

          &:before {
            @include pseudo();
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(../img/icon-plus.svg) no-repeat center;
            -webkit-background-size: 40%;
            background-size: 40%;
          }
        }

        .car-block__form-title {
          color: rgba($base-color, .4);
        }

        .car-block__form-title {
          margin-bottom: 0;
        }

        &.active {
          .car-block__form-step {
            background: $primary;
            border-color: $primary;
          }

          .car-block__form-title {
            color: $base-color;
          }
        }

      }

      &.car-block__form-item-result {
        .car-block__form-title {
          min-height: unset;
          margin-bottom: 0;
        }
      }
    }

    &-title {
      position: relative;
      display: flex;
      min-height: 64px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 15px;

      .h4 {
        margin: 0 16px 0 0;
      }

      @include respond-below(sm) {
        .__trade-in-ttl {
          width: calc(100% - 40px - 75px - 12px);
        }
      }
    }

    &-step {
      position: absolute;
      @include size(64px);
      background: #fff;
      border: 3px solid #E1E1E1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #C4C4C4;
      top: 0;
      left: -96px;
      z-index: 1;
      pointer-events: none;

      .icon {
        stroke-width: 5px;
        fill: none;
        stroke: currentColor;
      }
    }

    .form-control__field {
      &+.form-control__field {
        margin-top: 12px;
      }

      .nice-select .option.disabled {
        font-weight: 700;
        color: $base-color;
      }
    }

    .text-sm {
      display: block;
      opacity: .5;
      margin-top: 16px;
      line-height: 1.42;
    }

    &-content {
      &.__placeholder {
        opacity: .5;
        filter: grayscale(1);

        @include respond-below(sm) {
          margin-top: 20px;
        }
      }
    }
  }

  @include respond-below(xl) {
    &__sidebar {
      padding-right: 0;
    }
  }

  @include respond-below(lg) {
    &__sidebar {
      width: 100%;
    }

    &__content {
      padding-left: 0;
      width: 100%;
      padding-top: 78px;
    }

    &__img {
      display: none;
    }
  }

  @include respond-below(md) {
    &__content {
      padding-top: 40px;
    }

    &__info {
      padding: 24px;
      margin-left: -17px;
      margin-right: -17px;

      &:before {
        display: none;
      }

      *+& {
        margin-top: 24px;
      }

      & *+.text-xl {
        margin-top: 16px;
      }
    }
  }

  @include respond-below(sm) {
    &__sidebar {
      padding-left: 0;
    }

    &__form {
      &-item {
        &:before {
          display: none;
        }

        &.car-block__form-item-disabled {
          border-top: 1px solid #E9E9E9;
          border-bottom: 1px solid #E9E9E9;
          margin-bottom: 32px;
          padding-top: 24px;
          padding-bottom: 24px;

          .car-block__form-title {
            .h4 {
              max-width: calc(100% - 146px);
            }
          }
        }

        &.car-block__form-item-result {
          .car-block__form-title {
            display: none;
          }

          .btn {
            padding: 0;
          }
        }
      }

      &-title {
        min-height: 40px;
        flex-wrap: nowrap;

        .h4 {
          font-size: 18px;
          margin-right: 0;
          padding-right: 10px;
        }
      }

      &-step {
        position: relative;
        @include size(40px);
        left: 0;
        margin-right: 12px;
      }

      .btn-xs {
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
        font-size: 14px;
        padding: 0 15px;
        font-weight: 600;
      }
    }
  }
}

.insurance-types {
  @include flex;
  margin: -8px;

  &__col {
    width: 50%;
    padding: 8px;
  }

  &__item {
    background: #E9E9E9;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    position: relative;
    overflow: hidden;

    &.insurance-types__item-blue {
      background: #E3F1FC;
    }
  }

  &__txt {
    padding: 40px 48px;
    position: relative;
    z-index: 1;
    min-height: 243px;
    height: 100%;

    .h4 {
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 8px;
    }

    .text-lg {
      display: block;
      margin: 0;
      opacity: 0.64
    }
  }

  &__img {
    position: absolute;
    width: 60%;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    pointer-events: none;

    img {
      display: block;
      max-height: 100%;
    }
  }

  @include respond-below(md) {
    margin: -4px -21px;

    &__col {
      width: 50%;
      padding: 4px;
    }

    &__txt {
      padding: 24px 18px 193px;
      min-height: unset;
      text-align: center;

      .h4 {
        font-weight: 900;
      }
    }

    &__img {
      position: absolute;
      width: 100%;
      top: auto;
      height: 193px;
    }
  }

  @include respond-below(sm) {
    &__col {
      width: 100%;
    }
  }
}

.insurance-block {
  margin: -16px;
  @include flex;

  &__col {
    width: calc(100% / 3);
    padding: 16px;

    &:nth-child(3n + 1) {
      .insurance-block__item {
        background: #F3F3F3;
      }
    }

    &:nth-child(3n + 2) {
      .insurance-block__item {
        background: #FEF7EA;
      }
    }

    &:nth-child(3n + 3) {
      .insurance-block__item {
        background: #E3F1FC;
      }
    }
  }

  &__item {
    height: 100%;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    padding: 48px 48px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .list-styled {
      flex-grow: 1;

      li {
        color: $base-color;
      }
    }

    .btn {
      margin-top: 40px;
    }

    .text-xxl {
      display: block;
      margin-bottom: 16px;
      font-weight: 700;
    }
  }

  @include respond-below(lg) {
    margin: -10px;
    @include flex;

    &__col {
      padding: 10px;
    }
  }

  @include respond-below(md) {
    margin: -4px -21px;
    @include flex;

    &__col {
      width: 100%;
      padding: 4px;
    }

    &__item {
      padding: 23px 14px 24px;

      .btn {
        margin-top: 20px;
        width: 100%;
      }

      .text-xxl {
        margin-bottom: 8px;
      }
    }
  }
}

.insurance-slider {
  &__item {
    img {
      display: block;
      margin: 0 auto;
    }
  }

  .swiper-wrapper {
    align-items: center;
  }

  @include respond-below(md) {
    .swiper-slide {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: auto;

      img {
        height: 44px;
      }
    }
  }
}

.home-filters {
  margin-top: 40px;
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
  border-radius: 24px;

  &__brands {
    width: 61.7%;
    padding: 21px 56px 40px;

    .brands-menu {
      margin-left: -14px;
      margin-right: -14px;

      li {
        width: 20%;

        a {
          padding-top: 0;
          padding-bottom: 0;

          &:hover {
            background: none;
            color: $primary;
          }
        }

        .brands-menu__icon {
          display: none;
        }

        .brands-menu__title {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 0;

          small {
            font-size: inherit;
            margin-left: 3px;
          }
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          a {
            padding-top: 14px;
            padding-bottom: 14px;

            &:hover {
              color: $base-color;
              background: #f3f3f3;
            }
          }

          .brands-menu__icon {
            display: flex;
          }

          .brands-menu__title {
            display: block;
            padding-left: 15px;

            small {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  &__form {
    width: 56%;
    border-left: 1px solid #E9E9E9;
    padding: 33px 54px;
    background: url(../img/form-car.webp) no-repeat right bottom;
    background-size: auto 90%;

    .h5 {
      margin-bottom: 30px;
    }

    .btn {
      font-size: 16px;
      line-height: 1;
      padding: 0 20px;

      .icon {
        vertical-align: middle;
      }
    }

    &-list {
      list-style: none;
      padding: 0;
      margin: 16px -5px -5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      li {
        margin: 0 5px 5px;
      }

      a {
        color: #333333;
        display: inline-block;
        vertical-align: top;
        opacity: 0.65;
        border-bottom: 1px solid;

        &.active,
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @include respond-below(xl) {
    &__brands {
      width: 55%;

      .brands-menu {
        li {
          width: calc(100% / 3);

          &:nth-child(5),
          &:nth-child(4) {
            a {
              padding-top: 0;
              padding-bottom: 0;

              &:hover {
                background: none;
                color: $primary;
              }
            }

            .brands-menu__icon {
              display: none;
            }

            .brands-menu__title {
              flex-grow: 1;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 0;

              small {
                font-size: inherit;
                margin-left: 3px;
              }
            }
          }
        }
      }
    }

    &__form {
      width: 45%;
    }
  }

  @include respond-below(lg) {
    &__brands {
      width: 40%;
      padding: 30px;

      .brands-menu {
        li {
          width: 50%;

          a {
            padding-top: 0;
            padding-bottom: 0;

            &:hover {
              background: none;
              color: $primary;
            }
          }

          .brands-menu__title {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 0;

            small {
              font-size: inherit;
              margin-left: 3px;
            }
          }
        }
      }
    }

    &__form {
      width: 60%;
      padding: 30px;
      background: none;
    }
  }

  @include respond-below(md) {
    margin-left: -17px;
    margin-right: -17px;
    flex-wrap: wrap;

    &__brands {
      width: 100%;
      padding: 30px;

      .brands-menu {
        li {
          width: 25%;
          display: block;

          a {
            padding-top: 14px !important;
            padding-bottom: 14px !important;

            &:hover {
              color: $base-color;
              background: #f3f3f3;
            }
          }

          .brands-menu__icon {
            display: flex !important;
            width: 30px;
            height: 30px;
          }

          .brands-menu__title {
            display: block !important;
            padding-left: 15px !important;

            small {
              font-size: 12px;
            }
          }
        }
      }
    }

    &__form {
      width: 100%;
      padding: 30px;
      background: none;
      border-left: 0;
      border-top: 1px solid #e9e9e9;

      .btn {
        width: 100%;
      }
    }
  }

  @include respond-below(sm) {
    margin-top: 20px;

    &__brands {
      .brands-menu {
        li {
          width: calc(100% / 3);
        }
      }
    }
  }

  @include respond-below(sx) {
    &__brands {
      padding: 15px;

      .brands-menu {
        li {
          width: 50%;
        }
      }
    }
  }
}

.equipment-block {
  &__pull {
    padding: 16px 0;
    position: relative;
    font-weight: 600;
    font-size: 20px;
    display: block;
    cursor: pointer;
    border-bottom: 1px solid rgba($base-color, .16);

    &:before {
      @include pseudo();
      @include size(24px);
      background: url(../img/icons/plus.svg) no-repeat center;
      -webkit-background-size: contain;
      background-size: contain;
      right: 0;
      top: 50%;
      margin-top: -12px;
      transition: $transition;
    }

    &:hover {
      &:before {
        opacity: .5;
      }
    }

    &.active {
      &:before {
        transform: rotate(45deg);
      }
    }
  }

  &__hidden {
    display: none;
    border-bottom: 1px solid rgba($base-color, .16);
    padding: 16px 0;

    .text-xl {
      display: block;
      font-weight: 700;
      margin-bottom: 8px;
    }

    & *+.text-xl {
      margin-top: 24px;
    }
  }

  @include respond-below(sm) {
    &__pull {
      font-size: 18px;
    }

    &__hidden {
      .list-styled {
        font-size: 16px;
      }

      .text-xl {
        font-size: 18px;
      }
    }
  }
}

.used-car {
  @include flexbox(flex-start, flex-start);
  margin-bottom: 16px;

  &__gallery {
    width: 55%;
    background: #FFFFFF;
    box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    position: relative;
    overflow: hidden;

    .swiper-button-next,
    .swiper-button-prev {
      width: 32px;
      height: 32px;
      background: none;
      font-size: 20px;
      margin-top: -16px;
      box-shadow: none;
    }

    .favorites-link {
      @include size(40px);
      padding: 9px;
      position: absolute;
      top: 16px;
      left: 16px;
      right: auto;
      z-index: 2;
      background: #fff;
      box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
      border-radius: 8px;
    }

    &-thumbs {
      padding: 16px;

      .swiper-slide {
        width: 106px;
        position: relative;

        &:before {
          @include pseudo();
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 3px solid $primary;
          opacity: 0;
          border-radius: 8px;
          transition: $transition;
          z-index: 1;
        }

        &-thumb-active {
          &:before {
            opacity: 1;
          }
        }
      }

      .used-car__gallery-img {
        border-radius: 8px;
      }
    }

    &-img {
      display: block;
      position: relative;
      overflow: hidden;

      &:before {
        @include pseudoHeight(65.5%);
      }

      img {
        @include img-cover;
      }
    }
  }

  &__info {
    width: 45%;
    padding-left: 59px;
    padding-top: 24px;
  }

  &__title {
    display: block;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.17;
  }

  &__views {
    position: relative;
    padding-left: 38px;
    display: block;
    font-size: 16px;
    opacity: .5;

    &:before {
      @include pseudo();
      background: url(../img/icons/eye.svg) no-repeat center;
      -webkit-background-size: contain;
      background-size: contain;
      @include size(24px);
      left: 0;
      top: 50%;
      margin-top: -12px;
      opacity: .4;
    }
  }

  &__characteristics {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 14px;
    list-style: none;
    padding: 0;
    margin: 22px -19px 29px;

    li {
      padding: 0 10px;
      text-align: center;
    }

    img {
      display: block;
      margin: 0 auto 6px;
      height: 32px;
    }
  }

  .catalog-item__price {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 17px;

    &-current {
      color: $base-color;
      margin: 0 35px 0 0;
      font-weight: 800;
      font-size: 40px;
      line-height: 1;
    }

    &-credit {
      font-size: 16px;
      opacity: .6;
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: -4px;

    .btn {
      margin: 4px;
      white-space: nowrap;
      box-shadow: none;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    column-count: 2;
    margin: 39px 0 37px;
    max-width: 420px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    &:before,
    &:after {
      content: '';
      height: 1px;
      background: #222222;
      opacity: .16;
      flex-grow: 1;
      display: block;
    }

    &-item {
      margin: 0 25px;
      height: 32px;
      line-height: 32px;
      background: rgba(34, 34, 34, 0.08);
      border-radius: 12px;
      padding: 0 20px;
      font-size: 14px;
      color: rgba($base-color, 0.8);
      font-weight: 700;

      &:hover {
        color: #fff;
        background: $base-color;
      }
    }
  }

  &__desc {
    display: flex;
    flex-wrap: wrap;

    &-txt {
      width: 40%;

      &,
      p,
      ul li,
      ol li {
        font-size: 18px;
      }
    }

    &-offer {
      width: 55%;
      margin-left: auto;
    }
  }

  &__offer {
    background: #fff;
    color: $primary-second;
    box-shadow: 0 0 25px 15px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    position: relative;
    padding: 30px;

    &-cnt {
      display: flex;
      flex-wrap: wrap;
    }

    &-l {
      width: 45%;
      z-index: 2;
    }

    &-r {
      padding-left: 20px;
    }

    &-ttl {
      font-size: 38px;
      line-height: 1.1;
      font-weight: 800;
      margin-bottom: 10px;
    }

    &-txt {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;

      &,
      &__sm {
        line-height: 1.1;
      }

      &__sm {
        font-size: 12px;
        opacity: .6;
        margin-bottom: 15px;
      }
    }

    .form-control {
      &__field {
        margin-top: 10px;
      }
    }

    .rules__lnk {
      color: $primary-second;
    }

    .jumbotron-content__percent {
      &-item {
        font-size: 36px;

        b {
          font-size: 70px;
        }
      }
    }

    &-img {
      position: absolute;
      z-index: 0;
      bottom: 0;
      right: 30px;
      max-width: 380px;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: optimizeQuality;
    }

    .gos-sign {
      display: none;
    }
  }

  @include respond-below(lg) {
    &__gallery {
      width: 50%;
    }

    &__info {
      width: 50%;
      padding-left: 30px;
      padding-top: 16px;
    }

    &__title {
      font-size: 30px;
    }

    .catalog-item__price {
      &-current {
        margin: 0 15px 0 0;
        font-size: 30px;
      }
    }

    &__desc {

      &-offer,
      &-txt {
        width: 100%;
      }

      &-offer {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }

  @include respond-below(md) {
    &__gallery {
      width: calc(100% + 34px);
      margin-left: -17px;
      margin-right: -17px;

      &-thumbs {
        .swiper-slide {
          width: 69px;
        }
      }
    }

    &__info {
      width: 100%;
      padding-left: 0;
    }

    &__title {
      font-size: 24px;
    }

    &__offer {
      &-ttl {
        max-width: 260px;
      }
    }
  }

  @include respond-below(sm) {
    .catalog-item__price {
      flex-direction: column;
      align-items: flex-start;

      &-current {
        font-size: 40px;
        margin: 0;
      }
    }

    &__buttons {
      flex-direction: column;
      align-items: unset;
      justify-content: unset;

      .btn {
        flex-grow: 1;
      }
    }

    &__desc {
      &-txt {

        &,
        p,
        ul li,
        ol li {
          font-size: 14px;
        }
      }
    }

    &__offer {
      padding: 32px 16px 16px 16px;

      &-l {
        width: 100%;
      }

      &-r {
        display: none;
      }

      &-ttl {
        font-size: 24px;
      }

      .gos-sign {
        display: block;
        position: absolute;
        z-index: 1;
        top: 15px;
        right: 15px;
        bottom: auto;
        background: url('../svg/gos-sign-circle.svg') no-repeat 0 0;
        background-size: contain;
        width: 42px;
        height: 42px;
      }
    }
  }
}

.used-form {
  background: #FFFFFF;
  box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .h6 {
    color: $base-color;
    font-weight: 600;
    padding-bottom: 8px;
    opacity: 1;
  }

  &__price {
    width: 20%;
  }

  &__range,
  &__price,
  &__btn {
    padding: 27px 45px;
  }

  &__btn {
    padding-left: 0;

    .btn {
      white-space: nowrap;
    }
  }

  &__range {
    flex-grow: 1;
    position: relative;

    &:after {
      @include pseudo();
      top: 27px;
      right: 0;
      bottom: 27px;
      background: rgba(#000000, .16);
      width: 1px;
    }

    .noUi-value {
      font-size: 11px;
    }

    .noUi-tooltip {
      display: none;
    }
  }

  &__price {
    .catalog-item__price {
      margin: 0;
      display: block;

      &-title {
        white-space: nowrap;
        font-weight: 600;
        font-size: 16px;
        color: $base-color;
        margin: 0;
      }

      &-current {
        font-weight: 600;
        font-size: 32px;
        white-space: nowrap;
        color: $base-color;
        margin: 0;
      }

      &-credit {
        white-space: nowrap;
        color: $base-color;
        margin: 0;
        display: block;
        font-size: 14px;
      }
    }
  }

  @include respond-below(lg) {
    &__range {
      width: 50%;

      &:after {
        @include pseudo();
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
      }
    }

    &__price {
      flex-grow: 1;
    }
  }

  @include respond-below(sm) {
    margin-left: -17px;
    margin-right: -17px;

    &__range {
      width: 100%;

      &:after {
        display: none;
      }
    }

    &__price {
      width: 100%;
      text-align: center;
    }

    &__range,
    &__price,
    &__btn {
      width: 100%;
      padding: 18px 16px;
    }

    .btn {
      width: 100%;
      padding: 0;
    }
  }
}

.cars-block {
  margin: -8px 0;

  &__item {
    border: 1px solid #E9E9E9;
    border-radius: 24px;
    margin: 8px 0;
    display: flex;
    justify-content: space-between;

    &-col {
      padding: 32px;
      position: relative;

      .catalog-item__model {
        text-align: left;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 14px;
      }

      .catalog-item__img {
        position: relative;
        overflow: hidden;
      }

      .catalog-item__img-title {
        font-size: 70px;
      }

      .favorites-link {
        margin-right: 10px;
      }

      .catalog-item__price {
        display: block;
        margin-top: 16px;

        &-current {
          color: $base-color;
          margin: 0;
        }

        &-credit {
          display: block;
          margin: 0;

          b {
            color: $primary;
          }
        }
      }

      .h6 {
        padding: 0;
        display: block;
        font-weight: 600;
        font-size: 18px;
        max-width: 230px;
        margin-bottom: 16px;
      }

      &.cars-block__item-info,
      &.cars-block__item-gift {
        flex-grow: 1;
      }

      &.cars-block__item-btn {
        width: 320px;

        @include respond-below(sm) {
          width: 100%;
        }

        .btn {
          display: block;
          width: 100%;
          padding: 0;
          margin-bottom: 14px;
        }
      }
    }

    &-gift-list {
      @include listReset;

      li {
        background: #F3F3F3;
        border-radius: 8px;
        padding: 6px 41px;
        position: relative;
        border: 2px solid #F3F3F3;
        font-size: 14px;
        line-height: 24px;
        cursor: pointer;
        transition: all .2s;

        &:hover {
          opacity: .8;
        }

        &:before {
          @include pseudo();
          background: url(../img/list-check-light.svg) no-repeat center;
          -webkit-background-size: contain;
          background-size: contain;
          @include size(12px);
          left: 15px;
          top: 50%;
          margin-top: -6px;
        }

        &.active {
          background: none;
          border-color: $primary;
          font-weight: 600;
          cursor: unset;

          &:before {
            background-image: url(../img/list-check-red.svg);
          }

          &:hover {
            opacity: 1;
          }
        }
      }

      li+li {
        margin-top: 8px;
      }
    }

    &-info-list {
      @include listReset;

      li+li {
        margin-top: 4px;
      }

      li {
        padding-left: 120px;
        position: relative;
        font-size: 16px;
        line-height: 24px;
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        span {
          position: absolute;
          left: 0;
          top: 0;
          font-weight: 700;
          color: #222222;
          opacity: 0.24;
        }

        &:before {
          @include pseudo();
          @include size(24px);
          background: #F0F0F0 no-repeat center;
          background-size: 40%;
          border-radius: 4px;
          top: 0;
          left: 85px;
        }

        &.active {
          &:before {
            background-color: $primary;
            background-image: url(../img/icon-check-white.svg);
          }

          span {
            color: $primary;
            opacity: 1;
          }
        }
      }
    }
  }

  @include respond-below(lg) {
    &__item {
      flex-wrap: wrap;

      &-col {
        width: 50%;
      }
    }
  }

  @include respond-below(sm) {
    margin-left: -17px;
    margin-right: -17px;

    &__item {
      flex-wrap: wrap;

      &-col {
        width: 100%;
        padding: 15px;

        &.cars-block__item-btn {
          padding-top: 0;
          display: flex;
          flex-wrap: wrap;

          .btn {
            &.__1 {
              width: 100%;
              margin-bottom: 10px;
            }

            &.__2,
            &.__3 {
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              width: 49%;
              padding: 0 10px;
              line-height: 1;
            }

            &.__3 {
              margin-left: auto;
            }
          }
        }

        &.cars-block__item-gift {
          .form-control.nice-select {
            height: 54px;
            line-height: 54px;
            font-size: 15px;
          }
        }
      }
    }
  }
}

.characteristics-photos {
  @include flexbox(space-between, flex-end);

  &__col {
    img {
      display: block;
      width: 100%;

      &.__1 {
        width: 315px;
      }

      &.__2 {
        width: 601px;
        margin: 0 auto;
      }

      &.__3 {
        height: 204px;
      }

      @include respond-below(xl) {
        &.__1 {
          width: 280px;
        }

        &.__2 {
          width: 540px;
        }
      }

      @include respond-below(lg) {
        &.__1 {
          width: 200px;
        }

        &.__2 {
          width: 440px;
        }

        &.__3 {
          height: 160px;
        }
      }

      @include respond-below(sm) {
        margin: 0 auto;

        &.__1,
        &.__2,
        &.__3 {
          width: 270px;
        }
      }
    }

    &.__last {
      align-self: flex-start;
      position: relative;

      @include respond-below(md) {
        margin-top: 40px;
      }
    }

    @include respond-below(sm) {
      margin: 40px auto 0;
      width: 100%;
    }
  }

  &__1 {
    margin-top: -25px;

    .__v,
    .__h,
    .__value {
      display: block;
      margin: 0 auto;
    }

    .__v,
    .__h {
      background: #cecece;
    }

    .__v {
      width: 2px;
      height: 24px;
    }

    .__h {
      width: 20px;
      height: 2px;
    }

    .__value {
      text-align: center;
    }
  }

  &__2 {
    margin-top: 5px;

    .__line {
      @include flex();
      justify-content: center;
      align-items: center;
    }

    .__v,
    .__h {
      background: #cecece;
    }

    .__v {
      width: 2px;
      height: 24px;
    }

    .__h {
      width: calc(100% - 10px);
      height: 2px;
    }

    .__value {
      text-align: center;
      display: block;
      margin: 0 auto -10px;
    }

    @include respond-below(sm) {
      .__h {
        width: 266px;
      }
    }
  }

  &__3 {
    margin-top: 5px;
    margin-left: -35px;

    .__line {
      @include flex();
      justify-content: center;
      align-items: center;
    }

    .__v,
    .__h {
      background: #cecece;
    }

    .__v {
      width: 2px;
      height: 24px;
    }

    .__h {
      width: 380px;
      height: 2px;
    }

    .__value {
      text-align: center;
      display: block;
      margin: 0 auto -10px;
    }

    @include respond-below(xl) {
      .__h {
        width: 340px;
      }
    }

    @include respond-below(lg) {
      margin-left: -25px;

      .__h {
        width: 280px;
      }
    }

    @include respond-below(sm) {
      margin-left: -15px;

      .__h {
        width: 170px;
      }
    }
  }

  &__4 {
    margin-top: 5px;

    .__line {
      @include flex();
      justify-content: center;
      align-items: center;
    }

    .__v,
    .__h {
      background: #cecece;
    }

    .__v {
      width: 2px;
      height: 24px;
    }

    .__h {
      width: calc(100% - 10px);
      height: 2px;
    }

    .__value {
      text-align: center;
      display: block;
      margin: 0 auto -10px;
    }

    @include respond-below(sm) {
      .__h {
        width: 266px;
      }
    }
  }

  &__5 {
    position: absolute;
    top: 82px;
    left: -150px;
    transform: rotate(-90deg);
    width: 204px;

    .__line {
      @include flex();
      justify-content: center;
      align-items: center;
      width: 204px;
    }

    .__v,
    .__h {
      background: #cecece;
    }

    .__v {
      width: 2px;
      height: 24px;
    }

    .__h {
      width: 200px;
      height: 2px;
    }

    .__value {
      text-align: center;
      display: block;
      margin: 0 auto -10px;
    }

    @include respond-below(xl) {
      left: -130px;
    }

    @include respond-below(lg) {
      top: 63px;
      left: -80px;
      width: 162px;

      .__line {
        width: 162px;
      }

      .__h {
        width: 154px;
      }
    }

    @include respond-below(sm) {
      left: 20px;
    }

    @include respond-below(sx) {
      left: -50px;
    }

    @include respond-below(xs) {
      left: -80px;
    }
  }
}

.characteristics-block__wrapper {
  overflow: auto;
  margin: -30px;

  @include respond-below(md) {
    margin: -25px;
  }
}

.characteristics-block {
  display: block;
  padding: 30px;

  .checkbox-list {
    display: flex;
    overflow-x: auto;

    &__item {
      margin: 0;
      padding-right: 30px;
    }
  }

  &__parameters {
    display: flex;
    margin: 16px -16px;

    &-title {
      display: block;
      font-size: 14px;
      opacity: .4;
      text-align: center;
    }

    &-item {
      width: 25%;
      padding: 16px;
    }

    &-icons {
      padding: 15px 5px;
      margin-bottom: 4px;
      background: #F3F3F3;
      border-radius: 16px;

      .used-car__characteristics {
        justify-content: center;
        margin: 0;
      }

      img {
        opacity: .24;
      }
    }
  }

  &__table {
    border-top: 1px solid rgba($base-color, .32);

    &-pull {
      padding: 15px 0;
      position: relative;
      font-weight: 600;
      font-size: 20px;
      display: block;
      cursor: pointer;
      border-bottom: 1px solid rgba($base-color, .32);

      &:before {
        @include pseudo();
        @include size(24px);
        background: url(../img/icons/plus.svg) no-repeat center;
        -webkit-background-size: contain;
        background-size: contain;
        right: 0;
        top: 50%;
        margin-top: -12px;
        transition: $transition;
      }

      &:hover {
        &:before {
          opacity: .5;
        }
      }

      &.active {
        &:before {
          transform: rotate(45deg);
        }
      }
    }

    &-hidden {
      display: none;
      border-bottom: 1px solid rgba($base-color, .32);
    }

    &-line {
      padding: 12px 0 23px;
      display: flex;
      flex-wrap: wrap;

      &+.characteristics-block__table-line {
        border-top: 1px solid #E9E9E9;
      }
    }

    &-col {
      width: 25%;
    }

    &-title {
      display: block;
      width: 100%;
      font-size: 16px;
      line-height: 32px;
      color: #222222;
      opacity: 0.45;
    }

    @include respond-below(sm) {
      &__pull {
        font-size: 18px;
      }

      &__hidden {
        .list-styled {
          font-size: 16px;
        }

        .text-xl {
          font-size: 18px;
        }
      }
    }
  }

  @include respond-below(lg) {
    width: 150%;

    .checkbox-list {
      margin: -16px;

      &__item {
        width: calc(25% - 32px);
        margin: 16px;
      }
    }
  }

  @include respond-below(md) {
    width: 200%;
    padding: 25px;
  }

  @include respond-below(sm) {
    width: 100%;
    padding: 25px;

    .checkbox-list {
      flex-wrap: wrap;

      &__item {
        width: calc(100% - 32px);
        margin: 0 16px 16px 16px;
      }
    }

    &__parameters {
      flex-wrap: wrap;

      &-item {
        width: 100%;
        padding: 10px 16px;
      }

      &-icons {
        padding: 10px;

        .used-car {
          &__characteristics {
            font-size: 11px;

            li {
              padding: 0 15px;
            }
          }
        }
      }
    }

    &__table {
      &-pull {
        padding: 12px 0;
        font-size: 13px;

        &:before {
          width: 16px;
          height: 16px;
          top: 55%;
        }
      }

      &-title {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 10px;
      }

      &-col {
        width: 100%;
        margin-bottom: 5px;
      }

      &-line {
        padding-bottom: 12px;
      }
    }
  }
}

.brand-cars {
  margin-top: -15px;
  margin-bottom: 48px;

  &-title {
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent;

    &__col {
      padding: 15px 32px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: rgba($base-color, .5);
      width: 13%;

      &:first-child {
        width: 20%;
      }

      &:last-child {
        width: 28%;
      }

      &-filter {
        position: relative;
        color: inherit;
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;

        &:hover {
          color: $base-color;
        }

        &:after {
          @include pseudo();
          right: -15px;
          border: 1px solid;
          width: 8px;
          height: 8px;
          border-top: 0;
          border-left: 0;
          transform: rotate(45deg);
          top: 4px;
        }

        &.desc {
          &:after {
            top: 7px;
            border-top: 1px solid;
            border-left: 1px solid;
            border-bottom: 0;
            border-right: 0;
          }
        }
      }
    }
  }

  &__item {
    border: 1px solid #e6e6e6;
    border-radius: 24px;
    overflow: hidden;
    transition: $transition;

    &.active {
      border: 1px solid #dcdcdc;
      box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.05);
    }

    &-discount {

      position: absolute;
      color: #fff;
      left: 50%;
      transform: translate3d(-50%, 0px, 0px);
      top: 30%;
      margin-top: -32px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      background: #E4AE55;
      border-radius: 4.06875px;
      width: 217px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-title {
      font-weight: 600;
      font-size: 24px;
      display: block;
      margin-bottom: 8px;
    }

    &-color {
      width: 15px;
      height: 15px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 100%;
      margin-top: -2px;
    }

    &+.brand-cars__item {
      margin-top: 8px;
    }

    &-hidden {
      display: none;

      &-line {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #E9E9E9;
      }
    }

    &-img {
      width: 20%;
      padding: 20px 20px 0;
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        display: block;
        max-width: 80%;
        position: relative;
        z-index: 1;

        @include respond-below(sx) {
          margin: 0 auto;
        }
      }

      .favorites-link {
        position: absolute;
        z-index: 2;
        top: 20px;
        left: 20px;

        @include respond-below(lg) {
          top: 10px;
          left: 10px;
        }
      }
    }

    &-controls {
      width: 42%;
      padding: 32px 32px 32px 0;
      display: flex;
      justify-content: flex-end;

      .catalog-item__price {
        display: block;
        margin: 0;
        text-align: right;

        &-title {
          font-weight: 600;
          font-size: 16px;
          margin: 0;

          &.__old {
            text-decoration: line-through;
            color: $primary;
          }

          &.__value {
            color: #222222;
            opacity: 0.4;
          }
        }

        &-current {
          margin: 3px 0 20px;
          font-size: 24px;
          line-height: 1;
          color: $base-color;
        }

        &-credit {
          display: block;
          color: #222222;
          opacity: 0.8;
          font-size: 16px;
          margin: 10px auto 0;
        }
      }

      .favorites-link {
        display: block;
        position: relative;
        margin: 0 auto;
      }

      &-col {
        &:nth-child(1) {
          margin-right: auto;
          min-width: 150px;
          text-align: center;
        }

        &:nth-child(3) {
          display: flex;
          flex-direction: column;
          width: 230px;
          margin-left: 30px;

          .flex {
            display: flex;
            margin: 15px -5px 0;
          }

          .btn {
            &.__1 {
              width: 100%;
              padding: 0;
              margin-top: 8px;
            }

            &.__2,
            &.__3 {
              display: flex;
              align-items: center;
              justify-content: center;
              width: calc(100% / 2 - 10px);
              padding: 0 10px;
              margin: 0 5px;
              line-height: 1;
            }
          }
        }

        @include respond-below(xl) {
          &:nth-child(1) {
            min-width: 140px;
          }
        }

        @include respond-below(lg) {
          &:nth-child(3) {
            width: 230px;
          }
        }
      }
    }

    &-info {
      width: 42%;
      padding: 32px 0 32px 32px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      ul {
        @include listReset;

        b {
          color: $primary;
        }

        li+li {
          margin-top: 10px;
        }

        &.brand-cars__item-info-list {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 20px;

          li {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            line-height: 1.1;
            font-weight: 600;
            width: 50%;

            img {
              opacity: .16;
              width: 22px;
              margin-right: 10px;
            }
          }
        }
      }

      &-col {
        &:nth-child(1) {
          width: 50%;
        }

        &:nth-child(1),
        &:nth-child(2) {
          position: relative;
          padding-right: 32px;
          margin-right: 32px;
          height: 100%;

          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background: #E9E9E9;
          }

          @include respond-below(xl) {
            padding-right: 22px;
            margin-right: 22px;
          }
        }

        @include respond-below(lg) {
          &:nth-child(2) {
            padding-right: 0;
            margin-right: 0;

            &:after {
              display: none;
            }
          }
        }

        @include respond-below(sm) {
          &:nth-child(1) {
            &:after {
              display: none;
            }
          }
        }
      }

      .more {
        color: $primary-second;
        background: rgba(254, 204, 71, 0.2);
        border: 1px solid #FECC47;
        padding: 2px 8px;
        border-radius: 27px;

        &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-left: 9px;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: url('../svg/arrow-circle-up-white-blue.svg') no-repeat 0 0;
          background-size: contain;
          transform: rotate(180deg);
          transition: all .2s;
        }

        &.active {
          &:after {
            transform: rotate(0deg);
          }
        }
      }
    }

    &-pull {
      display: flex;
      justify-content: space-between;
      background: #F7F7F7;
      transition: $transition;
      cursor: pointer;

      &.active {
        background: none;
      }

      &-btn {
        padding: 0 10px;

        &:after {
          content: '';
          @include size(40px);
          background: url(../img/icons/btn-arrow.svg) no-repeat center;
          -webkit-background-size: contain;
          background-size: contain;
          display: block;
          transition: $transition;
        }

        &.active {
          &:after {
            transform: rotate(180deg);
            opacity: 0.5;
          }
        }
      }

      &__col {
        padding: 15px 32px;
        width: 13%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
          width: 20%;
        }

        &:last-child {
          width: 32%;
        }
      }

      &-title {
        font-size: 20px;
        font-weight: 600;
      }

      &-old {
        font-weight: 600;
        font-size: 18px;
        text-decoration-line: line-through;
        opacity: .4;
      }

      &-discount {
        font-weight: 600;
        font-size: 24px;
        position: relative;
        padding-left: 34px;

        &:before {
          @include pseudo();
          background: url(../img/icons/discount.svg) no-repeat center;
          -webkit-background-size: contain;
          background-size: contain;
          @include size(24px);
          left: 0;
          top: 50%;
          margin-top: -12px;
        }
      }
    }

    &-desc {
      padding: 50px 32px;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #E9E9E9;
      }

      &__offers,
      &__list {
        @include flex();
        justify-content: space-between;
      }

      &__col {
        width: 30%;
        padding: 16px 0;

        @include respond-below(md) {
          width: calc(100% / 2);
        }

        @include respond-below(sx) {
          width: 100%;
        }
      }

      &__ttl {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 20px;
      }

      &__txt {
        padding: 0;

        li {
          position: relative;
          display: block;
          list-style: none;
          font-size: 16px;
          line-height: 1.4;
          padding-left: 20px;

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 8px;
            left: 0;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: $primary;
          }
        }
      }

      &__offers {
        margin: 20px 0;

        .proposals-item {
          width: 31%;
          padding: 30px;

          &__title {
            font-size: 24px;
          }

          &__txt {
            width: 75%;
          }

          p {
            font-size: 15px;
          }

          .btn {
            margin-top: 20px;
          }
        }

        .proposals-item {
          &:before {
            display: none;
          }

          &__img {

            &--c-b,
            &--c-c,
            &--c-r {
              @include flex();
              align-items: center;
              bottom: 0;
              justify-content: flex-end;
              position: absolute;
            }

            &--c-b {
              width: 70%;
              right: 0;
            }

            &--c-c {
              width: 70%;
              top: 0;
              right: 0;
            }

            &--c-r {
              width: 100%;
              top: 10%;
              right: 5%;
            }

            img {
              display: block;
              max-height: 100%;
              image-rendering: -webkit-optimize-contrast;
              image-rendering: optimizeQuality;
            }
          }
        }

        @include respond-below(xl) {
          .proposals-item {
            padding: 25px;

            &__title {
              font-size: 20px;
            }

            p {
              font-size: 14px;
            }
          }
        }

        @include respond-below(lg) {
          .proposals-item {
            &__txt {
              width: 100%;
            }

            &__img {
              display: none;
            }
          }
        }

        @include respond-below(md) {
          margin-bottom: 0;

          .proposals-item {
            width: 100%;
            margin-bottom: 20px;

            &__txt {
              width: 60%;
              padding: 0;
              background: transparent;
            }

            &__img {
              display: flex;
              justify-content: flex-end;

              &--c-r {
                top: 0;
              }
            }
          }
        }

        @include respond-below(sm) {
          .proposals-item {
            &__txt {
              width: 100%;
            }

            &__img {
              display: none;
            }

            p {
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  @include respond-below(xl) {
    &-title {
      &__col {
        font-size: 12px;
        line-height: 18px;

        &-filter {
          &:after {
            right: -12px;
            top: 3px;
          }
        }
      }
    }

    &__item {
      &-title {
        font-size: 20px;
      }

      &-controls {
        width: 38%;

        .catalog-item__price {
          &-title {
            font-size: 14px;
          }

          &-current {
            font-size: 17px;
          }

          &-credit {
            font-size: 14px;
          }
        }

        &-col {
          &:nth-child(2) {
            width: 140px;
          }

          &:nth-child(3) {
            width: 220px;
            margin-left: 20px;
          }
        }
      }

      &-pull {
        font-size: 14px;

        &-btn {
          font-size: 14px;

          &:after {
            @include size(30px);
          }
        }

        &-title {
          font-size: 18px;
        }

        &-old {
          font-size: 16px;
        }

        &-discount {
          font-size: 20px;
          padding-left: 30px;

          &:before {
            @include size(20px);
            margin-top: -10px;
          }
        }
      }
    }
  }

  @include respond-below(lg) {
    margin-top: 0;

    &-title {
      display: none;
    }

    &__item {
      &-pull {
        &__col {
          width: 33%;
          flex-grow: 1;
          text-align: right;
          padding: 16px;

          &:first-child {
            text-align: left;
          }
        }

        &-title {
          font-size: 14px;
          font-weight: 600;
        }

        &-old {
          font-size: 12px;
        }

        &-discount {
          font-size: 16px;
          padding-left: 0;

          &:before {
            display: none;
          }
        }
      }

      &-hidden {
        &-line {
          flex-wrap: wrap;
        }
      }

      &-img {
        width: 30%;
        padding: 10px;
      }

      &-controls {
        width: 100%;
        margin-left: auto;
        padding: 16px;
        align-items: center;
      }

      &-info {
        width: 70%;
        padding: 16px;
      }
    }

    .brand-cars__item-pull__col.brand-cars__item-pull__col-hide {
      display: none;
    }
  }

  @include respond-below(sm) {
    margin-top: 0;
    margin-left: -17px;
    margin-right: -17px;

    &-title {
      display: none;
    }

    &__item {
      &-pull {
        &-btn {
          padding: 0 5px;
          font-size: 12px;
          white-space: nowrap;
          line-height: 34px;
          height: 34px;

          &:after {
            @include size(20px);
          }
        }

        &-discount {
          font-size: 12px;
          white-space: nowrap;
          padding-right: 5px;
        }

        &__col {
          width: 33%;
          flex-grow: 1;
          text-align: right;
          padding: 8px 14px;

          &:first-child {
            text-align: left;
          }
        }

        &-title {
          font-size: 14px;
          font-weight: 600;
        }

        &-old {
          font-size: 12px;
        }

        &-discount {
          font-size: 16px;
          padding-left: 0;

          &:before {
            display: none;
          }
        }
      }

      &-hidden {
        position: relative;

        &-line {
          flex-wrap: wrap;
        }
      }

      &-img {
        width: 100%;
        padding: 10px 10px 0 10px;

        img {
          margin: 0 auto;
        }
      }

      &-controls {
        width: 100%;
        margin-left: auto;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding: 0 16px 16px;

        .catalog-item__price {
          display: flex;
          flex-wrap: wrap;
          text-align: left;

          &-current,
          &-credit,
          &-title.__old,
          &-title.__value {
            width: 50%;
          }

          &-title.__old,
          &-current {
            text-align: right;
          }

          &-current {
            font-size: 24px;
            margin: 0;
            order: 4;
          }

          &-title {
            &.__old {
              order: 2;
            }

            &.__value {
              order: 1;
            }
          }

          &-credit {
            margin-top: 0;
            order: 3;
          }
        }

        .favorites-link {
          position: absolute;
          top: 10px;
          left: 10px;
        }

        &-col {
          width: 100%;

          &:nth-child(1) {
            text-align: left;
            margin-bottom: 0;
          }

          &:nth-child(2) {
            width: 100%;
            margin-top: 5px;
            align-self: flex-start;
          }

          &:nth-child(3) {
            padding: 0;
            margin: 10px 0 0;
            width: 100%;
          }
        }
      }

      &-info {
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0 16px;

        &-col {
          width: 100%;

          &:nth-child(1) {
            width: 100%;
            padding: 0;
            margin: 0 0 15px;
            border-right: 0;
          }

          ul {
            display: flex;
            flex-wrap: wrap;

            li {
              margin: 0 10px 4px 0;
            }

            li+li {
              margin-top: 0;
            }
          }
        }

        ul.brand-cars__item-info-list {
          margin-bottom: 10px;

          li {
            width: 45%;
          }
        }
      }

      &-desc {
        padding: 0;
        text-align: left;

        &:before {
          margin-top: 20px;
        }

        &-col {
          padding: 10px 0;
        }

        &-ttl {
          font-size: 16px;
        }

        &__txt {
          li {
            padding-left: 15px;
            font-size: 14px;
            line-height: 1.2;

            &:before {
              top: 4px;
            }
          }
        }
      }
    }

    .brand-cars__item-pull__col.brand-cars__item-pull__col-hide {
      display: none;
    }
  }
}

.program-proposal {
  background: $primary-second;
  box-shadow: 0 15px 16px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  padding: 35px 80px 68px;
  color: #fff;
  position: relative;

  a {
    color: #fff;
  }

  // &:before {
  //   @include pseudo();
  //   background: url(../img/offer-car.webp) no-repeat bottom left;
  //   top: 0;
  //   left: -180px;
  //   right: 0;
  //   bottom: 0;
  //   background-size: auto;
  //   pointer-events: none;
  // }

  // &:after {
  //   @include pseudo();
  //   background: url(../img/offer-family.webp) no-repeat bottom right;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-size: auto;
  //   pointer-events: none;
  // }

  &-title {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;

    &__percent {
      // color: $primary;
      flex-grow: 1;
      padding: 0 20px;
      line-height: 1;
      max-width: 270px;

      b,
      i {
        display: block;
      }

      b {
        font-weight: 900;
        /*font-size: 96px;*/
        line-height: .7;
        font-size: 52.2218px;
        letter-spacing: 0.025em;
      }

      i {
        /* font-size: 20px;*/
        font-style: normal;
        text-align: center;
        font-size: 12.78px;
        line-height: 16px;
        letter-spacing: 0.025em;
        font-weight: bold;
      }
    }

    &__subtitle {
      max-width: 400px;
    }
  }

  .jumbotron-content__form {
    max-width: 780px;
    margin: 51px auto 0;
    padding-left: 100px;
    z-index: 2;
    position: relative;

    &-title-ttl {
      margin-top: -40px;
      margin-bottom: 20px;
      font-size: 28px;
      width: 100%;
      text-align: center;
      display: block;
      font-weight: 600;

      b {
        font-weight: 700;
        font-size: 52px;
        vertical-align: middle;
      }
    }

    &-title-line {
      background: rgba(255, 255, 255, .24);
    }
  }

  @include respond-below(xl) {
    padding: 35px 40px 68px;

    .jumbotron-content__form {
      max-width: 680px;
      padding-left: 120px;
    }

    &-title {
      .h2 {
        font-size: 36px;
      }
    }
  }

  @include respond-below(lg) {
    padding-bottom: 170px;

    &:before,
    &:after {
      opacity: .45;
      background-size: auto 35%;
    }

    &-title {
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      max-width: 680px;
      // margin: 0 auto;

      .h2 {
        margin-bottom: 30px;
      }

      .flex {
        display: flex;
        align-items: flex-start;
      }

      &__percent {
        font-size: 140px;
        margin: 0 0 20px 0;
      }

      &__subtitle {
        max-width: 100%;
      }
    }

    .jumbotron-content__form {
      margin: 38px auto 0;
      padding-left: 0;
    }
  }

  @include respond-below(md) {
    margin-left: -17px;
    margin-right: -17px;
    padding: 32px 15px;
    background: url(/samovar/front/img/offer-bk.png) no-repeat;
    background-position-x: center;

    &:after {
      display: none;
    }

    &:before {
      @include pseudo();
      top: 15px;
      right: 15px;
      left: auto;
      bottom: auto;
      width: 42px;
      height: 42px;
      background: url(../svg/gos-sign-circle.svg) no-repeat 0 0;
      background-position: center;
      background-size: contain;
      opacity: 1;
    }

    &-title {
      &__percent {
        /*font-size: 110px;*/
        color: #0B273F;
        background: #FFFFFF;
        border-radius: 13.8955px;
        padding: 14px 9px;
        margin-right: 10px;
      }
    }
  }

  @include respond-below(sm) {
    /* padding-top: 62px;*/

    &-title {
      .h2 {
        /*font-size: 24px;*/
        text-align: left;
        font-size: 30.4713px;
        line-height: 37px;
        width: 100%;
        margin-bottom: 21px;
        color: #0B273F;
      }

      &__subtitle {
        font-size: 15px;
      }
    }

    .program-proposal-flex {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      align-items: flex-start;
    }

    .jumbotron-content__form {
      &-title-ttl {
        margin-top: 20px;
        font-size: 20px;

        b {
          font-size: 32px;
        }
      }
    }
  }
}

.program-proposal-title__subtitle {
  text-align: left;
  font-size: 13.264px;
  line-height: 20px;
  letter-spacing: 0.025em;
  padding-left: 10px;
  border-left: 1.14291px solid #0B273F;
  color: #0B273F;
  font-weight: 900;
  list-style: none;
}

.program-proposal-title__subtitle p {
  margin: 0 0 6px;
}

.program-proposal .jumbotron-content__form-title-ttl {
  position: absolute;
  left: -15px;
  top: -42px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background: #FFF;
  color: #0B273F;
  font-size: 15.1277px;
  line-height: 16px;
  padding: 5px 10px 5px 5px;
  width: auto;
  letter-spacing: 0.025em;
  font-weight: 900;
  text-transform: lowercase;
  margin: 0;
}

.program-proposal .jumbotron-content__form-title-txt {
  flex-flow: column;
}

.program-proposal .jumbotron-content__form-title-ttl b {
  font-size: 15.1277px;
  line-height: 16px;
}

.program-proposal .jumbotron-content__form-title-txt {
  flex-flow: column;
  align-items: flex-end;
  font-weight: 900;
  font-size: 8.60205px;
  line-height: 10px;
  letter-spacing: 0.025em;
  color: #000;
  // margin-bottom: 20px;
}

.program-proposal .jumbotron-content__form-title-txt b {
  font-size: 16.5846px;
  line-height: 20px;
  letter-spacing: 0.025em;
  font-weight: 900;
  margin-top: 6px;
}

.program-proposal .jumbotron-content__form .form-inline .form-control.form-control--dark {
  border: 0.820075px solid #0B273F;
  background: none;
  height: 32px;
  margin-bottom: 10px;
  font-weight: 900;
  font-size: 13.4328px;
  line-height: 16px;
  color: #ABABAB;

  @media (min-width: 1600px) {
    font-size: 18.5747px;
    line-height: 23px;
  }
}

.program-proposal .jumbotron-content__form .form-inline .form-control.form-control--dark::placeholder {
  font-weight: 900;
  font-size: 13.4328px;
  line-height: 16px;
  color: #ABABAB;

  @media (min-width: 1600px) {
    font-size: 18.5747px;
    line-height: 23px;
  }
}

.program-proposal .jumbotron-content__form .form-inline .btn {
  position: absolute;
  bottom: -10px;
  border-radius: 0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  right: -15px;
  font-weight: 900;
  font-size: 15.7046px;
  line-height: 19px;
  letter-spacing: 0.025em;
  height: 47px;
  text-transform: uppercase;
  gap: 17px;
}

.program-proposal .jumbotron-content__form .text-sm {
  display: none;
}

.program-proposal .jumbotron-content__form .form-inline {
  padding-bottom: 280px;
}

.form-control_img_position {
  position: absolute;
  bottom: 39px;

  img {
    width: 84%;
    display: block;
    margin: 0 auto;

    &[alt="dongfeng"] {
      width: 67%;
    }
  }

  @media (min-width: 1024px) {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: 233px;

    & img {
      width: 100%;

      &[alt="dongfeng"] {
        width: 100%;
      }

      &[alt="ford"] {
        margin-bottom: 20px;
      }

      &[alt="nissan"] {
        margin-bottom: 20px;
      }
    }
  }

  @media (min-width: 1280px) {
    width: 401px;
    bottom: 0;
  }

  @media (min-width: 1440px) {
    width: 460px;
    bottom: -1px;
  }
}

.used-filters {
  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .h5 {
      margin-bottom: 25px;
    }

    *+.text-xl {
      margin-top: 26px;
    }

    .text-xl {
      display: flex;
      font-weight: 600;
      margin-bottom: 14px;
      white-space: nowrap;
      align-items: center;
      justify-content: space-between;

      &:after {
        content: '';
        flex-grow: .95;
        height: 1px;
        background: #222222;
        opacity: .12;
        display: block;
      }
    }

    .checkbox-list {
      @include flex();
      transition: $transition;

      &.checkbox-list--switcher {
        max-height: 26px;

        &.active {
          max-height: unset;
        }

        .checkbox-list__item {
          width: 25%;
          font-weight: 400;
          font-size: 16px;
        }
      }

      &__item {
        width: 20%;
        margin-bottom: 6px;
        margin-top: 0;
        font-weight: 600;
        font-size: 15px;

        .checkbox-list__light {
          opacity: .3;
        }

        &+.checkbox-list__item {
          margin-top: 0;
        }
      }
    }
  }

  &__col-wide {
    width: 64%;
    padding-right: 32px;
  }

  &__col {
    width: 36%;
    background: #FFFFFF;
    box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    padding: 32px;

    .btn {
      line-height: 1;
      padding: 0 10px;
    }
  }

  @include respond-below(lg) {
    &__row {
      flex-direction: column-reverse;

      .checkbox-list {
        max-height: unset;

        &.checkbox-list--switcher {
          max-height: unset;
        }
      }
    }

    &__col-wide {
      width: 100%;
      height: 0;
      padding-right: 0;
      pointer-events: none;
      opacity: 0;

      &.active {
        height: unset;
        pointer-events: auto;
        opacity: 1;
      }
    }

    &__col {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  @include respond-below(md) {
    &__col {
      width: calc(100% + 34px);
      margin-left: -17px;
      margin-right: -17px;
    }

    &__row {
      .checkbox-list {
        &.checkbox-list--switcher {
          .checkbox-list__item {
            width: 50%;
          }
        }

        &__item {
          width: 50%;
        }
      }
    }
  }
}

.section__tabs {
  &-item {
    padding: 16px 0;
    border-top: 4px solid transparent;
    transition: $transition;
    margin: 0 16px;
    color: $base-color;
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    &.active {
      color: $primary;
      border-color: $primary;
    }
  }

  &-list {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -16px 8px;
  }

  &-box {
    position: relative;
    display: none;

    &.active {
      display: block;
    }
  }

  .swiper-slide {
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .gallery-top {
    width: 81%;
    margin: 0;
    box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    overflow: hidden;

    .swiper-slide {
      @include pseudoHeight(52%);
    }
  }

  .gallery-thumbs {
    position: absolute;
    top: 0;
    right: 0;
    width: 19%;
    padding-left: 16px;
    height: 100%;

    .swiper-slide {
      border-radius: 16px;
    }
  }

  @include respond-below(lg) {
    .gallery-top {
      width: 100%;
      overflow: visible;
      border-radius: 0;
      box-shadow: none;

      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }

      .swiper-slide {
        box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
        border-radius: 24px;
      }
    }

    .gallery-thumbs {
      width: 0;
      height: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }

  @include respond-below(md) {
    &-box {
      margin-left: -17px;
      margin-right: -17px;
    }

    .gallery-top {
      padding-right: 24px;
    }
  }
}

.features__title {
  display: block;
  text-align: center;
  font-weight: 400;
  margin-bottom: 16px;
}

.features__block {
  @include flex;
  padding-top: 53px;
  position: relative;

  p {
    margin: 0;
  }

  &:before {
    @include pseudo();
    top: 0;
    background: url(../img/features.svg) no-repeat top center;
    left: 0;
    right: 0;
    height: 36px;
    background-size: 75%;
  }

  &-item {
    width: 25%;
    text-align: center;
  }

  &-number {
    display: block;
  }

  @include respond-below(md) {
    padding-top: 0;

    &:before {
      display: none;
    }
  }

  @include respond-below(sm) {
    margin: -10px -17px -10px;

    &-item {
      width: 50%;
      padding: 10px;
    }
  }
}

.discount-line {
  display: flex;
  background: $primary-second;
  border-radius: 16px;
  color: #fff;
  text-align: center;
  margin-top: 32px;

  &__col {
    flex-grow: 1;
    padding: 12px;
    font-weight: 600;

    &.discount-line__current {
      background: $primary;
      border-radius: 15px;
    }
  }

  @include respond-below(sm) {
    &__col {
      font-size: 14px;

      b {
        display: block;
      }
    }
  }
}

.special {
  @include flex;
  margin: -16px;

  &-col {
    width: 20%;
    padding: 16px;
  }

  &-slider {
    .special-item {
      height: calc(50% - 8px);
    }
  }

  &-item {
    height: 100%;
    position: relative;
    font-size: 14px;
    padding: 25px 26px 17px;
    background: #FFFFFF;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.06);
    border-radius: 16px;

    &+.special-item {
      margin-top: 16px;
    }

    &__t1 {
      display: block;
      font-weight: 600;
      font-size: 18px;
    }

    &__t2 {
      display: block;
      opacity: .64;
      line-height: 1.1;
    }

    .link-stretched {
      margin-top: 7px;
      font-weight: 700;
      color: #222222;
      opacity: 0.32;
      display: inline-block;
      vertical-align: top;

      &:after {
        display: none;
      }

      &:hover {
        opacity: 1;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 14px;
    }

    &__price {
      font-weight: 600;
      font-size: 22px;
      line-height: 0.8;
      display: block;
      text-align: right;
    }

    &__discount {
      padding-left: 60px;
      position: relative;
      color: rgba($base-color, .4);
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      cursor: pointer;

      &:before {
        @include pseudo();
        background: url(../img/discount-disabled.svg) no-repeat center;
        -webkit-background-size: contain;
        background-size: contain;
        left: 0;
        top: 50%;
        width: 49px;
        height: 28px;
        margin-top: -14px;
      }

      &.active {
        color: $primary;

        &:before {
          background-image: url(../img/discount-active.svg);
        }
      }
    }
  }

  @include respond-below(xl) {
    &-col {
      width: 25%;
    }
  }

  @include respond-below(md) {
    &-item {
      &+.banks-item {
        margin-top: 8px;
      }
    }
  }

  @include respond-below(md) {
    &-item {
      &__price {
        font-size: 18px;
      }
    }
  }
}

.about-block {
  @include flex;
  align-items: center;

  &__content {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #7A7A7A;
    padding-right: 70px;

    h3,
    .h3 {
      margin-bottom: 0;
      color: $base-color;
    }
  }

  //&__slider {
  //  border-radius: 24px;
  //  overflow: hidden;
  //}

  &__gallery {
    width: 55%;
    position: relative;

    &-img {
      display: block;
      margin: 0 auto;
      max-width: 60%;
      padding: 16px;
    }

    //.swiper-slide {
    //  background-repeat: no-repeat;
    //  background-position: 50%;
    //  background-size: cover;
    //  @include pseudoHeight(60%);
    //}

    //.swiper-button-next, .swiper-button-prev {
    //  @include size(64px);
    //  color: #fff;
    //  background: rgba(#172B4D, .9);
    //  border-radius: 30px;
    //  top: auto;
    //  bottom: 0;
    //  margin: 0;
    //  right: auto;
    //
    //  &:hover {
    //    background: #172B4D;
    //  }
    //}

    //.swiper-button-next {
    //  border-bottom-left-radius: 0;
    //  border-top-left-radius: 0;
    //  left: 0;
    //}
    //
    //.swiper-button-prev {
    //  border-bottom-right-radius: 0;
    //  border-top-right-radius: 0;
    //  left: -64px;
    //}
  }

  @include respond-below(md) {
    flex-direction: column-reverse;

    &__content {
      width: 100%;
      padding-right: 0;
      display: block;
    }

    //&__slider {
    //  border-radius: 0;
    //  overflow: visible;
    //  padding-right: 24px;
    //
    //  .swiper-slide {
    //    border-radius: 24px;
    //    overflow: hidden;
    //  }
    //}

    &__gallery {
      width: calc(100% + 34px);
      margin-left: -17px;
      margin-right: -17px;
      //margin-bottom: 40px;

      &-img {
        max-width: 30%;
      }

      //.swiper-slide {
      //  border-radius: 24px;
      //  overflow: hidden;
      //}
      //
      //.swiper-button-next, .swiper-button-prev {
      //  display: none;
      //}
    }
  }

  @include respond-below(sx) {
    &__gallery {
      &-img {
        max-width: 60%;
      }
    }
  }
}

.range-block__value {
  display: block;
  margin-bottom: 18px;
  font-weight: 600;
  font-size: 24px;
}

.range-block__item-numbers {
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  padding-top: 13px;
  opacity: 0.4;
}

.auto-credit {
  position: relative;

  &:before {
    @include pseudo();
    background: url(../img/autocredit.webp) no-repeat left center;
    bottom: -50px;
    left: -100px;
    top: 0;
    right: 0;
  }

  &-block {
    position: relative;
    width: 80%;
    margin-left: auto;
    background: #FFFFFF;
    box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
    border-radius: 16px;
    display: flex;

    &__left {
      width: 61%;
      padding: 58px 96px;
    }

    &__right {
      width: 39%;
      background: #F3F3F3;
      border-radius: 16px;
      padding: 52px 62px 75px;

      .checkbox-list__item {
        font-size: 12px;
        margin-top: 16px;

        a {
          font-weight: 600;
          color: $base-color;

          &:hover {
            color: $primary;
          }
        }
      }

      .form-control__field {
        margin-bottom: 45px;
        position: relative;

        &.focused,
        &.filled {
          .form-control--xl__label {
            font-size: 16px;
            top: 0;
          }
        }
      }
    }
  }

  @include respond-below(lg) {
    &-block {
      width: 85%;

      &__left {
        width: 60%;
        padding: 58px;
      }

      &__right {
        padding: 52px;
      }
    }
  }

  @include respond-below(md) {
    margin-left: -17px;
    margin-right: -17px;
    padding-top: 200px;

    &:before {
      background: url(../img/autocredit.webp) no-repeat top center;
      bottom: 0;
      left: 0;
      top: 0;
      right: 0;
      background-size: 414px;
    }

    &-block {
      width: 100%;
      flex-wrap: wrap;
      box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.06);

      &__left {
        width: 100%;
        padding: 23px 16px;
      }

      &__right {
        width: 100%;
        padding: 23px 16px;
      }
    }
  }

  @include respond-below(sm) {
    &-block {
      &__left {
        .range-block__item .noUi-pips-horizontal>.noUi-value {
          font-size: 10px;
        }
      }
    }
  }
}

.page-nav {
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
  border-radius: 24px;
  margin-top: -100px;

  ul {
    @include listReset;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      flex-grow: 1;
      text-align: center;

      &+li {
        border-left: 1px solid rgba(#E9E9E9, .6);
      }

      a {
        color: $base-color;
        display: inline-block;
        vertical-align: top;
        position: relative;
        padding: 35px 10px 35px 45px;

        &:before {
          @include pseudo();
          top: 50%;
          left: 0;
          @include size(32px);
          margin-top: -16px;
          background: url(../img/icons/btn-arrow.svg) no-repeat center;
          -webkit-background-size: contain;
          background-size: contain;
        }

        &:hover {
          opacity: .5;
        }
      }
    }
  }

  @include respond-below(lg) {
    display: none;
  }
}

.form-txt {
  display: block;
  margin-top: 10px;
  opacity: .5;
  font-size: 12px;
}

.tradein-form {
  position: relative;
  border-radius: 25px;
  background: linear-gradient(to bottom, #f5f5f5, #e0e0e0);

  .h2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      margin-right: 15px;
      max-width: 2em;
    }
  }

  .text-xxl {
    display: block;
    margin-bottom: 32px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 550px;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    z-index: 1;

    &-mobile {
      display: none;
    }
  }

  &__content {
    position: relative;
    padding: 64px 79px 77px;
    z-index: 1;
  }

  .form-row {
    max-width: 780px;
  }

  @include respond-below(md) {
    margin: 0 -17px;
    padding-top: 70px;
    position: relative;

    .h2 {
      align-items: center;
      justify-content: center;

      img {
        display: none;
      }
    }

    &__bg {
      display: none;

      &-mobile {
        display: block;
        position: absolute;
        bottom: 100%;
        margin-bottom: -10%;
        left: 0;
        right: 0;
        background-size: 257px;
        height: 136px;
        background-repeat: no-repeat;
        background-position: 50%;
        z-index: 3;
      }
    }

    &__content {
      box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
      border-radius: 24px;
      padding: 24px 16px 24px;
      text-align: center;
      position: relative;
      z-index: 2;
    }
  }

  @include respond-below(sm) {
    padding-top: 0;

    &__bg {
      &-mobile {
        display: none;
      }
    }

    .form-col.form-col-25 {
      width: 100%;
    }
  }
}

.gallery {
  .gallery-slider {
    img {
      border-radius: 30px;
    }
  }

  .section__slider-pagination {
    top: 0;
    right: 3%;

    @include respond-below(sm) {
      display: none;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    @include respond-below(sm) {
      width: 50px;
      height: 50px;
      margin-top: -10px;
    }
  }
}

.sales-block {
  border-radius: 24px;
  overflow: hidden;
  background: url(../img/sale-block__desk.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 18px 28px;
  background-position: 100% 50%;
  position: relative;
  box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.03);
  margin-top: 24px;

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__info-head {
    font-weight: 900;
    font-size: 29.5289px;
    line-height: 30px;
    letter-spacing: 0.025em;
    color: #0B273F;

    @include respond-below(md) {
      font-size: 13.0885px;
      line-height: 16px;

    }
  }

  .grid {
    display: grid;
    grid-row-gap: 50px;

    @include respond-below(md) {
      grid-row-gap: 84px;
    }
  }

  &_position_img {
    position: absolute;
    bottom: 0;
    width: 471px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);

    @include respond-below(lg) {
      width: 341px;
    }

    @include respond-below(sm) {
      bottom: 19px;
      width: 250px;
      left: 64%;
    }
  }

  &__info-text {
    font-weight: 900;
    font-size: 22.1665px;
    line-height: 22px;
    letter-spacing: 0.025em;
    color: #FFFFFF;

    @include respond-below(md) {
      font-size: 9.68917px;
      line-height: 12px;
    }
  }

  &__txt {
    position: relative;
    color: $primary-second;
    padding: 24px 70px 24px 36px;
    width: 40%;

    &-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 22%;
        display: block;
      }
    }

    &-subtitle {
      max-width: 72%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 700;
      font-size: 30px;
      padding-left: 40px;
      line-height: 1.1;

      b {
        font-size: 72px;
        padding: 0 10px;
      }
    }

    * {
      position: relative;
    }

    &:before {
      @include pseudo();
      top: 0;
      left: -100px;
      right: 0;
      bottom: 0;
      background: $primary;
      transform: skewX(-25deg);
    }
  }

  &.__right {
    background-position: 130% 50%;
  }

  @include respond-below(xl) {
    &.__right {
      background-position: 140% 50%;
    }
  }

  @media (max-width: 1400px) {
    &.__right {
      background-position: 170% 50%;
    }
  }

  @include respond-below(lg) {
    &.__right {
      background-position: 100% 50%;
    }
  }

  @include respond-below(md) {
    margin: -20px -17px 0;
    background: url(../img/sale-block__mob.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 15px 8px;

    .text-xl {
      font-size: 16px;
      text-align: center;
      display: block;
    }

    &__txt {
      padding: 6px;
      width: 100%;
      height: 100px;

      &-title {
        justify-content: center;

        img {
          width: auto;
          height: 60px;
        }
      }

      &-subtitle {
        font-size: 16px;
        padding-left: 20px;

        b {
          font-size: 40px;
        }
      }

      &:before {
        @include pseudo();
        top: -50px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: skewY(0deg);
      }
    }

    &.__right {
      background-position: 50% 100%;
    }
  }

  @include respond-below(sm) {
    height: 150px;
    background-position: 50% 100%;

    &__txt {
      height: 80px;

      &-title {
        img {
          height: 40px;
        }
      }
    }
  }

  @include respond-below(sx) {
    background-position: 50% 100%;
  }
}

.show-md {
  display: none !important;
}

@include respond-below(md) {
  .hide-md {
    display: none !important;
  }

  .show-md {
    display: block !important;
  }
}

.form-control.__error {
  background-color: #ff5b62 !important;
}

.form-control--xl.__error {
  border-color: #ff5b62 !important;
}

.checkbox-block.__error {
  color: #ff5b62 !important;
}

.nice-select.open.__scrollable .list {
  max-height: 200px;
  overflow: auto;
}

.container,
.container-fluid {
  &.__zi-un {
    z-index: unset;
  }
}

.text {
  img {
    max-width: 100%;
    display: block;

    &.__image {
      &-left {
        float: left;
        margin: 0 20px 20px 0;
      }

      &-right {
        float: right;
        margin: 0 0 20px 20px;
      }

      &-center {
        margin: 20px auto;
        float: none;
      }
    }
  }

  iframe {
    max-width: 100%;
  }
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 60px 0 20px;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px 5px 0;
    list-style: none;

    a,
    span {
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 16px;
      min-width: 30px;
      text-align: center;
    }

    a {
      background: $primary;
      color: $primary-second;
      text-decoration: none;

      &:hover {
        background: #E4AE55;
      }
    }

    span {
      background: #f0f0f0;
      color: #000;
    }
  }
}

.link-black {
  color: #222;
}

// .contacts {
//   &__cnt {
//     @include flex();

//     #js_map>ymaps {
//       width: 100% !important;
//       height: 100% !important;
//     }
//   }

//   &__l {
//     width: 30%;
//     padding-right: 20px;

//     @include respond-below(lg) {
//       width: 35%;
//     }

//     @include respond-below(md) {
//       width: 100%;
//       padding-right: 0;
//       margin-bottom: 30px;
//     }
//   }

//   &__r {
//     width: 70%;

//     @include respond-below(lg) {
//       width: 65%;
//     }

//     @include respond-below(md) {
//       width: 100%;
//     }
//   }

//   &__map {
//     width: 100%;
//     height: 587px;

//     @include respond-below(md) {
//       height: 450px;
//     }

//     @include respond-below(sx) {
//       height: 380px;
//     }

//     &-select {
//       display: block;
//       text-decoration: underline;
//       cursor: pointer;

//       &.active,
//       &:hover {
//         text-decoration: none;
//       }
//     }
//   }
// }

.ya-map {
  width: 100%;
  height: 587px;
  display: flex;
}

.map-menu {
  width: 480px;
  padding: 28px 0;
  z-index: 5;
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0px 12px 28px rgba(0, 0, 0, 0.1);
  border-radius: 30px;

  @include respond-below(sm) {
    position: static;
    transform: none;
    width: 100%;
    margin-bottom: 25px;
  }
}

.metro-search {
  width: 100%;
  height: 45px;
  padding: 0 20px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #DCDCDC;
  transition: border .3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.metro-search:focus {
  border: 1px solid #E4AE55;
}

.toggle-tabs {
  &__btns {
    position: relative;
    margin: 0 13px 18px 13px;
    display: flex;
    justify-content: space-between;

    @include respond-below(sm) {
      flex-direction: column;
    }

    .toggle-btn {
      position: relative;
      margin: 0;
      padding: 0 7px;
      outline: none;
      font-size: 14px;
      line-height: 16px;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;

      @include respond-below(sm) {
        margin-bottom: 5px;
        padding: 4px 10px;
        font-size: 16px;
        line-height: 18px;
        justify-content: flex-start;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 3px;
        background-color: #E4AE55;
        transition: all .3s ease;

        @include respond-below(sm) {
          height: 100%;
          bottom: 0;
          left: 0;
          transform: none;
          border-radius: 5px;
        }
      }

      &.active {
        &::after {
          width: 100%;
        }
      }

      span {
        position: relative;
        z-index: 1;
      }
    }

    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #DCDCDC;
    }
  }

  .toggle-tab {
    padding: 0 20px;
    display: none;

    &.active {
      display: block;
    }

    &__content {
      max-height: 300px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar {
        width: 4px;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #E4AE55;
      }
    }

    p {
      margin: 0 0 10px 0;
    }
  }
}

.metro-selection {

  &__btn {
    position: relative;
    width: 100%;
    height: 30px;
    margin: 0 0 12px 0;
    padding: 0 0 0 45px;
    font-size: 13px;
    line-height: 15px;
    color: #000;
    display: flex;
    align-items: center;

    @include respond-below(sm) {
      font-size: 16px;
      line-height: 18px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &.yellow {
      &::before {
        background-image: url(../img/metro-yellow.png);
      }
    }

    &.green {
      &::before {
        background-image: url(../img/metro-green.png);
      }
    }
  }

  &__content {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #DCDCDC;

    &-item {
      display: flex;
      align-items: center;

      &+.metro-selection__content-item {
        margin-top: 8px;
      }

      span {
        font-size: 16px;
        line-height: 18px;
        color: #000;
      }
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: contain;
  }
}

.accordeon {
  &__content {
    height: 0;
    overflow-y: hidden;
    transition: height .3s ease;

    &-inner {
      padding-bottom: 12px;
    }

    p {
      margin: 0;
    }
  }
}

.taxi-credit {
  &__adv {
    @include flex();
    margin: 60px 0;

    @include respond-below(md) {
      margin: 60px 0 20px;
    }

    @include respond-below(sx) {
      margin: 40px 0 20px;
    }

    &-col {
      @include flex();
      align-items: center;
      width: calc(100% / 4);
      padding: 0 16px;

      @include respond-below(md) {
        width: calc(100% / 2);
        margin-bottom: 40px;
      }

      @include respond-below(sx) {
        width: 100%;
      }
    }

    &-img {
      position: relative;
      font-size: 22px;
      text-align: center;
      font-weight: 600;
      line-height: 1.45;

      &:before,
      &:after {
        content: '';
        position: absolute;
        border: 1px solid #999;
        border-radius: 50%;
      }

      &:before {
        width: 46px;
        height: 46px;
        top: -8px;
        left: -8px;
      }

      &:after {
        width: 63px;
        height: 63px;
        top: -16px;
        left: -17px;
      }
    }

    &-txt {
      width: calc(100% - 64px);
      margin-left: 30px;
    }
  }

  &__conditions {
    @include flex();
    margin: 60px -16px;

    @include respond-below(sx) {
      margin: 30px -16px;
    }

    &-l {
      @include flex();
      width: 66.666%;
      margin: 0 -16px;

      @include respond-below(sx) {
        margin-bottom: 30px;
      }
    }

    &-r {
      width: 33.333%;
    }

    &-l,
    &-r {
      padding: 0 16px;

      @include respond-below(sx) {
        width: 100%;
      }
    }

    &-col {
      width: calc(100% / 2);
      padding: 0 16px;

      &:last-child {
        width: 100%;
      }

      @include respond-below(sx) {
        width: 100%;
      }
    }

    &-excerpt {
      width: 100%;
      padding: 0 16px;
      color: #f20404;
      font-size: 15px;

      @include respond-below(sx) {
        font-size: 14px;
      }
    }

    &-ttl {
      font-size: 20px;
      margin-bottom: 20px;
      font-weight: 600;
    }

    &-list {
      padding-left: 25px;

      li {
        position: relative;
        margin-bottom: 30px;
        list-style: none;

        &:before {
          content: '';
          width: 15px;
          height: 15px;
          position: absolute;
          left: -26px;
          top: 2px;
          background: url('../img/taxi-credit/galka.webp') no-repeat 0 0;
        }

        @include respond-below(sx) {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__service {
    @include flex();
    margin: 60px -16px;

    @include respond-below(sx) {
      margin: 20px -16px;
    }

    &-col {
      width: calc(100% / 2);
      padding: 16px;

      @include respond-below(sx) {
        width: 100%;
      }
    }

    &-box {
      padding: 30px;
      border-radius: 20px;
      border: 1px solid rgba(0, 0, 0, .2);

      @include respond-below(sx) {
        padding: 20px;
      }
    }

    &-img {
      display: block;
      margin: 0 auto 30px;
      border-radius: 10px;
    }

    &-ttl {
      font-size: 20px;
      font-weight: 600;
      margin: 0 auto 30px;
      text-align: center;
    }
  }

  &__form {

    &-ttl,
    &-txt {
      text-align: center;
    }

    &-ttl {
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    &-txt {
      margin-bottom: 20px;
    }
  }

  &__stock {
    margin: 30px 0;

    &-lbl {
      margin-bottom: 20px;
      text-align: center;
    }

    &-tabs {
      @include flexbox(center, center);
      margin-bottom: 40px;
      padding: 0;

      li {
        position: relative;
        list-style: none;
        margin: 0 10px;
        border-radius: 5px;
        border: .5px solid rgba(0, 0, 0, .2);
        font-size: 14px;
        line-height: 13px;
        text-align: center;
        text-transform: uppercase;
        padding: 9px 15px 9px 42px;
        box-sizing: border-box;
        cursor: pointer;
        transition: all .2s;

        &:hover {
          box-shadow: 0 4px 4px hsla(0, 0%, 60%, .2);
        }

        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 15px;
          height: 15px;
          background: url('../img/taxi-credit/galka.webp');
          left: 15px;
          top: 9px;
        }

        &.active {
          background: #8d9090;
          border-color: #8d9090;
          color: #fff;

          &:before {
            background: url('../img/taxi-credit/galka-white.webp');
          }
        }

        @include respond-below(md) {
          font-size: 13px;
          margin: 0 5px 10px;
          padding: 7px 10px 7px 32px;

          &:before {
            left: 10px;
            top: 6px;
          }
        }
      }
    }

    &-car {
      @include flexbox(space-between, center);
      max-width: 1024px;
      margin: 0 auto 20px;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, .4);

      @include respond-below(md) {
        text-align: center;
      }

      &__img {
        width: 220px;

        @include respond-below(md) {
          width: 180px;
          padding-right: 15px;
        }

        @include respond-below(md) {
          width: 100%;
          padding-right: 0;
          margin-bottom: 20px;
        }
      }

      &__image {
        @include respond-below(md) {
          margin: 0 auto;
          width: 210px;
        }
      }

      &__info {
        width: 300px;

        @include respond-below(md) {
          width: 200px;
        }

        @include respond-below(md) {
          width: 100%;
        }
      }

      &__credit {
        padding-right: 20px;

        @include respond-below(md) {
          padding-right: 0;
          width: 100%;
        }
      }

      &__offer {
        @include respond-below(md) {
          width: 100%;
        }
      }

      &__ttl {
        font-weight: 600;
        font-size: 20px;
      }

      &__type {
        font-weight: 600;
        font-size: 18px;
        color: red;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      &__val {
        text-transform: uppercase;
      }

      &__price {
        text-align: center;
        margin-bottom: 15px;

        b {
          font-size: 20px;
        }
      }
    }
  }

  &__calc {
    max-width: 860px;
    margin: 60px auto;
  }
}

.h3__brand {
  font-weight: 600;
  font-size: 24px;
  color: #222;
  margin-top: 24px;
}

.h3__brand--span {
  margin-top: 0;
}

#modalAutoCredit {
  .modal__title {
    font-size: 36px;
    line-height: 32px;
  }
}

.h4__btn {
  display: inline-block;
  vertical-align: top;
  border-radius: 16px;
  position: relative;
  text-align: center;
  cursor: pointer;
  border: 0;
  background: 0 0;
  height: 48px;
  line-height: 48px;
  font-weight: 600;
  font-size: 16px;
}

/* New year modals styles */
// #modalBook,
// #modalTradeIn,
// #modalCredit,
// #modalAutoCredit {
//   height: 475px;
//   padding: 20px;
//   background-image: url(../img/popup/book-ny.jpg);

//   .modal {
//     &-content {
//       max-width: 100%;
//       width: 100%;
//       height: 100%;
//       display: flex;
//       align-items: flex-end;
//       justify-content: flex-start;

//       form {
//         margin-left: 10px;

//         .ny-form {
//           margin-left: 20px;
//           display: flex;

//           &__inputs {
//             margin-right: 15px;
//             display: flex;

//             input {
//               width: 200px;
//               margin-bottom: 0;

//               @include input-placeholder {
//                 color: #000;
//               }

//               &:focus {
//                 background-color: #fff;
//                 background-color: rgba(255, 255, 255, .4);
//               }

//               &+input {
//                 margin-left: 15px;

//                 @include respond-below(sm) {
//                   margin-left: 0;
//                   margin-top: 10px;
//                 }
//               }

//               @include respond-below(sm) {
//                 width: 100%;
//                 height: 55px;
//                 padding: 0 10px;
//                 font-size: 15px;
//                 background-color: rgba(255, 255, 255, .6);
//               }
//             }

//             @include respond-below(sm) {
//               width: 49%;
//               flex-direction: column;
//             }
//           }

//           .js_form-submit {
//             @include respond-below(sm) {
//               width: 49%;
//               height: 55px;
//               padding: 0 5px;
//               font-size: 15px;
//             }
//           }

//           @include respond-below(sm) {
//             width: 100%;
//             margin-left: 0;
//             justify-content: space-between;
//             align-items: flex-end;
//           }
//         }
//       }
//     }
//   }

//   @include respond-below(sm) {
//     padding: 15px 6px;
//     background-image: url(../img/popup/book-ny__mobile.jpg);
//   }
// }

/* / New year modals styles */

// .swiper-slide[aria-label="1 / 7"] .jumbotron__slider-content,
// .swiper-slide[aria-label="2 / 7"] .jumbotron__slider-content,
// .swiper-slide[aria-label="3 / 7"] .jumbotron__slider-content,
// .swiper-slide[aria-label="4 / 7"] .jumbotron__slider-content {
//   display: none;
// }

// @media screen and (max-width: 576px) {

//   .swiper-slide[aria-label="1 / 7"] .jumbotron__slider-bg-mobile,
//   .swiper-slide[aria-label="7 / 7"] .jumbotron__slider-bg-mobile {
//     background-position: center 0%;
//   }
// }

.form {
  &-group {
    margin-bottom: 15px;
  }
}

.noUi-handle {
  &-lower {
    .noUi-tooltip {
      right: -10px;
    }
  }

  &-upper {
    .noUi-tooltip {
      left: -10px;
    }
  }
}

.modal {
  margin: 0 auto !important;
  // height: 475px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &-form {
    display: flex;
    flex-direction: column;
  }

  &-input {
    width: 100%;
    height: 45px;
    padding: 0 20px;
    border-radius: 30px;
    background-color: #fff;
    font-size: 1rem;
    -webkit-box-shadow: 0px 4px 18px 2px rgba(34, 60, 80, 1);
    -moz-box-shadow: 0px 4px 18px 2px rgba(34, 60, 80, 1);
    box-shadow: 0px 4px 18px 2px rgba(34, 60, 80, 1);
    display: flex;

    &+input {
      margin-top: 25px;

      @include respond-below(sm) {
        margin-top: 15px;
      }
    }

    @include respond-below(sm) {
      height: 35px;
      font-size: 0.8rem;
    }
  }

  &-btn {
    width: 100%;
    height: 45px;
    margin-top: 25px;
    padding: 0 10px;
    border-radius: 30px;
    background-color: #ecad00;
    font-size: 1rem;
    text-transform: uppercase;
    color: #fff;
    border: none;
    transition: all .25s ease;
    -webkit-box-shadow: 0px 4px 18px 2px rgba(34, 60, 80, 1);
    -moz-box-shadow: 0px 4px 18px 2px rgba(34, 60, 80, 1);
    box-shadow: 0px 4px 18px 2px rgba(34, 60, 80, 1);

    &:hover {
      opacity: .75;
    }

    &:active {
      transform: translateY(4px);
    }

    @include respond-below(sm) {
      height: 35px;
      margin-top: 15px;
      font-size: 0.8rem;
    }
  }
}

/* Callback modal styles */
#modalCall {
  height: 475px;
  padding: 40px 0 0 50px;
  background-image: url(../img/popup/popup1.jpg);

  @include respond-below(sm) {
    padding: 0;
    background-image: url(../img/popup/popup1__mobile.webp);
  }
}

.callback-modal {
  &__content {
    width: 250px;

    @include respond-below(sm) {
      width: 100%;
    }

    .modal-form {
      @include respond-below(sm) {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__heading {
    margin-bottom: 20px;
    font-size: 1.35rem;
    line-height: 1.05;
    color: #fff;
    font-weight: bold;
    text-align: center;
    display: block;

    @include respond-below(sm) {
      margin-bottom: 10px;
      color: #a1292d;
      font-size: 1.15em;
      text-shadow: 0px 1px 0 rgb(255, 255, 255), 0px -1px 0 rgb(255, 255, 255), 1px 0px 0 rgb(255, 255, 255), -1px 0px 0 rgb(255, 255, 255), 1px 1px 0 rgb(255, 255, 255), 1px -1px 0 rgb(255, 255, 255), -1px 1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255);
    }
  }

  &__mobile-heading {
    display: none;

    @include respond-below(sm) {
      width: 100%;
      height: 40px;
      margin-bottom: 115px;
      background-color: #b31a1f;
      color: #fff;
      font-size: 1.25rem;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

/* / Callback modal styles */

#modalFamily {
  height: 475px;
  padding: 0;
  background-image: url(../img/popup/family.jpg);
  display: inline-flex !important;

  .modal-content {
    max-width: unset;
    width: 100%;
    height: 100%;
  }

  @include respond-below(sm) {
    background-size: cover;
    background-image: url(../img/popup/family-mob.webp);
  }
}

#modalSaleTest {
  height: 475px;
  padding: 0;
  background-image: url(../img/popup/test-auto.jpg);
  display: inline-flex !important;

  .modal-content {
    max-width: unset;
    width: 100%;
    height: 100%;
  }

  @include respond-below(sm) {
    background-size: cover;
    background-image: url(../img/popup/test-auto-mob.webp);
  }
}

#modalSaleCredit {
  height: 475px;
  padding: 0;
  background-image: url(../img/popup/sale-credit.jpg);
  display: inline-flex !important;

  .modal-content {
    max-width: unset;
    width: 100%;
    height: 100%;
  }

  @include respond-below(sm) {
    background-size: cover;
    background-image: url(../img/popup/sale-credit-mob.webp);
  }
}

#modalTradeinNew {
  height: 475px;
  padding: 0;
  background-image: url(../img/popup/tradein-new.webp);
  background-size: cover;
  display: inline-flex !important;

  .modal-content {
    max-width: unset;
    width: 100%;
    height: 100%;
  }

  @include respond-below(sm) {
    background-size: cover;
    background-image: url(../img/popup/tradein-new-mob.webp);
  }
}

/*#modalCreditNew {
    .modal_credit-flex {
        display: flex;
        align-items: center;
    }
}*/
#modalPresent {
  height: 475px;
  padding: 0;
  background-image: url(../img/popup/present.jpg);
  display: inline-flex !important;

  .modal-content {
    max-width: unset;
    width: 100%;
    height: 100%;
  }

  @include respond-below(sm) {
    background-size: cover;
    background-image: url(../img/popup/present-mob.webp);
  }
}

.new_popup_form {
  width: 100%;
  padding: 196px 0 43px 31px;

  .form-inline {
    flex-direction: column;

    input {
      border: 2.30989px solid #B5B5B5;
      background: #CCCCCC;
      border-radius: 221.51px;
      height: unset;
      margin-bottom: 14px;
      padding: 8px 29px;
    }

    button {
      //   margin-top: 36px;
      background: #FFBB1D;
      /*box-shadow: 0 9.34369px 11.6796px rgba(0, 0, 0, 25%);*/
      border-radius: 308.1px;
      color: white;
      height: unset;
      font-weight: 900;
      font-size: 24.4907px;
      line-height: 24px;
      letter-spacing: 0.025em;
      width: 50%;
      padding: 16px 39px;

      @include respond-below(sm) {
        font-size: 17.474px;
        line-height: 17px;
        letter-spacing: 0.025em;
        color: #FFFFFF;
        padding: 12px 27px;
        width: 90%;
        margin-top: 159px;
      }
    }
  }

  @include respond-below(sm) {
    padding: 111px 0 18px 24px;
  }
}


#map {
  width: 100%;
  height: 400px;
  padding: 0;
  margin: 0;
}

.baloon__content {
  & h5 {
    display: none;
    font-weight: normal;
    margin-bottom: 0;

    @media (max-width: 767px) {
      display: block;
    }
  }

  & .contacts__l {
    & img {
      width: 100px;
      max-width: 100%;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.contacts__cnt {
  display: flex;

  .contacts__l {
    width: 30%;
    padding-right: 20px;
  }

  .contacts__r {
    width: 70%;
  }
}

#modalFixSale {
  height: 475px;
  padding: 40px 60px;
  background-color: #fff;
  background-image: url(../img/popup/fix-modal-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
  display: inline-flex !important;

  @media (max-width: 767px) {
    height: initial;
  }

  @include respond-below(sm) {
    padding: 16px 17px 23px;
    background-image: none;
  }

  & .special-item {
    -webkit-box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.5);
    box-shadow: 0px 0px 2px 0px rgba(50, 50, 50, 0.5);
    padding: 10px;
    margin: 0 5px;

    & .active {
      display: none;
    }

    &__title {
      white-space: nowrap;

      @media (max-width: 767px) {}
    }

    &__price {
      font-size: 18px;

      @media (max-width: 767px) {
        text-align: center;
        font-size: 14px;
      }
    }

    &__t1 {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 5px;

      @media (max-width: 767px) {

        font-size: 12px;
      }
    }

    &__t2 {
      line-height: 1;
      font-size: 12px;

      @media (max-width: 767px) {}

      @media (max-width: 576px) {
        display: none;
      }
    }

    & .link-stretched {
      line-height: 1;
      font-size: 12px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .modal {
    &-img {
      top: initial;
    }

    &-content {
      max-width: unset;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__text {
      color: #000;
    }

    &__top {
      display: flex;
      margin-bottom: 10px;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }

      & .fixParams {
        display: flex;
      }
    }

    &__title {
      margin-bottom: 10px;
      font-size: 28px;
      line-height: 1.1;

      @include respond-below(sm) {
        margin-bottom: 6px;
        font-size: 20px;
      }
    }

    &__subtitle {
      margin-bottom: 0;
      font-size: 22px;
      line-height: 1.05;
      color: #000;
      display: block;

      @include respond-below(sm) {
        margin-bottom: 12px;
        font-size: 16px;
      }
    }

    &__form {
      width: 330px;

      @media (max-width: 767px) {
        margin-bottom: 150px;
      }

      @include respond-below(sm) {
        width: 100%;
      }
    }

    &-img {
      align-items: center;

      @include respond-below(sm) {
        width: 100%;
        height: 150px;
        left: 0;
        right: unset;
        top: unset;
        display: flex;
        justify-content: center;
      }

      img {
        @include respond-below(sm) {
          height: 100%;
        }
      }
    }
  }

}

@media (max-width: 576px) {

  .swiper-slide[aria-label="1 / 5"],
  .swiper-slide[aria-label="2 / 5"],
  .swiper-slide[aria-label="3 / 5"] {
    height: 100%;
  }

  .section__slider .swiper-slide {
    height: auto;
  }

  .proposals-item.proposals-item--dark-blue {
    background-position: center;
  }


  .contacts__cnt {
    flex-direction: column;

    .contacts__l {
      width: 100%;
      padding-right: 0;
    }

    .contacts__r {
      width: 100%;
    }
  }
}

@media (max-width: 400px) {

  .swiper-slide[aria-label="1 / 5"],
  .swiper-slide[aria-label="2 / 5"],
  .swiper-slide[aria-label="3 / 5"] {
    height: auto;
  }
}

/*MODAL STYLES*/
.red {
  background: #D90303;
  color: #fff
}

.red:hover {
  background: #970101
}

.black-gradient {
  background: linear-gradient(120.04deg, #000 23.53%, #E4AE55 103.18%) !important
}

.red-gradient {
  background: linear-gradient(161.47deg, #B90101 -9.37%, #FFC76A 102.74%) !important
}

.gradient-title {
  color: #fff !important
}

.gradient-title b {
  text-transform: uppercase;
  color: #E4AE55 !important
}

.gradient-title p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  width: max-content
}

.gradient-time {
  border: 2px dashed #fff !important;
  color: #fff !important
}

.gradient-form {
  background: rgba(241, 241, 241, 0.28) !important
}

.gradient-form:hover {
  background: rgba(119, 100, 69, 0.26) !important
}

.gradient-form::-webkit-input-placeholder {
  color: rgba(241, 241, 241, 0.67) !important
}

.form-control.form-control--dark {
  background: rgba(228, 174, 85, 0.26);
  /*border-radius: 21px;*/
  color: #262020;
  //background: #fff;
  //color: #0b273f;
}

.form-control.modal__form-input-color {
  color: #FFFFFF;
}

.form-control.form-control--dark:focus {
  outline: 0;
  background: rgba(228, 174, 85, 0.26)
}

.jumbotron-content__form .form-inline .form-control.form-control--dark::-webkit-input-placeholder {
  color: rgba(75, 75, 75, .67);
  color: #fff;
}

.modal a {
  color: #E4AE55
}

.modal {
  /*padding: 48px 40px 34px;*/
  padding: 0;
  width: 100%;
  max-width: 930px;
  background: #fff;
  border-radius: 32px;
  overflow: visible
}

.modal-content .form-control {
  display: block;
  /*margin-bottom: 16px;*/
  margin-bottom: 6px;
  background: #F8EAD3;
  border-radius: 21px
}

.modal-content .form-control:hover {
  background: rgba(245, 200, 128, 0.26)
}

.modal-content .form-control::-webkit-input-placeholder {
  color: rgba(75, 75, 75, 0.67)
}

.modal__title {
  color: #E4AE55;
  display: block;
  font-size: 24px;
  line-height: 23px;
  font-weight: 700;
  margin-bottom: 24px;
}

.modal-content .text-sm {
  color: #000;
  display: block;
  margin-top: 16px;
  margin-bottom: 0;
  opacity: 1
}

.modal__title-sub {
  display: block;
  /*margin-top: 6px;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  width: max-content*/
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin: 28px 98px 38px 0;
}

.modal__title-xs {
  width: max-content;
  color: #000;
  display: block;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400
}

.modal-img img {
  border-radius: 0 20px 20px 0;
  display: block;
  z-index: 1
}

.modal__time {
  display: block;
  background: transparent;
  border: 2px dashed #333;
  color: #0b273f;
  border-radius: 16px;
  /* padding: 2px 20px; */
  padding: 2px 3px;
  /* margin-bottom: 17px;*/
  margin-bottom: 13px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  width: 157px;
}

.modal__time b {
  color: #E4AE55
}

.fancybox-slide--html .fancybox-close-small {
  color: #fff;
  padding: 8px;
  top: 8px;
  right: 9px;
  border-radius: 50%;
  opacity: 1
}

.jumbotron-content__form.__white {
  margin-top: 50px;
}

.jumbotron-content__form .form-inline .form-control.form-control--dark.placeholder {
  color: rgba(75, 75, 75, 0.67)
}

.jumbotron-content__form .form-inline .form-control.form-control--dark:-moz-placeholder {
  color: rgba(75, 75, 75, 0.67)
}

.jumbotron-content__form .form-inline .form-control.form-control--dark::-moz-placeholder {
  color: rgba(75, 75, 75, 0.67)
}

.jumbotron-content__form .form-inline .form-control.form-control--dark:-ms-input-placeholder {
  color: rgba(75, 75, 75, 0.67)
}

.jumbotron-content__form .form-inline .form-control.form-control--dark::-webkit-input-placeholder {
  //color: rgba(75, 75, 75, 0.67);

}

#modalInsurance .btn-lg {
  color: #FFFFFF;
}

#modalInsurance .modal__title {
  font-size: 34px;
  line-height: 96.69%;
}

#modalInsurance .modal__title-lg {
  font-size: 18px;
  line-height: 96.69%;
  color: #000;
}

#modalInsurance .modal__title {
  margin-bottom: 35px;
}

#modalInsurance .modal__time {
  margin-bottom: 40px;
}

#modalInsurance .form-control {
  background: #F1F1F1;
}

/* Mini banner style */
.mini-banner-model {
  background: url(../img/sale-bk.png) no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}

.container-banner {
  padding: 10px 8px 10px;
}

.mini-banner-model__title {
  font-weight: 900;
  font-size: 13.0885px;
  line-height: 16px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

.mini-banner_text-flex {
  display: flex;
  justify-content: space-between;
  font-weight: 900;
  font-size: 9.68917px;
  line-height: 12px;
  /* identical to box height */

  letter-spacing: 0.025em;
  color: #FFFFFF;
}

.mini-banner_text-flex p {
  margin-bottom: 0;
}

.banner-car-position {
  position: absolute;
  bottom: 63px;
}

// @media screen and  (min-width: 375px) {
//   .program-proposal .jumbotron-content__form-title-txt {
//     margin-top: 20px;
//   }
// } 


@media screen and (min-width: 415px) {
  .program-proposal {
    background-size: cover;
    background-position-y: center;
    width: 375px;
    margin: 0 auto;
  }

  .program-proposal .jumbotron-content__form .form-inline .form-control.form-control--dark {}
}

@media screen and (min-width: 600px) {
  .program-proposal {
    width: 414px;
  }
}

@media screen and (min-width: 768px) {
  .program-proposal .jumbotron-content__form-title-ttl {
    top: -37px;
  }

  .program-proposal-flex {
    display: flex;
  }

  .modal-img {
    display: none;
  }

  .modal-content .form-control {
    width: 403px;
    height: 53px;
    margin-bottom: 9px;
  }

  .btn-lg {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 403px;
    height: 57px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
  }

  #modalInsurance .modal__title-lg {
    font-size: 24px;
    margin-top: 10px;
  }

  #modalTradeIn .modal-content {
    background: url(/samovar/front/img/modals/rassrochka.webp) no-repeat;
    background-size: cover;
  }
}

@media screen and (min-width: 1024px) {
  .banner-car-position {
    left: 50%;
    transform: translate3d(-50%, 0px, 0px);
    width: 22%;
    bottom: 63px;
  }

  .program-proposal-title {
    align-items: flex-start;
  }

  .program-proposal-title__percent {
    background: #FFF;
    border-radius: 12.9075px;
    padding: 10px 10px;
  }

  .program-proposal {
    color: #0B273F;
  }

  .program-proposal-flex {
    display: flex;
  }

  .program-proposal-title .flex {
    display: flex;
    align-items: center;

  }

  .program-proposal-title .h2 {
    font-weight: 900;
    font-size: 41.0819px;
    line-height: 50px;
    letter-spacing: 0.025em;
    text-align: left;
  }

  .program-proposal {
    background: url(/samovar/front/img/offer-new-desk.png) no-repeat;
    background-size: contain;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 36px 40px 40px;
    border-radius: 47px;
  }

  .program-proposal .jumbotron-content__form {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    margin: 0;
    position: static;
  }

  .program-proposal .jumbotron-content__form .form-inline {
    flex-flow: column;
  }

  .program-proposal .jumbotron-content__form .form-inline {
    padding-bottom: 0;
  }

  .program-proposal .jumbotron-content__form .form-inline .btn {
    right: 0;
    bottom: 37px;
  }

  .program-proposal .jumbotron-content__form-title-ttl {
    top: auto;
    bottom: 26px;
    left: 0;
  }

  #modalBook .modal-content {
    background: url(/samovar/front/img/modals/bron-desk.png) no-repeat;
    padding: 47px 35px 35px 70px;
    background-position-x: right;
  }

  #modalAutoCredit .modal-content,
  #modalCredit .modal-content {
    background: url(/samovar/front/img/modals/credit-new-desk.png) no-repeat;
    padding: 47px 35px 35px 70px;
    background-position-x: right;
  }

  .modal__time {
    width: 401px;
  }

  .modal-img {
    display: none;
  }

  .text-sm {
    width: 50%;
  }

  .modal__title {
    font-size: 36px;
    line-height: 35px;
    width: 45%;
  }

  .modal__title-sub {
    margin: 4px 0 21px 0;
  }

  .modal .modal__title-xs {
    font-size: 14px;
    line-height: 16px;
    width: 100%;
  }

  #modalCredit .modal__title {
    font-size: 48px;
    line-height: 42px;
    width: 61%;
    margin-bottom: 44px;
  }

  #modalInsurance .modal-content {
    background: url(/samovar/front/img/modals/kasko-desk.png) no-repeat;
    background-size: contain;
    background-position: right;
    padding: 47px 30px 35px 57px;
    border-radius: 32px;
  }

  #modalCreditNew .modal-content {
    background: url(/samovar/front/img/modals/end-desk.png) no-repeat;
    background-size: contain;
    background-position: right;
    padding: 47px 30px 35px 57px;
  }

  #modalCreditNew {
    max-width: 1005px;
  }

  #modalInsurance .modal__title {
    font-size: 48px;
    line-height: 96.69%;
    width: 48%;
  }

  #modalCreditNew .modal__title {
    line-height: 96.69%;
    width: 59%;
  }

  .modal_credit-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  #modalCreditNew .modal__title-xs {
    flex-basis: 61%;
  }

  #modalCreditNew .modal__time {
    width: 175px;
  }

  #modalTradeIn .modal-content {
    background: url(/samovar/front/img/modals/rassrochka-desk.png) no-repeat;
    background-size: contain;
    background-position: right;
    padding: 47px 30px 35px 57px;
  }

  #modalInstallment .modal-content {
    background: url(/samovar/front/img/modals/rassrochka2-desk.png) no-repeat;
    padding: 28px 28px 35px 70px;
    background-position-x: right;
  }
}

@media screen and (min-width: 1280px) {
  .banner-car-position {
    min-width: 453px;
    width: 554px;
    height: auto;
    bottom: 0;
  }

  .banner-car-position img {
    width: 100%;
    height: 100%;
  }

  .mini-banner-model {
    background: url(../img/sale-bk-desk.png) no-repeat;
    background-size: cover;
    background-position: center;
  }

  .container-banner {
    padding: 15px 19px 20px;
  }

  .mini-banner__img-position {
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0px, 0px);
    top: 0;
    height: 100%;
  }

  .mini-banner-model__title {
    font-size: 29.5289px;
    line-height: 30px;
    margin-bottom: 50px;
  }

  .mini-banner_text-flex {
    font-size: 22.1665px;
    line-height: 22px;
  }

  .program-proposal {
    background-size: cover;
    padding: 36px 40px 117px;
  }

  .jumbotron-content__form-title {
    margin-bottom: 26px;
  }

  .program-proposal .jumbotron-content__form-title-txt {
    display: flex;
    font-size: 20.3119px;
    line-height: 25px;
    margin-bottom: 0;
  }

  .program-proposal-title {
    flex-flow: column;
    gap: 59px;
  }

  .program-proposal-title__percent {
    margin-right: 15px;
  }

  .program-proposal .jumbotron-content__form-title-ttl {
    bottom: 56px;
    font-size: 22.8161px;
    line-height: 28px;
    padding: 11px 10px 11px 5px;
  }

  .program-proposal .jumbotron-content__form-title-txt b {
    font-size: 39.161px;
    line-height: 48px;
    border-radius: 14.2588px;
    padding: 0 11px;
  }

  .program-proposal .jumbotron-content__form .form-inline .form-control.form-control--dark {
    margin-bottom: 24px;
  }

  .program-proposal .jumbotron-content__form .form-inline .btn {
    right: 40px;
    bottom: 37px;
    width: 358px;
    border-radius: 16px;
    height: 55px;
    font-size: 16.6828px;
    line-height: 20px;
  }

  .program-proposal-title__percent i {
    font-weight: 900;
  }

  .program-proposal .jumbotron-content__form-title-ttl b {
    font-weight: 900;
    font-size: 22.8161px;
    line-height: 28px;
    padding: 11px 10px 11px 5px;
  }
}

@media screen and (min-width: 1440px) {
  .program-proposal {
    padding: 36px 40px 205px;
  }
}

@media screen and (min-width: 1600px) {
  .form-inline .form-control__field {
    width: 309px;
    width: 213px;
  }

  .program-proposal .form-control__field {
    width: 309px;
  }

  .program-proposal .jumbotron-content__form .form-inline .form-control.form-control--dark {
    height: 42px;
    margin-bottom: 0;
  }

  .program-proposal {
    padding: 36px 40px 141px;
  }

  .program-proposal .jumbotron-content__form-title-ttl {
    bottom: 46px;
    padding-left: 40px;
  }

  .program-proposal-title__subtitle {
    font-size: 17.8011px;
    line-height: 22px;
  }

  .program-proposal-flex {
    align-items: center;
  }

  // .program-proposal .jumbotron-content__form-title-txt {
  //   margin-right: 55px;
  // }
}

.titli-model__uppercase {
  text-transform: uppercase;
}

.form-inline {
  gap: 10px;

  @media (min-width: 1440px) {
    gap: 14px;
  }
}

.content-line__position {
  background: url(../img/content-line.png)no-repeat;
  width: 970px;
  height: 73px;
  position: absolute;
  z-index: 1;
  padding: 15px 65px 0 0;
  top: 26px;
}

.content-line__position-text {
  font-weight: 900;
  font-size: 32px;
  line-height: 39px;
  text-transform: uppercase;
  color: #E4AE55;
  margin-top: 43px;
  position: relative;
  z-index: 11;
}

.jumbotron.jumbotron--model .jumbotron-img:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 44%;
  bottom: 0;
  background: url(../img/bg.png) no-repeat right center;
  background-size: 100% 100%;
}

.desk-none {
  display: none;
}



.jumbotron .text-xl {
  padding: 10px 0 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  max-width: 550px;
}

.jumbotron-content__percent.__yellow {
  display: grid;
}

.jumbotron .jumbotron-content__percent-txt {
  padding-left: 0;
  font-weight: 700;
  font-size: 31px;
  line-height: 38px;
}

.jumbotron--first__number-desktop {
  font-weight: 800;
  font-size: 31px;
  line-height: 31px;
}

.jumbotron--first__number-value,
.jumbotron--family__number-value {
  margin-bottom: 53px;
}

.jumbotron-content__form-title-txt {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.form-control.form-control--dark {
  color: #FFF;
}

.jumbotron .jumbotron-img img {
  width: 100%;
}

.jumbotron-content__form .form-inline .form-control__field .form-control--dark::-ms-input-placeholder {
  color: #FFF;
}

.red-discount {
  background: url(../img/home-slider/slide-decor-red.svg) no-repeat center;
  background-size: contain;
  width: 64px;
  height: 64px;
  position: absolute;
  color: #fff;
  right: 0;
  top: 30%;
  margin-top: -32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 64px;
  text-align: center;
}

.input::placeholder {
  color: #000000;
}


.jumbotron-content .h1 span {
  display: block;
  color: #FFF;
  font-size: 20px;
  line-height: 24px;
  padding: 0;
}

.jumbotron-content .h1 b {
  display: block;
  text-transform: uppercase;
  font-weight: 800;
}

.jumbotron.jumbotron--first .jumbotron-content__percent-txt {
  font-weight: 700;
}

.jumbotron__slider {
  aspect-ratio: 1920/775;
}


.jumbotron.jumbotron--model .text-xl.brands-banner__text {
  font-weight: 800;
  font-size: 32px;
  line-height: 32px;
  color: #FFFFFF;

  @media (max-width: 1024px) {
    font-size: 18px;
    line-height: 22px;
    color: #0B273F;
    color: #0b273f;
    margin: 5px 0 15px;
    color: #000;
  }

}

.jumbotron--model__info-mob span.no_absolute {
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 18px;
    color: #0B273F;
    margin: 0;
    padding: 0;
  }
}

.jumbotron-content__form__white-date {
  font-weight: 700;
  font-size: 28px;
  line-height: 25px;
  color: #FFFFFF;
  margin-bottom: 15px;
  display: inline-block;

  @media (max-width: 1024px) {
    color: #0B273F;
  }
}

.jumbotron-content__form-flex {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    justify-content: space-between;
  }
}

.jumbotron.jumbotron--model .jumbotron-content {
  @media (max-width: 1024px) {
    padding: 20px 0 24px;
  }
}

.form-control__field-new {
  width: 280px;

  @media (max-width: 590px) {
    width: 100%;
  }

  input {
    @media (max-width: 590px) {
      width: 100%;
    }
  }
}

.jumbotron-content__form-flex-btn {
  width: 280px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  background: #E4AE55;
  border-radius: 10px;
  height: 50px;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

#modalThanks {
  padding: 20px;
}

.model-form-control {
  width: 280px;
  height: 50px;
  background: #E0E5EB;
  border-radius: 10px;

  color: #E4AE55;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.model-form-control::placeholder {
  color: #E4AE55;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.jumbotron--model__info-mob-title {
  font-size: 38px;
  line-height: 38px;
  color: #0B273F;

}

.white-date_color {
  color: #FECC45;
}

.text-color-mob {
  @media (max-width: 1024px) {
    color: #0B273F;
  }

  a {
    @media (max-width: 1024px) {
      color: #0B273F;
    }
  }
}

section.jumbotron.jumbotron--model {
  @media (max-width: 1024px) {
    padding-top: 540px;
  }

  @media (max-width: 630px) {
    padding-top: 0;
  }
}

.jumbotron.jumbotron--model div.jumbotron-img {
  @media (max-width: 1024px) {
    margin-top: 0;
  }
}

.jumbotron.jumbotron--model {
  @media (max-width: 1024px) {
    border-radius: 0;
  }
}

div.jumbotron-img {
  @media (max-width: 1024px) {
    border-radius: 0;
  }
}

.jumbotron-content__form {
  margin-top: 246px;

  @media (max-width: 1024px) {
    margin-top: 20px;
  }
}

.jumbotron--model__info-mob {
  display: none;

  @media (max-width: 1024px) {
    display: block;

    margin-top: -536px;
  }

  @media (max-width: 630px) {
    margin-top: -332px;
  }
}


@media (max-width: 1460px) {
  .jumbotron--family__number {
    right: 10%;
  }
}

@media (max-width: 1266px) {
  .brand-cars__item-discount {
    top: auto;
    bottom: 0;
  }
}

@media screen and (max-width: 1060px) {
  .content-line__position {
    width: 570px;
    background-size: contain;
  }

  .content-line__position-text {
    font-size: 22px;
    line-height: 17px;
    margin-top: 40px;
  }

  .jumbotron.jumbotron--model .jumbotron-img:before {
    display: none;
  }

}

@media (max-width: 1023px) {
  .jumbotron.jumbotron--slider .jumbotron__slider {
    height: auto;
    aspect-ratio: 113/112;
  }

  .jumbotron.jumbotron--model .jumbotron-img {
    height: 405px;
  }

  .jumbotron.jumbotron--model {
    padding-top: 357px;
  }

  .content-line__position {
    width: 350px;
  }

  .content-line__position-text {
    position: absolute;
    top: -325px;
    margin-top: 0;
    font-size: 14px;
  }

  .mob-none {
    display: none;
  }

  desk-none {
    display: block;
  }

  .jumbotron--first .jumbotron-img img {
    width: 100%;
  }

  .jumbotron--first .jumbotron-content__tag,
  .jumbotron-content .h1 span {
    position: absolute;
    top: -215px;
    width: 202px;
    height: 21px;
    background: rgba(255, 255, 255, 0.12);
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.no_absolute {
      position: static;
      border: none;
      background: none;
      display: block;
      color: #000;
    }
  }

  .jumbotron--first__number-value {
    font-size: 69.4241px;
    line-height: 69px;
    margin-bottom: 10px;
  }

  .jumbotron--first__number-desktop {
    font-size: 12.6597px;
    line-height: 13px;
  }

  .jumbotron.jumbotron--first .jumbotron-content__form-title-txt,
  .jumbotron.jumbotron--family .jumbotron-content__form-title-txt {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .jumbotron-content__percent.__yellow {
    margin-bottom: 5px;
  }

  .jumbotron.jumbotron--family .text-xl,
  .jumbotron.jumbotron--first .text-xl {
    padding: 10px 0;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    color: #FFF;
    opacity: 1;
  }
}

@media (max-width: 758px) {

  .jumbotron--first .jumbotron-content__tag,
  .jumbotron-content .h1 span {
    top: -168px;
  }

  .jumbotron--first__number {
    text-align: right;
    padding-right: 19px;
  }

  .jumbotron--family__number-value {
    margin-bottom: 0;
  }

  .jumbotron--family__number {
    right: 26px;
    top: auto;
    text-align: right;
  }

  .brand-cars__item-discount {
    width: 160px;
    height: 31px;
    left: auto;
    right: 0;
    bottom: auto;
    top: 54px;
    font-size: 13px;
    line-height: 16px;
  }
}

@media (max-width: 658px) {
  .content-line__position {
    width: 323px;
    height: 25px;
    top: 25px;
    padding: 0;
  }

  .content-line__position-text {
    position: absolute;
    top: -173px;
    left: 55px;
    margin-top: 0;
    font-size: 14px;
    font-size: 10px;
    line-height: 10px;
  }

  .jumbotron.jumbotron--model .jumbotron-img {
    height: unset;
    aspect-ratio: 400/143;
    background-position: top;

    &_model {
      height: 205px;
      aspect-ratio: unset;
    }
  }

  .jumbotron.jumbotron--model {
    padding-top: 205px;
  }

  .jumbotron--model .list-styled li {
    font-size: 15px;
    line-height: 21px;
  }

  .jumbotron--model .list-styled {
    display: grid;
    grid-row-gap: 5px;
  }

  .jumbotron.jumbotron--family .jumbotron-content__form-title-txt,
  .jumbotron.jumbotron--first .jumbotron-content__form-title-txt {
    font-weight: 400;
  }

  .jumbotron-content__form-title-txt b {
    font-weight: 400;
  }
}

@media (max-width: 450px) {
  .catalog-item__slider .swiper-pagination {
    height: 3px;
  }

  .jumbotron.jumbotron--model .h1 {
    font-size: 24.3969px;
    line-height: 28px;
  }

  .titli-model__uppercase {
    font-size: 24.3969px;
    line-height: 28px;
    font-weight: 800;
  }

  .jumbotron--model .list-styled {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .jumbotron--model .list-styled li {
    padding-left: 22px;
  }

  .list-styled li.__gift:before,
  .list-styled li.__like:before,
  .list-styled li.__percent:before,
  .list-styled li.__time:before {
    width: 16px;
    height: 16px;
  }

  .jumbotron-content__form .text-sm {
    text-align: left;
  }

  .jumbotron.jumbotron--first .text-xl,
  .jumbotron.jumbotron--family .text-xl {
    font-size: 10px;
    line-height: 12px;
  }

  .jumbotron.jumbotron--first .jumbotron-content__percent-txt {
    font-size: 12px;
    line-height: 15px;
  }

  .jumbotron--first {
    padding-top: 201px;
  }

  .jumbotron--first .jumbotron-content__tag {
    top: -187px;
  }

  .jumbotron--first .jumbotron-img {
    height: 205px;
  }

  .jumbotron--first .jumbotron-img img {
    object-position: right;
  }

  .jumbotron--first__number-value {
    margin-bottom: 0;
  }

  .jumbotron .jumbotron-content__percent-txt {
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
  }

  .jumbotron--family__number-value {
    font-size: 69.4241px;
    line-height: 69px;
  }

  .jumbotron--family__number-desktop {
    font-size: 12.6597px;
    line-height: 13px;
    font-weight: 800;
  }

  .jumbotron-content .h1 span {
    top: auto;
    bottom: 76px;
    width: auto;
    padding: 0 12px;
  }

  .jumbotron-content .h1 b {
    display: block;
    text-transform: uppercase;
  }

  .custom-text {
    right: 0;
  }

  .jumbotron--family__number {
    right: 52px;
  }

  .jumbotron.jumbotron--first .jumbotron-content__percent-txt {
    font-weight: 700;
  }

  .jumbotron.jumbotron--family .jumbotron-content__form-title-txt,
  .jumbotron.jumbotron--first .jumbotron-content__form-title-txt {
    font-size: 12px;
    line-height: 15px;
  }

  .brand-cars__item-discount {
    right: -68px;
    top: 37px;
    width: 143px;
  }

  .jumbotron-img.__ll-bg.mob-hidden {
    display: none;
  }

  .jumbotron--family .jumbotron-content .h1 span {
    bottom: 185px;
  }
}

@media (max-width: 360px) {
  .jumbotron--first .jumbotron-content__tag {
    width: 165px;
  }
}

@media (min-width: 451px) {
  .jumbotron-img.desk-hidden {
    display: none;
  }
}


.modal._NYp {
  border-radius: 55px;

  @include respond-below(md) {
    width: 363px !important;
    height: 475px !important;
    border-radius: 60px;
  }
}

.modal-content._NY {
  padding: 35px 66px 37px 56px !important;
  width: 930px !important;
  height: 475px;
  background-image: url(../img/modals/ny-popup-bg.webp) !important;
  background-size: cover !important;

  @include respond-below(md) {
    background-image: url(../img/modals/ny-popup-bg-mob.webp) !important;
    width: 363px !important;
    height: 475px !important;
    padding: 22px 15px 9px !important;
    border-radius: 60px !important;
  }


  .modal__title {
    font-weight: 800;
    width: 100% !important;
    font-size: 40px !important;
    line-height: 110% !important;
    text-transform: uppercase;
    color: #0b273f;
    text-align: center;
    margin-bottom: 3px !important;

    @include respond-below(md) {
      font-size: 20px !important;
      margin-bottom: 17px !important;
    }
  }

  .modal__subtitle {
    font-weight: 700;
    font-size: 32px;
    line-height: 193%;
    text-align: right;
    color: #e4ae55;
    display: block;

    @include respond-below(md) {
      text-shadow: -9px 0px 5px rgba(133, 156, 181, 0.5);
      font-size: 16px;
      line-height: 100%;
    }
  }

  .modal__gift {
    font-weight: 700;
    font-size: 24px;
    line-height: 193%;
    text-align: right;
    color: #0B273F;
    display: block;
    margin-top: -15px;

    @include respond-below(md) {
      font-size: 12px;
      line-height: 193%;
      margin-top: 0;
    }
  }

  form {
    width: 326px;
    background: rgba(169, 199, 230, 0.5);
    border-radius: 15px;
    padding: 13px 15px 9px;
    display: flex;
    flex-direction: column;
    gap: 9px;

    @include respond-below(md) {
      margin: 150px auto 0;
      width: 310px;
    }

    input {
      width: 100%;
      background: #F5F5F5;
      border: 1px solid #2D6DAD;
      border-radius: 41px;
      height: 45px;
      color: #2D6DAD;
      margin: 0;

      &::placeholder {
        color: #2D6DAD;
      }
    }

    label {
      display: flex;
      gap: 7px;
      margin: 8px 0;
      align-items: center;

      @include respond-below(md) {
        margin: 0;
      }

      input[type="checkbox"] {
        display: none;
      }

      input[type="checkbox"]:checked+span::after {
        content: "";
        display: block;
        position: static;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #E4AE55;
      }

      span {
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #CDCDCD;
      }

      p {
        margin: 0;
        font-weight: 400;
        font-size: 11px;
        line-height: 130%;
        color: #0B273F;

        @include respond-below(md) {
          font-size: 9px;
          line-height: 95%;
        }
      }
    }

    .btn {
      width: 100%;
      background: #E4AE55;
      border-radius: 20px;
      height: 48px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;
      color: #0B273F;
    }

    .modal__agreement {
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      color: #2D6DAD;
      margin-top: 8px;

      @include respond-below(md) {
        font-size: 6px;
        color: #B5B5B5;
        margin-top: 0;
      }

      a {
        color: inherit;
      }
    }
  }
}

.modal-content._NY+.fancybox-close-small {
  top: 0;
  right: -50px;
  background: #fff;
  color: #0B273F;

  @include respond-below(md) {
    right: 0;
    top: -50px;
  }
}

.brand-text__mob-hide {
  @media (max-width: 1024px) {
    color: #000;
  }
}

// .brands-banner__text.brand-text__mob-hide {
//   @media (max-width: 1024px) {
//     display: none;
//   }
// }

section.jumbotron--model.jumbotron--model__car {
  @media (max-width: 1024px) {
    padding-top: 0;
  }

  @media (max-width: 1023px) {
    border-radius: 24px;
    margin: 0 8px;
    width: calc(100% - 16px);
    padding-top: 357px;
  }

  @media (max-width: 630px) {
    padding-top: 205px;
  }

  div.jumbotron-img {
    @media (max-width: 1024px) {
      margin-top: 0;
    }
  }

  .container {
    @media (max-width: 1024px) {
      margin: 0;
      width: 100%;
    }
  }

  .jumbotron-content__form.__white {

    margin-bottom: 51px;

    @media (max-width: 1024px) {
      margin: 0;
    }
  }

  .jumbotron-content {
    width: 733px;

    @media (max-width: 1023px) {
      width: 100%;
      padding: 8px 16px 16px;
      background: #32455d;
    }
  }
}

#modalCredit .form-control.form-control--dark,
#modalBook .form-control.form-control--dark,
#modalAutoCredit .form-control.form-control--dark {
  color: #262020;
}

#modalThanksAlready {
  padding: 25px;

  .modal__title {
    @media (max-width: 567px) {
      font-size: 21px;
      line-height: 23px;
    }
  }
}

#modalFamily input {
  margin-bottom: 0;
}

.catalog-slider+.section__slider-pagination {
  display: none;
}

.info-slider+.section__slider-pagination {
  display: none;
}

.partners-slider_box__pagination {
  font-size: 20px;
  font-weight: 700;
}

.partners-slider_box__pagination .swiper-pagination-fraction {
  justify-content: center;
}

.partners-slider_box__pagination .swiper-pagination-fraction .swiper-pagination-current {
  color: #E4AE55;
}

@media (max-width: 567px) {
  .catalog-slider+.section__slider-pagination {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .info-slider+.section__slider-pagination {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .info-traid-slider+.section__slider-pagination {
    display: flex;
    position: relative;
    justify-content: center;
  }

  .partners-slider_box__pagination {
    display: flex;
    position: relative;
    justify-content: center;
    width: 25%;
    margin: 20px auto auto auto;
  }

  .section__slider .partners-slider__prev {
    margin-top: 10px;
    display: flex;
    right: 70px;
    left: 70px;
    width: 30px;
    height: 30px;
    background: #e4ae55;
    color: #0b273f;
  }

  .section__slider .partners-slider__next {
    margin-top: 10px;
    display: flex;
    right: 70px;
    width: 30px;
    height: 30px;
    background: #e4ae55;
    color: #0b273f;
  }
}

/***************************/
/* ВИДЖЕТ ОЦЕНКИ СТОИМОСТИ */

.open-valuation-widget {
  position: fixed;
  z-index: 99;
  left: 90px;
  bottom: 90px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 0;
}

.open-valuation-widget_red {
  left: 250px;
}

.open-valuation-widget img._outer {
  width: 100%;
  height: 100%;
  display: block;
  animation: pulse 7s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  80% {
    transform: scale(1);
  }

  85% {
    transform: scale(1.15)
  }

  90% {
    transform: scale(1)
  }

  95% {
    transform: scale(1.15)
  }

  100% {
    transform: scale(1)
  }
}

.open-valuation-widget img._inner {
  width: 66%;
  height: 66%;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.valuation-widget {
  width: 600px;
  height: 450px;
  border-radius: 15px;
  background: #fff;
  color: #333;
  overflow: hidden;
  display: none;
  padding: 0;
  position: relative;
}

.valuation-widget__loader {
  display: none;
}

.valuation-widget.loading .valuation-widget__loader {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);
}

.valuation-widget__loader img {
  width: 60px;
}

.valuation-widget__content {
  display: none;
}

#valuation-widget__step-gosnomer .valuation-widget__form {
  height: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.valuation-widget__content.active {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.valuation-widget__heading {
  font-size: 28px;
  font-weight: 700;
  line-height: 1.15;
}

#valuation-widget__step-gosnomer .valuation-widget__heading {
  margin-top: 15px;
}

.valuation-widget__heading span {
  color: #FFB800;
  display: block;
}

.valuation-widget__input-hint {
  font-size: 16px;
}

.valuation-widget__input-field {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.valuation-widget__input {
  height: 60px;
  border: 0;
  border-bottom: 2px solid #FFB800;
  padding: 0 20px;
  font-size: 24px;
  width: 100%;
  max-width: 415px;
}

.valuation-widget__input::placeholder {
  color: #E0E0E0;
}

.validation-text {
  position: absolute;
  font-size: 10px;
  bottom: 0;
  margin: 0;
  line-height: 1;
  left: 2px;
  color: #d5001c;

  opacity: 0;
  transition: all ease .3s;
}

.validation-text.active {
  bottom: -15px;
  opacity: 1;
}

.valuation-widget__footer {
  padding: 20px;
  margin: 0 -20px -20px;
  width: calc(100% + 40px);
  background: #F5F5F5;
}

.valuation-widget__button {
  /* height: 55px; */
  width: 275px;
  /* border-radius: 40px; */
  /* background: #FFB800; */
  font-size: 16px;
  font-weight: 700;
  margin-left: auto;
  text-transform: uppercase;
  /* color: #fff; */
  display: block;
}

.valuation-widget__return {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #BDBDBD;
  font-size: 16px;
  font-weight: 700;
}

.valuation-widget__specs {
  position: relative;
}

.valuation-widget__specs-title {
  position: absolute;
  left: 0;
  top: 16px;
  background: #0B273F;
  padding: 3px 15px;
  font-size: 20px;
  color: #F2F2F2;
  font-weight: 700;

  width: 100%;

  z-index: 2;
}

.valuation-widget__specs-grid {
  padding: 45px 30px 10px;
  background: #F5F5F5;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(2, 34px);
  align-items: center;
  gap: 0 30px;

  position: relative;
  z-index: 1;
}

.valuation-widget__specs-grid::before {
  content: "";

  display: block;

  width: calc(100% + 40px);
  height: 100%;

  background: #F5F5F5;

  position: absolute;
  top: 0;
  left: -20px;
  z-index: -1;
}

.valuation-widget__specs-grid li {
  color: #828282;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.valuation-widget__specs-grid li span {
  color: #333;
  font-weight: 700;
}

.valuation-widget__subheading {
  font-size: 16px;
  font-weight: 700;
}

.valuation-widget__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.valuation-widget__form .form-row {
  display: flex;
  align-items: center;
  gap: 10px;
}


.valuation-widget__input-label {
  font-size: 10px;
  line-height: 10px;
  color: #AAAAAA;
  padding: 0 0 0 10px;
}

.valuation-widget__input-container .form-control {
  background: none;

  padding: 0 0 0 10px;
  font-size: 16px;
}

.valuation-widget__input-container .form-control.__error {
  background: none !important;
}

.valuation-widget__input-container .form-control.__error::placeholder {
  color: #ff5b62 !important;
}

.valuation-widget .btn-primary {
  flex-basis: 48%;
}

.valuation-widget__form .form-row .valuation-widget__input {
  height: 40px;
  font-size: 16px;
  padding: 0;
}

.agreement {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.agreement input {
  display: block;
  position: absolute;
  top: 16px;
  left: -4px;
  width: 10px;
  height: 10px;
  opacity: 0;
}

.agreement input+span .custom-check {
  width: 10px;
  height: 10px;

  border: 1px solid #262626;

  display: block;
}

.agreement input:checked+span .custom-check {
  background-image: url(../img/widget-checkbox.svg);
  background-size: cover;
}

.agreement p {
  font-size: 12px;
  line-height: 1.2;
  // max-width: 100%;
  max-width: 450px;
}

.agreement p a {
  text-decoration: underline;
}

.jumbotron-content .agreement p,
.jumbotron-content .agreement p a {
  color: black;
}

.valuation-widget__warn-img {
  height: 100px;
  display: block;
  margin: 0 auto;
}

#valuation-widget__step-final {
  justify-content: center;
  align-items: center;
  gap: 50px;
}

#valuation-widget__step-final .valuation-widget__heading {
  font-size: 20px;
  text-align: center;
  margin: 0;
}

html .anchorSticky.activated {
  display: none !important;
}

.valuation-widget__input-container {
  flex-basis: 48%;
  height: 55px;
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 620px) {
  #open-valuation-widget {
    left: auto;
    right: 30px;
    bottom: 50px;
    width: 70px;
    height: 70px;
  }

  #valuation-widget {
    width: 100%;
    max-width: 360px;
    height: auto;
  }

  .valuation-widget__content.active {
    padding: 15px;
    gap: 15px;
    min-height: 300px;
  }

  .valuation-widget__heading {
    font-size: 20px;
  }

  #valuation-widget__step-gosnomer .valuation-widget__heading {
    margin-top: 5px;
  }

  .valuation-widget__input-hint {
    font-size: 14px;
  }

  .valuation-widget__input {
    height: 44px;
    padding: 0 15px;
    font-size: 16px;
  }

  .valuation-widget__footer {
    margin: 0 -15px -15px;
    width: calc(100% + 30px);
    padding: 20px 15px;
  }

  .valuation-widget__button {
    width: 100%;
  }

  .valuation-widget__return {
    font-size: 12px;
  }

  .valuation-widget__specs {
    margin-top: 12px;
  }

  .valuation-widget__specs-title {
    padding: 10px;
    font-size: 14px;

    top: 0;
  }

  .valuation-widget__specs-grid {
    /* padding: 26px 15px 12px; */
    padding: 45px 15px 10px;
    grid-template-rows: repeat(3, 30px);
    gap: 0 18px;
    margin: 0;
  }

  .valuation-widget__specs-grid li {
    font-size: 10px;
  }

  .valuation-widget__subheading {
    font-size: 14px;
  }

  .valuation-widget__form .form-row {
    flex-direction: column;
    gap: 10px;
  }

  .valuation-widget__input-container {
    width: 100%;
  }

  #valuation-widget__step-final .valuation-widget__heading {
    font-size: 14px;
  }

  .valuation-widget__warn-img {
    height: 80px;
  }
}

.agreement-white {
  color: white;
}

.agreement-sale {
  max-width: 100%;
}

@media screen and (min-width: 1024px) {
  .jumbotron-content .agreement p, 
  .jumbotron-content .agreement p a {
    color: white;
} 
}