// Typical
//
//___________________________________

html {
    height: 100%;
}

body {
    font-family: $base-font;
    color: $base-color;
    background: $base-bg;
    min-width: $body-min-width;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    height: 100%;
    font-size: 16px;
    line-height: 1.5;

    @include respond-above(md) {
        &.active-header-menu {
            overflow: hidden;
        }
    }

    @include respond-below(md) {
        font-size: 14px;
        line-height: 1.42;

        &.active-header-menu {
            overflow: hidden;

            .wrapper {
                height: 100vh;
                overflow: hidden;
            }
        }
    }
}

.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    width: 100%;
    position: relative;
    flex: 1 0 auto;
    padding-top: 162px;

    @include respond-below(xl) {
        padding-top: 135px;
    }
    @include respond-below(lg) {
        padding-top: 94px;
    }
}

.header,
.footer {
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    z-index: 1;
}

.container,
.container-fluid {
    width: 100%;
    max-width: $container-max-widths;
    margin: 0 auto;
    padding: 0 60px;
    position: relative;
    z-index: 2;
    &.withFavorite {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .equp__favorite {
            margin: 0;
            width: 30%;
            @media (max-width: 767px) {
                display: none;
            }
            > div {
                float: right;
                text-align: center;
            }
        }
        & .breadcrumbs {
            width: 70%;
            margin: 0;
            @media (max-width: 767px) {
                width: 100%;
                display: flex;
                align-items: baseline;

                & .breadcrumbs__back {
                    display: block;
                    height: 12px;
                    padding: 0;
                    & .breadcrumbs__back-txt {
                        display: none;
                    }
                }
                & .breadcrumbs__list {
                    display: block;
                    li {
                        display: inline-block;
                    }
                }
            }
        }
    }

    @include respond-below(xxl) {
        max-width: 1604px;
        padding-left: 50px;
        padding-right: 50px;
    }
    @include respond-below(xl) {
        padding-left: 30px;
        padding-right: 30px;
    }
    @include respond-below(md) {
        padding-left: 25px;
        padding-right: 25px;
    }
}

.container-fluid {
    max-width: 100%;
}

.container {
    &.container-zi {
        z-index: 3;
    }

    &.container-decor-t {
        margin-top: 46px;

        &:before {
            @include pseudo();
            width: 500%;
            left: 50%;
            transform: translateX(-50%);
            background: #fafafa;
            top: 30px;
            bottom: 48px;
        }
    }

    &.container-decor-b {
        margin-bottom: 46px;

        &:before {
            @include pseudo();
            width: 500%;
            left: 50%;
            transform: translateX(-50%);
            background: #fafafa;
            top: 143px;
            bottom: -106px;
            z-index: -1;
        }
    }

    @include respond-below(lg) {
        &.container-decor-t {
            &.container-decor-t-lg {
                &:before {
                    display: none;
                }
            }
        }
        &.container-decor-b {
            margin-bottom: 0;

            &:before {
                display: none;
            }
        }
    }
}

.section {
    position: relative;
    width: 100%;
    padding: 80px 0;

    &.section--used {
        padding: 24px 0;
        z-index: 1;

        &:before {
            @include pseudo();
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            background: #fafafa;
        }
    }

    &.section-reverse {
        & + .section {
            padding-top: 0;
        }
    }

    .container + .container {
        padding-top: 78px;
    }

    &__slider {
        position: relative;

        .swiper-container {
            padding: 24px 16px;
            margin: -24px -16px;
        }

        .swiper-slide {
            height: auto;
        }

        .swiper-button-prev {
            left: -36px;
        }

        .swiper-button-next {
            right: -36px;
        }

        &-pagination {
            bottom: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: 0;

            .swiper-pagination {
                bottom: auto;
                position: relative;
                top: auto;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 48px;

                .swiper-pagination-bullet {
                    margin: 0 4px;
                }
            }

            .btn {
                margin-left: 30px;
            }
        }

        &.section__slider--video {
            .swiper-button-prev,
            .swiper-button-next {
                top: 140px;
            }
        }
    }

    &__title {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &.section__title--search {
            margin-bottom: 40px;
        }

        &.section__title-centered {
            align-items: center;
            text-align: center;
        }

        &.section__title-between {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            @include respond-below(lg) {
                flex-direction: column;
                align-items: unset;

                .section__title-links {
                    display: flex;
                    margin: 10px -4px;

                    .btn {
                        margin: 0 4px;
                        flex-grow: 1;
                    }
                }
            }
            @include respond-below(sm) {
                .section__title-links {
                    flex-direction: column;

                    .btn {
                        margin: 4px;
                    }
                }
            }
        }

        &-line {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;

            .social-links {
                margin-left: 13px;
            }
        }

        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            margin: 0;
        }

        h5,
        .h5 {
            font-weight: 400;
        }

        & * + h5,
        & * + .h5 {
            margin-top: 8px;
        }

        &--decor {
            h1,
            .h1,
            h2,
            .h2,
            h3,
            .h3 {
                position: relative;
                padding-right: 55px;

                &:after {
                    @include pseudo();
                    height: 4px;
                    background: $primary;
                    border-radius: 4px;
                    width: 32px;
                    right: 0;
                    top: 50%;
                    margin-top: -2px;
                }
            }
        }
    }

    &__btn {
        text-align: center;
        margin-top: 29px;
    }

    &.section--search {
        padding-top: 56px;
    }

    @include respond-below(lg) {
        &__slider {
            .swiper-container {
                overflow: visible;
            }

            .swiper-button-prev {
                display: none;
            }

            .swiper-button-next {
                display: none;
            }

            &-pagination {
                margin-bottom: -7px;
            }
        }
    }

    @include respond-below(md) {
        padding: 40px 0;

        .container + .container {
            padding-top: 40px;
        }
        &.section-reverse {
            display: flex;
            flex-direction: column-reverse;

            .container + .container {
                padding-top: 0;
                padding-bottom: 40px;
            }
        }
        &__title {
            margin-bottom: 26px;

            & * + h5,
            & * + .h5 {
                margin-top: 4px;
            }

            &.section__title--search {
                margin-bottom: 23px;
            }

            &--decor {
                h1,
                .h1,
                h2,
                .h2,
                h3,
                .h3 {
                    padding-right: 0;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        &.section--search {
            padding-top: 16px;
        }
        &__slider {
            margin-left: -17px;
            margin-right: -17px;

            .swiper-container {
                padding: 0 24px 0 0;
                margin: 0 auto;
            }

            &-pagination {
                display: none;
            }
        }
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    display: block;
    margin-top: 0;
}

h1,
.h1 {
    font-size: 56px;
    line-height: 1.14;
    font-weight: 700;

    @include respond-below(xl) {
        font-size: 50px;
    }
    @include respond-below(lg) {
        font-size: 44px;
    }
    @include respond-below(md) {
        font-size: 24px;
        line-height: 1.3;
        font-weight: 800;
    }
}

h2,
.h2 {
    font-size: 48px;
    line-height: 1.16;
    font-weight: 700;

    @include respond-below(xl) {
        font-size: 42px;
    }
    @include respond-below(lg) {
        font-size: 36px;
    }
    @include respond-below(md) {
        font-size: 24px;
        line-height: 1.3;
        font-weight: 800;
    }
}

h3,
.h3 {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 700;

    @include respond-below(xl) {
        font-size: 34px;
    }
    @include respond-below(lg) {
        font-size: 28px;
    }
    @include respond-below(md) {
        font-size: 24px;
        line-height: 1.3;
        font-weight: 800;
    }
}

h4,
.h4,
.car-block__form-step,
.header-menu__desktop-lvl-3,
.features__title {
    font-size: 32px;
    line-height: 1.25;
    font-weight: 700;

    @include respond-below(xl) {
        font-size: 28px;
    }
    @include respond-below(lg) {
        font-size: 24px;
        line-height: 1.3;
    }
}

h5,
.h5,
.section__tabs-list,
.discount-line__col b {
    font-size: 24px;
    line-height: 1.33;
    font-weight: 700;

    @include respond-below(xl) {
        font-size: 22px;
    }
    @include respond-below(lg) {
        font-size: 20px;
    }
}

.text-xxl {
    font-size: 28px;

    @include respond-below(xl) {
        font-size: 24px;
    }
    @include respond-below(lg) {
        font-size: 22px;
    }
    @include respond-below(md) {
        font-size: 19px;
    }
}

.link-more {
    display: inline-block;
    vertical-align: top;
    font-weight: 700;
    color: inherit;

    .icon {
        margin-bottom: 0.1em;
        vertical-align: text-bottom;
        margin-right: 16px;
    }
}

.text-xl,
.catalog-item__model,
.news-item__title,
.list.list-xl {
    font-size: 20px;
}

.text-lg,
.brand-benefits__item,
.list.list-lg,
.list-styled,
.form-control_title,
.link-more,
.jumbotron--insurance__list,
.insurance-block__item,
.program-proposal-title__subtitle,
.discount-line__col {
    font-size: 18px;
}

.text-md,
.footer-links,
.catalog-item__price-title,
.catalog-item__price-old,
.news-item__date,
.news-item__txt p,
.video-item__quote,
.header-address,
.jumbotron-content__list {
    font-size: 14px;
    line-height: 1.43;
}

.text-sm {
    font-size: 12px;
    line-height: 1.33;
}

.text-primary {
    color: $primary;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: right;
}

.social-links {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -3px;

    &__item {
        @include size(32px);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        margin: 3px;
        color: #fff;
        background: $primary;

        &:hover {
            background: darken($primary, 10%);
        }

        .icon {
            @include size(100%);
        }

        &.social-links__item-telegram {
            background: #35a6de;

            &:hover {
                background: darken(#35a6de, 10%);
            }
        }

        &.social-links__item-viber {
            background: #665cac;

            &:hover {
                background: darken(#665cac, 10%);
            }
        }

        &.social-links__item-watsapp {
            background: #67c15e;

            &:hover {
                background: darken(#67c15e, 10%);
            }
        }
    }
}

.list {
    @include listReset;

    li {
        position: relative;
        padding-left: 72px;
        min-height: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        & + li {
            margin-top: 40px;
        }
        &:before {
            @include pseudo();
            background: url(../img/list-icon.svg) no-repeat center;
            -webkit-background-size: contain;
            background-size: contain;
            top: 0;
            left: 0;
            @include size(40px);
        }

        p {
            margin: 0;
        }

        .text-xxl {
            margin-bottom: 8px;
            line-height: 1.42;
            font-weight: 600;
            display: block;
        }
    }

    &.list-lg {
        li {
            & + li {
                margin-top: 16px;
            }
        }
    }

    &.list-xl {
        li {
            & + li {
                margin-top: 24px;
            }
        }
    }

    &.list-sm {
        li {
            & + li {
                margin-top: 24px;
            }
        }
    }

    @include respond-below(md) {
        li {
            padding-left: 48px;
            min-height: 32px;

            & + li {
                margin-top: 16px;
            }

            &:before {
                @include size(32px);
            }
        }
        &.list-lg,
        &.list-xl,
        &.list-sm {
            li {
                & + li {
                    margin-top: 16px;
                }
            }
        }
    }
}

.list-styled {
    @include listReset;
    line-height: 1.55;

    li {
        color: rgba($base-color, 0.6);
        padding-left: 35px;
        position: relative;

        &:before {
            @include pseudo();
            @include size(12px);
            background: url(../img/list-check.svg) no-repeat center;
            -webkit-background-size: contain !important;
            background-size: contain !important;
            left: 0;
            top: 0.3em;
        }

        &.__like,
        &.__gift,
        &.__percent,
        &.__time {
            &:before {
                @include size(21px);
                top: 0.2em;
            }
        }

        &.__like {
            &:before {
                background: url("../svg/circle-like.svg") no-repeat 0 0;
            }
        }

        &.__gift {
            &:before {
                background: url("../svg/circle-gift.svg") no-repeat 0 0;
            }
        }

        &.__percent {
            &:before {
                background: url("../svg/circle-percent.svg") no-repeat 0 0;
            }
        }

        &.__time {
            &:before {
                background: url("../svg/circle-time.svg") no-repeat 0 0;
            }
        }
    }
}

.link {
    display: inline-block;
    vertical-align: top;
    color: $primary;
    cursor: pointer;
    font-weight: 700;

    &:hover {
        color: darken($primary, 10%);
    }

    .icon {
        @include size(0.8em);
        margin-right: 10px;
    }
}

.tag {
    display: inline-block;
    vertical-align: top;
    z-index: 1;
    position: relative;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    height: 24px;
    line-height: 26px;
    padding: 0 11px;
    border-radius: 12px;

    & + .tag {
        margin-left: 9px;
    }

    &-hit {
        background: $primary;
        color: #fff;
    }

    &-hashtag {
        color: #41474a;
        font-size: 14px;
        background: #fff;
        text-transform: lowercase;
    }

    &-dark {
        background: $base-color;
        color: #fff;
    }

    &-primary {
        background: $primary;
        color: #fff;
    }
}

.link-stretched {
    position: static;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
}

.favorites-link {
    display: inline-block;
    vertical-align: top;
    color: $base-color;
    @include size(27px);
    position: relative;
    z-index: 1;
    transition: none;

    &__number {
        @include size(20px);
        background: $primary;
        border: 2px solid #fff;
        position: absolute;
        color: #fff;
        font-weight: 800;
        font-size: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        letter-spacing: 0.04em;
        border-radius: 50%;
        line-height: 17px;
        right: -9px;
        top: 50%;
        margin-top: -10px;
    }

    .icon {
        @include size(100%);
        fill: transparent;
        stroke: $base-color;
        stroke-width: 1.5px;
        transition: $transition;
    }

    &.active {
        .icon {
            fill: $base-color;
            stroke: $base-color;
        }
    }

    &:hover {
        .icon {
            fill: $primary;
            stroke: $primary;
        }
    }
}

.search-link {
    display: inline-block;
    vertical-align: top;
    color: $base-color;
    @include size(27px);
    position: relative;
    z-index: 1;
    padding: 0;

    .icon {
        @include size(100%);
        stroke-width: 0;
        stroke: none;
    }

    &.active {
        color: $base-color;
    }

    &:hover {
        color: $base-color;
    }
}

a,
button,
input,
.btn,
.swiper-button-prev,
.swiper-button-next,
.link,
.form-control {
    transition: $transition;
}

input,
button,
a,
textarea,
select,
label {
    font-family: $base-font;
}

input,
textarea {
    font-family: inherit;
    @include input-placeholder {
        font: inherit;
        color: $placeholder-color;
    }

    &:hover {
        @include input-placeholder {
            color: $placeholder-hover-color;
        }
    }
}

.form-control--dark {
    @include input-placeholder {
        color: #fff;
    }

    &:hover {
        @include input-placeholder {
            color: #fff;
        }
    }
}

.btn {
    display: inline-block;
    vertical-align: top;
    padding: 0 24px;
    border-radius: 16px;
    position: relative;
    text-align: center;
    cursor: pointer;
    border: 0;
    background: none;
    height: 56px;
    line-height: 56px;
    font-weight: 600;
    font-size: 16px;

    &-outline-primary {
        border: 3px solid $primary;
        line-height: 50px;
        color: $primary-second;
        background: none;

        &:hover {
            color: $primary-second;
            background: $primary;
        }
    }

    &.link-stretched {
        position: static;
    }

    &-block {
        display: block;
        width: 100%;
    }

    .icon {
        @include size(1em);
        margin-right: 0.5em;
        vertical-align: text-bottom;
    }

    &-xl {
        font-size: 18px;
        height: 72px;
        line-height: 72px;
        padding: 0 40px;

        .icon {
            @include size(28px);
            margin-right: 12px;
        }
    }

    &-lg {
        font-size: 18px;
        /* height: 64px;*/
        height: 42px;
        line-height: 64px;
        padding: 0 40px;
        border-radius: 21px;

        .icon {
            @include size(24px);
            margin-right: 12px;
        }

        @include respond-below(sm) {
            line-height: 1;
        }
    }

    &-sm {
        height: 48px;
        line-height: 48px;

        .icon {
            vertical-align: middle;
        }
    }

    &-xs {
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        font-size: 14px;
        padding: 0 20px;
        box-shadow: none;
    }

    &-primary {
        box-shadow: 0 5px 16px rgba(255, 204, 71, 0.24);
        background: $primary;
        color: $primary-second;

        &:hover {
            background: darken($primary, 10%);
            box-shadow: 0 0 0 rgba(255, 204, 71, 0.24);
        }
    }

    &-white {
        background: #ffffff;
        border: 1px solid #f7f7f7;
        border-radius: 16px;
        line-height: 54px;
        font-weight: 600;
        font-size: 16px;
        color: $base-color;
    }

    &-secondary {
        background: #f3f3f3;
        color: rgba(34, 34, 34, 0.56);

        &:hover {
            background: $primary-second;
            color: #fff;
        }
    }

    &-light {
        background: rgba(34, 34, 34, 0.12);
        color: $base-color;

        &:hover {
            background: $base-color;
            color: #fff;
        }
    }

    &-lighter {
        background: #f3f3f3;
        color: $base-color;

        &:hover {
            background: $base-color;
            color: #fff;
        }
    }

    &[disabled] {
        pointer-events: none;
    }

    &-sber {
        box-shadow: 0px 5px 16px rgba(33, 160, 56, 0.24);
        background: #21a038;
        color: #fff;

        &:hover {
            background: darken(#21a038, 10%);
            box-shadow: 0px 0px 0px rgba(33, 160, 56, 0.24);
        }
    }

    &-vtb {
        box-shadow: 0px 5px 16px rgba(10, 41, 115, 0.24);
        background: #0a2973;
        color: #fff;

        &:hover {
            background: darken(#0a2973, 10%);
            box-shadow: 0px 0px 0px rgba(10, 41, 115, 0.24);
        }
    }

    &-dark {
        box-shadow: 0 5px 16px rgba(11, 39, 63, 0.24);
        background: $primary-second;
        color: #fff;

        &:hover {
            background: darken($primary-second, 10%);
            box-shadow: 0 0 0 rgba(11, 39, 63, 0.24);
        }
    }

    @include respond-below(lg) {
        &-xl {
            font-size: 18px;
            height: 64px;
            line-height: 64px;
            padding: 0 40px;
        }
    }
}

.form-control {
    height: 39px;
    line-height: normal;
    display: block;
    width: 100%;
    color: $base-color;
    font-size: 18px;
    background: #f3f3f3;
    border-radius: 16px;
    padding: 0 24px;
    border: 0;
    font-weight: 400;
    height: 45px;

    &:-moz-placeholder {
        line-height: 64px;
    }

    &::-moz-placeholder {
        line-height: 64px;
    }

    &--xl {
        height: 76px;
        line-height: normal;
        display: block;
        width: 100%;
        color: $base-color;
        background: none;
        border-radius: 0;
        padding: 0;
        border: 0;
        border-bottom: 4px solid rgba($base-color, 0.2);
        font-weight: 600;
        font-size: 22px;

        &:-moz-placeholder {
            line-height: 72px;
        }

        &::-moz-placeholder {
            line-height: 72px;
        }

        &:focus {
            border-color: $base-color;
        }

        &__label {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 22px;
            color: #222222;
            opacity: 0.4;
            display: block;
            left: 0;
            pointer-events: none;
            transition: $transition;
        }
    }

    &-xl {
        font-size: 18px;
        height: 72px;
        line-height: normal;
        padding: 0 32px;

        &:-moz-placeholder {
            line-height: 72px;
        }

        &::-moz-placeholder {
            line-height: 72px;
        }
    }

    &:focus {
        outline: none;
        background: rgba(#f3f3f3, 0.5);
    }

    &:hover,
    &:focus,
    &:active {
        background: rgba(#f3f3f3, 0.5);
    }

    &.nice-select {
        float: none;
        width: 100%;
        line-height: 64px;
        display: flex;
        align-items: center;

        .current {
            color: $placeholder-color;
            transition: $transition;
        }

        &:after {
            border-bottom: 2px solid $primary-second;
            border-right: 2px solid $primary-second;
            @include size(8px);
            right: 24px;
        }

        &.open {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            .current {
                color: $placeholder-hover-color;
            }
        }

        &:hover,
        &:active {
            .current {
                color: $placeholder-hover-color;
            }
        }

        .list {
            width: 100%;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
            margin: 0;
            background: #f3f3f3;
            border-radius: 16px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            li {
                display: block;
                padding: 0 24px;
                height: 38px;
                line-height: 38px;
                min-height: 38px;
                background: none;
                margin: 0;

                & + li {
                    margin: 0;
                }

                &.focus,
                &.selected.focus,
                &.option:hover {
                    background-color: rgba($base-color, 0.1);
                }

                &:before {
                    display: none;
                }
            }
        }

        &:after {
        }
    }

    &__field {
        position: relative;
    }

    &.form-control--dark {
        background: rgba(#fff, 0.3);
        color: #fff;

        &:focus {
            outline: none;
            background: rgba(#fff, 0.05);
        }

        &:hover,
        &:focus,
        &:active {
            background: rgba(#fff, 0.05);
        }
    }
    &.form-control--dark::-moz-placeholder {
        opacity: 1;
        color: #fff;
    }

    &.form-control--light {
        background: rgba(#7a7a7a, 0.16);

        &:focus {
            outline: none;
            background: #fff;
        }

        &:hover,
        &:focus,
        &:active {
            background: #fff;
        }
    }

    &_title {
        display: block;
        margin: 0;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .form-control_title {
            width: 45%;
            margin: 0;
            padding-right: 15px;
        }

        .form-control__field {
            width: 55%;
            margin: 0;
        }
    }

    @include respond-below(lg) {
        &-xl {
            font-size: 18px;
            height: 64px;
            line-height: normal;
            padding: 0 24px;

            &:-moz-placeholder {
                line-height: 64px;
            }

            &::-moz-placeholder {
                line-height: 64px;
            }
        }
    }
    @include respond-below(sm) {
        &__row {
            display: block;

            .form-control_title {
                width: 100%;
                margin: 0;
                padding-right: 0;
                padding-bottom: 8px;
                font-weight: 600;
                font-size: 14px;
                color: $base-color;
                opacity: 0.56;
            }

            .form-control__field {
                width: 100%;
                margin: 0;
            }
        }
    }
}

textarea.form-control {
}
.form-control.nice-select .list li:nth-child(1) {
    display: none;
}
.form-inline {
    @include flex;

    .form-control__field {
        width: 256px;
        margin-right: 32px;
    }

    @include respond-below(xl) {
        .form-control__field {
            width: 230px;
            margin-right: 20px;
        }
    }
}

.form-row {
    @include flex;
    margin: -6px;

    & + * {
        margin-top: 12px;
    }
}

.form-col {
    width: 50%;
    padding: 6px;
    @include respond-below(sm) {
        width: 100%;
    }

    &.form-col-25 {
        @include respond-above(sm) {
            width: 25%;
        }
        @include respond-below(sm) {
            width: 50%;
        }
    }
}

.range-block {
    & + * {
        margin-top: 62px;
    }

    .h6 {
        display: block;
        opacity: 0.56;
        padding-bottom: 16px;
        margin: 0;
    }

    &__item {
        position: relative;

        &-has-pipes {
            margin-top: 38px;
        }

        &-has-tooltips {
            margin-bottom: 56px;
        }
    }
}

.range-block__item {
    margin-top: 50px;

    .noUi-pips-horizontal > .noUi-value {
        &:nth-child(2) {
            transform: translate(0, 0);
        }

        &:last-child {
            transform: translate(-100%, 0);
        }

        @include respond-below(xl) {
            font-size: 12px;
        }

        @include respond-below(sm) {
            font-size: 11px;
        }

        @include respond-below(xs) {
            font-size: 8px;
        }
    }
}

.checkbox-list {
    & + * {
        margin-top: 24px;
    }

    &__item {
        display: block;
        position: relative;
        padding-left: 2.5em;
        line-height: 1.5em;
        user-select: none;

        & + .checkbox-list__item {
            margin-top: 8px;
        }
    }

    &__icon {
        width: 1.5em;
        height: 1.5em;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 4px;
        background-color: #e9e9e9;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 50%;
        transition: $transition;
    }

    input {
        display: none;

        &:checked {
            & + .checkbox-list__icon {
                background-color: $primary;
                background-image: url(../img/icon-check-white.svg);
            }
        }
    }
}

.swiper {
    &-pagination {
        &:focus {
            outline: none;
        }

        &-bullet {
            margin: 0 4px;
            @include size(8px);
            border-radius: 4px;
            transition: $transition;

            &-active {
                width: 32px;
            }

            &:focus {
                outline: none;
            }
        }
    }

    &-button-next,
    &-button-prev {
        @include size(72px);
        background: #fff;
        border-radius: 50%;
        margin-top: -36px;
        box-shadow: 0px 16px 64px rgba(0, 0, 0, 0.08);

        &.swiper-button-disabled {
            opacity: 0;
        }

        &:hover {
            background: $primary;
            color: #fff;
        }

        &:focus {
            outline: none;
        }

        &:after {
            color: inherit;
            font-size: inherit;
        }
    }
}

.breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;

    &__back {
        display: block;
        @include size(12px);
        background: url(../img/icons/arrow.svg) no-repeat center;
        -webkit-background-size: contain;
        background-size: contain;

        &:hover {
            opacity: 0.5;
        }

        &-txt {
            display: none;
        }
    }

    &__list {
        @include listReset;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        li {
            padding: 0 14px;
            position: relative;
            color: rgba(#333333, 0.4);
            font-size: 14px;

            &:after {
                @include pseudo();
                @include size(4px);
                border-radius: 50%;
                right: -2px;
                background: $primary;
                top: 50%;
                margin-top: -2px;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            a {
                color: #000000;
                opacity: 0.64;
                display: inline-block;
                vertical-align: top;

                &:hover {
                    opacity: 1;
                    color: $primary;
                }
            }
        }
    }

    @include respond-below(sm) {
        &__back {
            display: block;
            @include size(12px);
            background: url(../img/icons/arrow.svg) no-repeat left center;
            background-size: 12px;
            padding-left: 28px;
            font-size: 16px;
            font-weight: 700;
            height: auto;
            color: $base-color;

            &-txt {
                display: block;
            }
        }
        &__list {
            display: none;
        }
    }
}

.modals-wrapper {
    position: absolute;
    top: -99999px;
    left: -99999px;
    width: 0;
    height: 0;
    overflow: hidden;
}

.modal {
    padding: 48px 66px 34px;
    width: 100%;
    max-width: 930px;
    background: $primary-second;
    border-radius: 24px;
    overflow: visible;

    a {
        color: #ffffff;
    }

    &.__white {
        background: #ffffff;
        color: #333333;

        a {
            color: #333333;
        }
    }

    &-content {
        /*max-width: 352px;*/

        .form-control {
            display: block;
            margin-bottom: 16px;
        }

        .text-sm {
            display: block;
            margin-top: 16px;
            margin-bottom: 0;
            opacity: 0.5;
        }
    }

    &__title {
        display: block;
        font-size: 34px;
        font-weight: 700;
        margin-bottom: 24px;
        line-height: 1.18;

        &-sub {
            display: block;
            margin-top: 6px;
            font-size: 24px;
            font-weight: 400;
        }

        &-xs {
            display: block;
            margin-top: 6px;
            font-size: 18px;
            font-weight: 400;
        }

        &-lg {
            display: block;
            font-size: 24px;
            font-weight: 800;
            margin-top: 3px;
        }
    }

    &__text {
        display: block;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
        line-height: 1.18;
    }

    &-img {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        pointer-events: none;

        &.modal-img-r-c {
            align-items: center;
        }

        img {
            display: block;
            z-index: 1;

            &.__mw-90 {
                max-width: 90%;
            }
        }

        &.__sber,
        &.__vtb,
        &.__def {
            &:before {
                content: "";
                position: absolute;
                z-index: 0;
                bottom: 0;
                right: 0;
                width: 400px;
                height: 400px;
                background-size: contain;
                border-radius: 0 0 30px 0;

                @include respond-below(md) {
                    width: 300px;
                    height: 300px;
                }
            }
        }

        &.__sber {
            &:before {
                background: url("../svg/modal-sber.svg") no-repeat 0 0;
            }
        }

        &.__vtb {
            &:before {
                background: url("../svg/modal-vtb.svg") no-repeat 0 0;
            }
        }

        &.__def {
            &:before {
                background: url("../svg/modal-def.svg") no-repeat 0 0;
            }
        }

        &__sale {
            position: absolute;
            z-index: 3;
            top: 150px;
            left: 0;

            &-txt {
                font-size: 22px;
                text-align: center;
                line-height: 1;
            }

            &-xl {
                font-size: 80px;
                font-weight: 600;
                line-height: 1;
            }

            @include respond-below(md) {
                top: 200px;
                left: -10px;

                &-txt {
                    font-size: 16px;
                }

                &-xl {
                    font-size: 60px;
                }
            }
        }
    }

    &__time {
        display: block;
        background: $primary;
        border: 2px dashed #333333;
        color: $primary-second;
        border-radius: 16px;
        padding: 2px 20px;
        margin-bottom: 17px;
        text-align: center;
    }

    &__logo {
        height: 40px;

        @include respond-below(sx) {
            height: 30px;
            margin-bottom: 5px;
        }
    }

    @include respond-below(lg) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    @include respond-below(md) {
        &-img {
            width: 40%;
        }
    }
    @include respond-below(sm) {
        padding: 16px 17px 23px;
        margin-bottom: 0;
        &-img {
            display: none;
        }
        &-content {
            max-width: 100%;
            width: 100%;
        }
        &__title {
            font-size: 24px;

            &-sub {
                font-size: 20px;
            }

            &-xs {
                font-size: 18px;
            }

            &-lg {
                font-size: 20px;
            }
        }
        &__time {
            font-size: 16px;
            line-height: 24px;

            b {
                display: block;
            }
        }
    }
}
#modalBook .modal-content {
    background: url(/samovar/front/img/modals/bron.webp) no-repeat;
    background-size: contain;
    background-position: right;
    padding: 40px 35px 20px 17px;
    border-radius: 32px;
}
#modalAutoCredit .modal-content,
.modal.__white .modal-content,
#modalCredit .modal-content {
    background: url(/samovar/front/img/modals/credit-new.webp) no-repeat;
    background-size: cover;
    background-position: 0 -100px;
    padding: 40px 35px 20px 17px;
    border-radius: 32px;

    @include respond-below(md) {
      background-position: center;
    }
}
.modal.__white .modal-content {
    padding: 40px 54px 20px 17px;
}
.modal.__white .modal-content .modal__title {
    width: 84%;
    font-size: 28px;
}
.modal.__white .modal-content form {
    margin-top: 50px;
}
#modalInsurance .modal-content {
    background: url(/samovar/front/img/modals/kasko.webp) no-repeat;
    background-size: contain;
    background-position: right;
    padding: 40px 35px 20px 17px;
    border-radius: 32px;
}
#modalCreditNew .modal-content {
    background: url(/samovar/front/img/modals/end.webp) no-repeat;
    background-size: contain;
    background-position: right;
    padding: 40px 35px 20px 17px;
    border-radius: 32px;
}
#modalTradeIn .modal-content {
    background: url(/samovar/front/img/modals/rassrochka.webp) no-repeat;
    background-size: contain;
    background-position: right;
    padding: 40px 35px 20px 17px;
    border-radius: 32px;
}
#modalInstallment .modal-content {
    background: url(/samovar/front/img/modals/rassrochka2.webp) no-repeat;
    background-size: contain;
    background-position: right;
    padding: 21px 35px 20px 26px;
    border-radius: 32px;
}
#modalInstallment .modal__title {
    width: 65%;
    margin-bottom: 17px;
}
#modalInstallment .modal__time {
    margin-bottom: 18px;
}
#modalInstallment .modal-content .form-control {
    background: #f1f1f1;
}
#modalInstallment .modal-content .text-sm {
    margin-top: 6px;
}
#modalCredit .modal__title {
    font-size: 31px;
    line-height: 32px;
    margin-bottom: 57px;
}
.modal .modal__title-xs {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    width: 62%;
    font-family: "Roboto";
}
.modal .modal__title-xs ul {
    padding-left: 17px;
}
#modalTradeIn .modal-content .form-control {
    background: rgba(241, 241, 241, 0.28);
}
/*#modalBook .fancybox-button {
    background: #2F6CA1;
}*/

.fancybox-slide--html .fancybox-close-small {
    color: #fff;
    padding: 8px;
    top: 0;
    background: $primary-second;
    right: -60px;
    border-radius: 50%;
    opacity: 1;

    @include respond-below(lg) {
        top: -60px;
        right: 0;
    }
}

.fancybox-slide--html .fancybox-close-small {
    &:focus {
        outline: none;
    }
}

.fancybox-bg {
    background: rgba(0, 0, 0, 0.2);
}

.rules {
    &__lnk {
        text-decoration: underline;
        color: $base-color;

        &:hover {
            text-decoration: none;
        }
    }
}

.__xs {
    &-hidden {
        display: block;

        @include respond-below(sm) {
            display: none !important;
        }
    }

    &-show {
        display: none !important;

        @include respond-below(sm) {
            display: block !important;
        }
    }
}
.input::placeholder {
    color: #000000;
}

#modalBrandFinance {
    overflow: hidden;

    .modal-content {
        padding: 20px;
    }

    .modal-img {
        display: block;

        &__sale {
            top: 45px;
        }

        @include respond-below(md) {
            display: none;
        }
    }
}