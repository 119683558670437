/*! nouislider - 14.6.2 - 9/16/2020 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
.noUi-connect {
  height: 100%;
  width: 100%;
}
.noUi-origin {
  height: 10%;
  width: 10%;
}
/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 7px;
}
.noUi-horizontal .noUi-handle {
  width: 28px;
  height: 28px;
  right: -14px;
  top: -11px;
}
.noUi-vertical {
  width: 8px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 28px;
  right: -11px;
  top: -14px;
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -14px;
  right: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #F3F3F3;
  border-radius: 7px;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: $primary;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid #E9E9E9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
  border-radius: 3px;
  background: #FFF;
  cursor: pointer;
  border-radius: 50%;

  &:focus{
    outline: none;
  }
}
.noUi-active {
  &:before{
    opacity: .5;
  }
}
/* Handle stripes;
 */
.noUi-handle:before{
  @include pseudo();
  background: $primary;
  @include size(10px);
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  transition: $transition;
  border-radius: 50%;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42;
  color: #A7A7A7;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #A7A7A7;
  bottom: 0;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 0;
  height: 38px;
  bottom: 100%;
  left: 2px;
  width: calc(100% - 5px);
}
.noUi-value-horizontal {
  transform: translate(-50%,0%);
}
.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 0%);
}
.noUi-marker-horizontal.noUi-marker {
  width: 1px;
  height: 15px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 15px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border-radius: 3px;
  color: $base-color;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
}
.noUi-horizontal .noUi-tooltip {
  transform: translate(-50%, 0);
  left: 50%;
  top: 120%;
}
.noUi-vertical .noUi-tooltip {
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.noUi-horizontal .noUi-origin > .noUi-tooltip {
  transform: translate(50%, 0);
  left: auto;
  bottom: 10px;
}
.noUi-vertical .noUi-origin > .noUi-tooltip {
  transform: translate(0, -18px);
  top: auto;
  right: 28px;
}
